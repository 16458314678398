var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('v-row',{staticClass:"text-right"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isUserAdmin && !_vm.readOnly)?_c('px-grupo-compra-adicionar-produto',{attrs:{"cotacao":_vm.cotacao,"comprador-default":_vm.compradores[0]},on:{"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary"}},on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Produto ")],1)]}}],null,false,83322596)}):_vm._e(),(!_vm.readOnly)?_c('px-grupo-compra-entrar-cotacao',{attrs:{"cotacao":_vm.cotacao,"comprador-default":_vm.compradores[0]},on:{"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_vm._v(" Entrar na cotação ")])]}}],null,false,927284198)}):_vm._e()],1)],1),_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""}},_vm._l((_vm.cotacao.produtos),function(produto){return _c('v-expansion-panel',{key:produto.id},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_vm._v(" "+_vm._s(produto.id)+" - "+_vm._s(produto.produto.descricao)+" "),_c('small',{staticClass:"caption"},[_vm._v("("+_vm._s(produto.participantes.length)+" participante(s))")]),_c('div',{staticClass:"caption"},[_c('px-anexo',{attrs:{"read-only":"","dot":"","download-base-path":"files/download-comprador"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""}},on),[_c('v-icon',[_vm._v("mdi-paperclip")])],1)]}}],null,true),model:{value:(produto.arquivos),callback:function ($$v) {_vm.$set(produto, "arquivos", $$v)},expression:"produto.arquivos"}}),_vm._v(" "+_vm._s(_vm._f("naoInformado")(produto.especificacaoProduto))+" "),_c('px-cotacao-respostas-produtos-especificacao',{attrs:{"small":"","especificacao":produto.especificacaoProduto}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[(produto.menorPrecoPraticado && _vm.hasMoreParticipantes(produto))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("currency")(produto.menorPrecoPraticado))+" ")])]}}],null,true)},[_c('span',[_vm._v("Menor preço praticado")])]):_vm._e()],1),(produto.quantidade)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(produto.quantidade)+" "+_vm._s(produto.unidadeMedida.descricao)+" ")]):_vm._e()],1)],1),_c('v-expansion-panel-content',[_vm._l((produto.participantes),function(participante,index){return [_c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_vm._v(" "+_vm._s(participante.comprador.pessoa.nome)+" "),(_vm.showPrecoQuantidade(participante))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" ("+_vm._s(_vm._f("currency")(participante.precoPraticado))+") ")])]}}],null,true)},[_c('span',[_vm._v("Preço praticado")])]):_vm._e(),(_vm.isCompradorAtual(participante.comprador))?_c('v-chip',{staticClass:"ml-2",attrs:{"color":"secondary","small":"","label":""}},[_vm._v(" Minha empresa ")]):_vm._e()],1),(_vm.showPrecoQuantidade(participante))?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(participante.quantidade)+" "+_vm._s(produto.unidadeMedida.descricao)+" "),(!_vm.readOnly)?_c('px-grupo-compra-cotacao-visualizacao-participacoes-options',{attrs:{"cotacao":_vm.cotacao,"participante":participante,"is-comprador-atual":_vm.isCompradorAtual(participante.comprador)},on:{"update":_vm.onUpdate}}):_vm._e()],1):_vm._e(),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("cnpj")(participante.comprador.pessoa.cnpj))+" ")])],1)],1)],1),(_vm.showDivider(index, produto.participantes))?_c('v-divider',{key:("div-" + index)}):_vm._e()]})],2)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }