import axios from 'axios';

const getList = params => axios.get('/cotacoes-conjuntas', { params });
const get = id => axios.get(`/cotacoes-conjuntas/${id}`, { headers: { name_header: 'test_value' } });

const save = (cotacao) => {
  if (cotacao.id) {
    return axios.put(`/cotacoes-conjuntas/${cotacao.id}`, cotacao);
  }
  return axios.post('/cotacoes-conjuntas', cotacao);
};

const entrarCotacao = (cotacaoId, cotacaoItem) => axios.post(`/cotacoes-conjuntas/${cotacaoId}/participantes`, cotacaoItem);
const removeParticipacao = (cotacaoId, itemParticipacaoId) => axios.delete(`/cotacoes-conjuntas/${cotacaoId}/participantes/${itemParticipacaoId}`);
const editarParticipacao = (cotacaoId, itemParticipacaoId, participacaoItem) => axios.put(`/cotacoes-conjuntas/${cotacaoId}/participantes/${itemParticipacaoId}`, participacaoItem);
const inserirProduto = (cotacaoId, produtoItem) => axios.post(`/cotacoes-conjuntas/${cotacaoId}/produtos`, produtoItem);
const removeFornecedor = (cotacaoId, fornecedorId) => axios.delete(`cotacoes-conjuntas/${cotacaoId}/fornecedores/${fornecedorId}`);
const adicionarFornecedor = (cotacaoId, fornecedorId) => axios.put(`cotacoes-conjuntas/${cotacaoId}/fornecedores/${fornecedorId}`, {});
const adicionarAdmin = (cotacaoId, userId) => axios.put(`cotacoes-conjuntas/${cotacaoId}/administradores/${userId}`, {});
const removerAdmin = (cotacaoId, userId) => axios.delete(`cotacoes-conjuntas/${cotacaoId}/administradores/${userId}`);
const getAdmins = cotacaoId => axios.get(`cotacoes-conjuntas/${cotacaoId}/administradores`);
const cancelarCotacao = cotacaoId => axios.put(`cotacoes-conjuntas/${cotacaoId}/cancelar`, {});
const enviarCotacao = cotacaoId => axios.put(`/cotacoes-conjuntas/${cotacaoId}/enviar-fornecedores`, {});
const updateIdentifier = (id, cotacao) => axios.put(`/cotacoes-conjuntas/${id}/identifier`, cotacao);

export default {
  getList,
  get,
  save,
  entrarCotacao,
  removeParticipacao,
  inserirProduto,
  editarParticipacao,
  removeFornecedor,
  adicionarFornecedor,
  adicionarAdmin,
  removerAdmin,
  getAdmins,
  cancelarCotacao,
  enviarCotacao,
  updateIdentifier,
};
