<template>
  <px-custom-dialog transition="fadet"
                    width="600"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Entrando na cotação
    </template>
    <template>
      <v-row dense>
        <v-col cols="12">
          <px-autocomplete-comprador
            required
            class="required"
            :error="$v.itemCotacao.comprador.$error"
            @blur="$v.itemCotacao.comprador.$touch()"
            v-model="itemCotacao.comprador"/>
        </v-col>
      </v-row>
      <v-subheader class="font-weight-bold">Produtos</v-subheader>
      <template v-for="(produto, idx) in itemCotacao.produtos">
        <v-row :key="produto.id" class="text--primary">
          <v-col cols="12">
            {{ produto.id }} - {{ produto.produto.descricao }}
            ({{ produto.unidadeMedida.descricao }})

            <px-grupo-compra-entrar-cotacao-recusar :produto="produto"
                                                    @add="onAddProduto"
                                                    @remove="onRemoveProduto"/>

            <div class="caption">
              <px-anexo read-only dot
                        v-model="produto.arquivos"
                        download-base-path="files/download-comprador">
                <template v-slot:activator="{ on }">
                  <v-btn icon
                         small
                         v-on="on">
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
              </px-anexo>
              {{ produto.especificacaoProduto | naoInformado }}
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!produto.$recusado" :key="`fields-${produto.id}`">
          <v-col cols="6">
            <px-input-number label="Quantidade"
                             required
                             class="required"
                             :error="$v.itemCotacao.produtos.$each[idx].quantidade.$error"
                             @blur="$v.itemCotacao.produtos.$each[idx].quantidade.$touch()"
                             v-model="produto.quantidade"/>
          </v-col>
          <v-col cols="6">
            <px-input-money
              ref="precoPraticado"
              label="Preço praticado"
              v-model="produto.precoPraticado"/>
          </v-col>
        </v-row>
        <v-divider v-if="showDivider(idx, itemCotacao.produtos)" :key="`div-${produto.id}`"/>
      </template>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid"
             @click="save">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { isEmpty } from 'lodash';
import dividerMixin from '@/mixins/divider-mixin';
import GrupoCompraEntrarCotacaoRecusar from './GrupoCompraEntrarCotacaoRecusar.vue';
import CotacaoGrupoService from '../cotacoes-grupo-service';

export default {
  mixins: [dividerMixin],
  props: {
    cotacao: Object,
    compradorDefault: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      itemCotacao: {
        comprador: this.compradorDefault,
        produtos: [],
      },
    };
  },
  methods: {
    onRemoveProduto(produto) {
      // eslint-disable-next-line no-param-reassign
      produto.$recusado = true;
    },
    onAddProduto(produto) {
      // eslint-disable-next-line no-param-reassign
      produto.$recusado = false;
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
      this.itemCotacao.produtos = this.cotacao.produtos.map(produto => ({
        id: produto.id,
        produto: produto.produto,
        arquivos: produto.arquivos,
        especificacaoProduto: produto.especificacaoProduto,
        unidadeMedida: produto.unidadeMedida,
        $recusado: false,
      }));
      this.$v.$reset();
    },
    buildSaveState() {
      return this.itemCotacao.produtos
        .filter(produto => !produto.$recusado)
        .map(produto => ({
          produto: {
            id: produto.id,
          },
          comprador: this.itemCotacao.comprador,
          precoPraticado: produto.precoPraticado,
          quantidade: produto.quantidade,
        }));
    },
    save() {
      const payload = this.buildSaveState();
      if (isEmpty(payload)) {
        this.$toast('É necessário informa a quantidade para ao menos um produto', { color: 'error' });
        return;
      }
      this.loading = true;
      CotacaoGrupoService.entrarCotacao(this.cotacao.id, this.buildSaveState())
        .then(() => {
          this.close();
          this.$toast('Pedido atualizado com sucesso', { color: 'success' });
          this.$emit('update');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.buildInitialState();
    },
  },
  components: {
    pxGrupoCompraEntrarCotacaoRecusar: GrupoCompraEntrarCotacaoRecusar,
  },
  validations: {
    itemCotacao: {
      comprador: {
        required,
      },
      produtos: {
        $each: {
          quantidade: {
            minValue(value, model) {
              return model.$recusado ? true : value > 0;
            },
          },
        },
      },
    },
  },
};
</script>
