const APP_ID_HEADER = 'App-Id';
const APP_ID_VALUE = 'application:COMPRADOR';

const appIdInterceptor = (config) => {
  if (!config.headers[APP_ID_HEADER]) {
    // eslint-disable-next-line no-param-reassign
    config.headers[APP_ID_HEADER] = btoa(APP_ID_VALUE);
  }

  return config;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  appIdInterceptor,
};
