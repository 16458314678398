import Main from '@/modules/main/Main.vue';
import Header from '@/modules/header/Header.vue';
import MinhaConta from '@/modules/minha-conta/MinhaConta.vue';
import MinhaEmpresa from '@/modules/minha-empresa/MinhaEmpresa.vue';
import Compras from '@/modules/compra/ListagemCompra.vue';
import CompraDetalhes from '@/modules/compra/CompraDetalhes.vue';
import MeuPainel from '@/modules/meu-painel/MeuPainel.vue';
import store from '@/store';
import GrupoService from '@/modules/selecao-grupo/grupo-compra-service';

import { INITIALIZE_FROM_COOKIES_AC, SET_REDIRECT_ACT } from '@/store/auth/actions.type';
import { SET_GRUPO_ACTION, SET_USER_GROUP_ACTION } from '@/store/grupo/actions.type';

import grupoCompra from './grupo-compra-router';

const getAcesso = id => GrupoService.getAcesso(id)
  .then(({ data }) => {
    if (!data) {
      throw Error('No access data');
    }
    store.dispatch(SET_USER_GROUP_ACTION, data);
    return data;
  });

const getGrupo = id => GrupoService.get(id)
  .then(({ data }) => {
    if (!data) {
      throw Error('No group data');
    }
    store.dispatch(SET_GRUPO_ACTION, data);
    return getAcesso(id);
  });

const beforeEnterAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);

      if (!to.params.idGrupo) {
        next('/selecao-grupo');
      }

      return getGrupo(to.params.idGrupo)
        .then(() => next())
        .catch(() => {
          next('/selecao-grupo');
        });
    }
    store.dispatch(SET_REDIRECT_ACT, to.fullPath);
    return next('/login');
  }
  if (!to.params.idGrupo) {
    next('/selecao-grupo');
  }

  return getGrupo(to.params.idGrupo)
    .then(() => next())
    .catch(() => {
      next('/selecao-grupo');
    });
};

export default {
  path: '/:idGrupo',
  name: 'main',
  redirect: { name: 'main.indicadores-catalogo' },
  components: {
    default: Main,
    header: Header,
  },
  children: [
    {
      path: 'meu-painel',
      name: 'main.meu-painel',
      components: {
        default: MeuPainel,
      },
    },
    {
      path: 'minha-conta',
      name: 'main.minha-conta',
      components: {
        default: MinhaConta,
      },
    },
    {
      path: 'minha-empresa',
      name: 'main.minha-empresa',
      components: {
        default: MinhaEmpresa,
      },
    },
    {
      path: 'compras',
      name: 'main.compras',
      components: {
        default: Compras,
      },
    },
    {
      path: 'compra/:id/detalhes',
      name: 'main.compras.detalhes',
      components: {
        default: CompraDetalhes,
      },
    },
    ...grupoCompra,
  ],
  beforeEnter: beforeEnterAuth,
};
