import GrupoCompraCotacoes from '@/modules/cotacoes/GrupoCompraCotacoes.vue';
import GrupoCompraCadastroCotacao
  from '@/modules/cotacoes/GrupoCompraCadastroCotacao.vue';
import GrupoCompraCotacaoVisualizacao
  from '@/modules/cotacoes/visualizacao/GrupoCompraCotacaoVisualizacao.vue';
import GrupoCompraCotacaoRespostaDetail
  from '@/modules/cotacoes/visualizacao/analise/GrupoCompraCotacaoRespostaDetail.vue';
import GrupoCompraFechamentoCotacao
  from '@/modules/cotacoes/fechamento/GrupoCompraFechamentoCotacao.vue';
import PaginaFornecedor from '@/modules/pagina-fornecedor/PaginaFornecedor.vue';
import Oportunidades from '@/modules/oportunidades/Oportunidades.vue';
import Grupo from '@/modules/grupo/Grupo.vue';
import Participantes from '@/modules/participantes/Participantes.vue';
import ParticipanteDetalhes from '@/modules/participantes/detalhes/ParticipanteDetalhes.vue';
import Fornecedores from '@/modules/fornecedores/Fornecedores.vue';
import Catalogo from '@/modules/catalogo/Catalogo.vue';
import Oferta from '@/modules/catalogo/oferta/Oferta.vue';
import Carrinho from '@/modules/carrinho/Carrinho.vue';
import IndicadoresCatalogo from '@/modules/indicadores-catalogo/IndicadoresCatalogo.vue';

export default [
  {
    path: 'cotacoes',
    name: 'main.cotacoes',
    components: {
      default: GrupoCompraCotacoes,
    },
  },
  {
    path: 'cadastro',
    name: 'main.cotacao.cadastro',
    components: {
      default: GrupoCompraCadastroCotacao,
    },
  },
  {
    path: 'cotacao/:idCotacao',
    name: 'main.cotacao',
    components: {
      default: GrupoCompraCotacaoVisualizacao,
    },
  },
  {
    path: 'cotacao/:idCotacao/resposta/:idResposta',
    name: 'main.cotacao.resposta',
    components: {
      default: GrupoCompraCotacaoRespostaDetail,
    },
  },
  {
    path: 'cotacao/:idCotacao/resposta/:idResposta/fechamento/:idComprador',
    name: 'main.resposta.fechamento',
    components: {
      default: GrupoCompraFechamentoCotacao,
    },
  },
  {
    path: 'pag/fornecedor/:id',
    name: 'main.pagina-fornecedor',
    components: {
      default: PaginaFornecedor,
    },
  },
  {
    path: 'oportunidades',
    name: 'main.oportunidades',
    components: {
      default: Oportunidades,
    },
  },
  {
    path: 'grupo-compras',
    name: 'main.admin.grupo-compras',
    components: {
      default: Grupo,
    },
  },
  {
    path: 'participantes',
    name: 'main.admin.participantes',
    components: {
      default: Participantes,
    },
  },
  {
    path: 'participantes/:idParticipante',
    name: 'main.admin.participantes.detalhes',
    components: {
      default: ParticipanteDetalhes,
    },
  },
  {
    path: 'fornecedores',
    name: 'main.admin.fornecedores',
    components: {
      default: Fornecedores,
    },
  },
  {
    path: 'catalogo',
    name: 'main.catalogo',
    components: {
      default: Catalogo,
    },
  },
  {
    path: 'catalogo/oferta/:id',
    name: 'main.catalogo.oferta',
    components: {
      default: Oferta,
    },
  },
  {
    path: 'carrinho',
    name: 'main.carrinho',
    components: {
      default: Carrinho,
    },
  },
  {
    path: 'indicadores-catalogo',
    name: 'main.indicadores-catalogo',
    components: {
      default: IndicadoresCatalogo,
    },
  },
];
