<template>
  <div>
    <px-catalogo-search-text @change="onChangeSearch"/>
    <div v-if="loading" class="mt-10">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && ofertas.content.length">
      <px-catalogo-list :ofertas="ofertas.content"/>
      <div class="text-center mt-5">
        <v-pagination
          v-model="page"
          :length="pagesLimit"
          :total-visible="8"
        ></v-pagination>
      </div>
    </div>
    <div v-if="!loading && !ofertas.content.length"
         align="center"
         class="headline mb-5 mt-8">
      Nenhuma oferta encontrada
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import CatalogoSearchText from './CatalogoSearchText.vue';
import CatalogoList from './CatalogoList.vue';
import CatalogoService from './catalogo-service';

const LIMIT = 12;
const PAGE = 1;

export default {
  mixins: [paginationMixin],
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
      page: PAGE,
      pagesLimit: 1,
      ofertas: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: PAGE,
        limit: LIMIT,
        offset: 0,
        filter: '',
        sort: '',
        sortDesc: true,
      },
      optionsList: {
        page: PAGE,
        itemsPerPage: LIMIT,
        sortBy: '',
        sortDesc: false,
        multiSort: false,
      },
    };
  },
  computed: {
    ...mapGetters('catalogoSearch', ['query']),
  },
  methods: {
    resetPage() {
      this.page = 1;
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onChangeSearch() {
      this.resetPage();
      this.getOfertas();
    },
    onChangeOptions() {
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getOfertas();
    },
    setPagesLimit() {
      this.pagesLimit = Math.ceil(this.ofertas.total / LIMIT);
    },
    getOfertas() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      CatalogoService.getOfertas(this.grupoId, {
        limit,
        offset,
        query: this.query,
      })
        .then(({ data }) => {
          this.ofertas = data;
          this.setPagesLimit();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.optionsList.page = newValue;
      this.onChangeOptions();
    },
  },
  mounted() {
    this.getOfertas();
  },
  components: {
    pxCatalogoSearchText: CatalogoSearchText,
    pxCatalogoList: CatalogoList,
  },
};
</script>
