<template>
  <div>
    <px-view-header title="Participantes" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <px-participantes-header :on-click-refresh="onRefresh" @change="onChangeFilter"/>
      <px-participantes-list :participantes="participantes"
                             :loading="loading"
                             :grupo-id="grupoId"
                             :on-change-options="onChangeOptions"
                             :options-list="optionsList"
                             @update="onRefresh"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import ParticipantesList from './ParticipantesList.vue';
import ParticipantesHeader from './ParticipantesHeader.vue';
import ParticipanteService from './participantes-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
      participantes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'entidade.nome',
        sortDesc: true,
      },
      optionsList: {},
      breadcrumbs: [
        { text: 'Administrador' },
        { text: 'Participantes' },
      ],
    };
  },
  computed: {
    ...mapGetters(['grupoCompra', 'isGrupoAdmin']),
    ...mapGetters('participantes', ['stringFilter']),
  },
  methods: {
    onRefresh() {
      this.getParticipantes();
    },
    onChangeFilter() {
      this.resetPage();
      this.getParticipantes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getParticipantes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getParticipantes() {
      this.loading = true;
      this.listOptions.filter = this.stringFilter;
      const { limit, offset } = this.listOptions;
      return ParticipanteService.getParticipantes(this.grupoId, {
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.participantes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (!this.isGrupoAdmin) {
      this.$router.push({
        name: 'main.meu-painel',
      });
      return;
    }

    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = '';
  },
  components: {
    pxParticipantesHeader: ParticipantesHeader,
    pxParticipantesList: ParticipantesList,
  },
};
</script>
