<template>
  <px-lateral-dialog persistent v-model="localValue" width="600">
    <template v-slot:title>
      Confirmação de recebimento
    </template>
    <template>
      <v-checkbox v-model="isChecked">
        <template v-slot:label>
          <span>
            Declaro que li e aceito os
            <a @click.stop target="_blank" href="https://proxpect.com.br/termos-uso.html">
              termos de uso
            </a>
              da plataforma.
          </span>
        </template>
      </v-checkbox>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn color="primary"
             :loading="loading"
             :disabled="!isChecked"
             @click="confirm">
        Confirmar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import UserService from '@/api/user-service';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isChecked: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    confirm() {
      this.loading = true;
      UserService.aceitarTermo()
        .then(() => {
          this.$emit('update');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
