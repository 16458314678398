<template>
  <div>
    <px-view-header title="Oportunidades" :breadcrumbs="breadcrumbs"/>

    <div class="mt-3">
      <px-oportunidades-list/>
    </div>
  </div>
</template>

<script>
import OportunidadesList from './OportunidadesList.vue';

export default {
  data() {
    return {
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Oportunidades' },
      ],
    };
  },
  methods: {
    onClickRefresh() {
    },
  },
  components: {
    pxOportunidadesList: OportunidadesList,
  },
};
</script>
