<template>
  <div>
    <v-row>
      <v-col cols="12" class="headline mt-3">
        Contatos
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Site</span>
        <div class="body-1">
          {{ fornecedor.site | naoInformado }}
          <px-open-site :site-desc="fornecedor.site"></px-open-site>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Telefone</span>
        <div class="body-1">
          {{ fornecedor.pessoa.telefone | phone | naoInformado }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">E-mail</span>
        <div class="body-1">
          {{ fornecedor.pessoa.email | naoInformado }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    fornecedor: Object,
  },
};
</script>
