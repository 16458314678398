import store from '@/store';
import { INITIALIZE_FROM_COOKIES_AC } from '@/store/auth/actions.type';
import SelecaoGrupo from '@/modules/selecao-grupo/SelecaoGrupo.vue';
import Header from '@/modules/header/Header.vue';

const beforeEnterAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return next();
    }
    return next('/login');
  }
  return next();
};

export default {
  path: '/selecao-grupo',
  name: 'selecao-grupo',
  components: {
    default: SelecaoGrupo,
    header: Header,
  },
  beforeEnter: beforeEnterAuth,
};
