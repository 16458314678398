<template>
  <div>
      <span class="body-1">
        Vendido e entregue por
        <a :href="urlFornecedor" target="_blank">{{ oferta.fornecedor.pessoa.nome }}</a>
      </span>
  </div>
</template>

<script>
const APP_URL = process.env.VUE_APP_APP_URL;

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  computed: {
    urlFornecedor() {
      return `${APP_URL}/${this.grupoId}/pag/fornecedor/${this.oferta.fornecedor.id}`;
    },
  },
};
</script>
