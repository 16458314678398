import {
  SET_GRUPO_ACTION, SET_USER_GROUP_ACTION,
} from './actions.type';
import {
  SET_GRUPO, SET_USER_GROUP,
} from './mutations.type';

export default {
  [SET_GRUPO_ACTION](context, data) {
    context.commit(SET_GRUPO, data);
  },
  [SET_USER_GROUP_ACTION](context, data) {
    context.commit(SET_USER_GROUP, data);
  },
};
