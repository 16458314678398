<template>
  <v-menu
    offset-x
    max-width="450"
    min-width="350"
    max-height="455"
    v-model="menuOpened"
    :close-on-content-click="true"
    content-class="menu-overflow"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-badge
        color="secondary"
        overlap
        bottom
        left
      >
        <template v-slot:badge>
          <span>{{ itens.length }}</span>
        </template>
        <v-btn icon small class="mr-3"
               v-on="on">
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card v-if="loading" class="text-center" style="overflow-y: hidden">
      <v-card-text>
        <px-circular-loading/>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-list class="list-overflow" max-height="400">
        <v-list-item v-if="!itens.length">
          <v-list-item-content align="center">
            <v-list-item-title>
              Não há produtos no carrinho
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <template v-for="(item) in itens">
          <v-list-item three-line :key="item.id">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.oferta.titulo }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.oferta.fornecedor.pessoa.nome }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="onRemove(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="`div${item.id}`"/>
        </template>
      </v-list>
      <v-card-actions>
        <v-btn block link color="primary" :to="`/${grupoId}/carrinho`">
          Ir para o carrinho
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_ITENS, REMOVE_ITEM } from '@/store/carrinho/actions.type';

export default {
  data() {
    return {
      menuOpened: false,
      grupoId: this.$route.params.idGrupo,
    };
  },
  computed: {
    ...mapGetters('carrinho', ['itens', 'loading']),
  },
  methods: {
    ...mapActions('carrinho', {
      getItens: GET_ITENS,
      removeItem: REMOVE_ITEM,
    }),
    onRemove(item) {
      this.removeItem({ grupoId: this.grupoId, id: item.id });
    },
  },
  created() {
    this.getItens(this.grupoId);
  },
};
</script>

<style scoped>
.menu-overflow {
  overflow-y: hidden;
}

.list-overflow {
  overflow-y: scroll;
}
</style>
