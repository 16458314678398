<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">Produto</th>
        <th>Quantidade</th>
        <th>ICMS</th>
        <th>IPI</th>
        <th v-for="prazo in prazos" :key="`${prazo.id}header`">
          <px-forma-pagamento :forma-pagamento="prazo.formaPagamento"
                              :prazo-pagamento="prazo.prazoPagamento"/>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(item, index) in produtos">
        <tr :key="index">
          <td>
            {{ item.cotacaoConjuntaProduto.id }} -
            {{ item.cotacaoConjuntaProduto.produto.descricao }}
          </td>
          <td>
            {{ item.cotacaoConjuntaProduto.quantidade }}
            {{ item.cotacaoConjuntaProduto.unidadeMedida.descricao }}
          </td>
          <td>
            <px-imposto-simple v-if="isRespondida(item)" :imposto="item.impostos" tipo="ICMS"/>
          </td>
          <td>
            <px-imposto-simple v-if="isRespondida(item)" :imposto="item.impostos" tipo="IPI"/>
          </td>
          <td v-for="prazo in item.prazos" :key="prazo.id">
            <template v-if="isRespondida(item)">
              <px-resposta-prazo-preco :value="prazo.precoUnitario" :situacao="prazo.situacao"/>
            </template>
          </td>
          <td>
            <template v-if="isRespondida(item)">
              <px-especificacao-produto-comparavel
                v-if="showEspecificacaoLine"
                :especificacao="item.cotacaoConjuntaProduto.especificacaoProduto"
                :especificacao-fornecida="item.especificacaoProduto"/>
              <v-btn icon @click="toggleItem(item)">
                <v-icon>{{icon(item)}}</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <px-situacao-cotacao-produto-icon :situacao="item.situacao"/>
            </template>
          </td>
        </tr>
        <tr :key="`details${index}`" v-if="item.$active">
          <td colspan="9" class="grey lighten-4 expanded-item details-item">
            <px-grupo-compra-cotacao-resposta-produto-detail :item="item"
                                                             :check-situacao="checkSituacao"
              :show-especificacao="showEspecificacao"/>
          </td>
        </tr>
      </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { first } from 'lodash';
import { SituacaoRespostaCotacaoProduto } from 'px-business-components';
import Vue from 'vue';
import GrupoCompraCotacaoRespostaProdutoDetail from './GrupoCompraCotacaoRespostaProdutoDetail.vue';

export default {
  props: {
    produtos: Array,
    showEspecificacao: {
      type: Boolean,
      default: false,
    },
    showEspecificacaoLine: {
      type: Boolean,
      default: true,
    },
    checkSituacao: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isRespondida() {
      return item => (!this.checkSituacao ? true
        : item.situacao === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key);
    },
    prazos() {
      return first(this.produtos).prazos;
    },
    icon() {
      return item => (item.$active ? 'mdi-chevron-up' : 'mdi-chevron-down');
    },
  },
  methods: {
    toggleItem(item) {
      if (item.$active === undefined) {
        Vue.set(item, '$active', true);
        return;
      }

      // eslint-disable-next-line no-param-reassign
      item.$active = !item.$active;
    },
  },
  components: {
    pxGrupoCompraCotacaoRespostaProdutoDetail: GrupoCompraCotacaoRespostaProdutoDetail,
  },
};
</script>

<style scoped>
  .details-item {
    box-shadow: inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75);
  }
</style>
