<template>
  <div class="mt-5 ml-5">
    <v-divider/>
    <div class="mt-10 title-content">Perguntas</div>
    <v-row dense>
      <v-col cols="12">
        <v-alert
          class="mt-5"
          type="info"
          outlined
          dismissible>
          Todas as perguntas são enviadas de forma pública ao fornecedor.
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-textarea
          outlined
          auto-grow
          hide-details
          v-model="pergunta"
          :error="$v.pergunta.$error"
          @blur="$v.pergunta.$touch()"
          label="Escreva aqui sua pergunta"
          rows="1"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="text-right">
        <v-btn color="primary" @click="onSendDuvida" :loading="loading">
          Enviar
        </v-btn>
      </v-col>
    </v-row>

    <px-oferta-duvidas-list ref="duvidasList"
                            :oferta="oferta"
                            :method-getter-comprador="catalogoService.getDuvidas"/>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompradorService from '@/api/comprador-service';
import CatalogoService from '../catalogo-service';

export default {
  mixins: [validationMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      comprador: null,
      pergunta: null,
      loading: false,
      catalogoService: CatalogoService,
    };
  },
  methods: {
    getComprador() {
      CompradorService.getComprador()
        .then(({ data }) => {
          [this.comprador] = data;
        });
    },
    clearFormData() {
      this.pergunta = null;
      this.$v.$reset();
    },
    onCreateDuvida() {
      this.$refs.duvidasList.onChangeOptions();
    },
    buildPayload() {
      return {
        pergunta: this.pergunta,
        comprador: this.comprador,
      };
    },
    onSendDuvida() {
      this.loading = true;
      CatalogoService.criarDuvida(this.grupoId, this.oferta.id, this.buildPayload())
        .then(() => {
          this.onCreateDuvida();
          this.clearFormData();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getComprador();
  },
  validations: {
    pergunta: {
      required,
    },
  },
};
</script>
