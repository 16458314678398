<template>
  <v-card outlined>
    <v-subheader>Participantes</v-subheader>
    <v-divider/>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <v-list v-if="!loading">
      <template v-for="(participante, index) in participantes">
        <v-list-item :key="participante.comprador.id">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon small :color="iconColor(participante)">
                {{ icon(participante) }}
              </v-icon>
              {{ participante.comprador.pessoa.nome }}

            </v-list-item-title>
            <v-list-item-subtitle>
              {{ participante.comprador.pessoa.cnpj | cnpj }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn color="primary"
                   link :to="linkCompra(participante)"
                   v-if="!participante.finalizado && isCompradorAtual(participante)">
              Efetuar pedido
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index !== (participantes.length - 1)"
                   :key="`v-div-p${index}`"/>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { flatten, isEmpty, uniqBy } from 'lodash';
import CompradorService from '@/api/comprador-service';

export default {
  props: {
    cotacao: Object,
    compra: Object,
  },
  data() {
    return {
      participantes: [],
      compradores: [],
      loading: false,
    };
  },
  computed: {
    linkCompra() {
      return item => (`./${this.cotacao.id}/resposta/${this.compra.respostaCotacaoConjunta.id}/fechamento/${item.comprador.id}`);
    },
    icon() {
      return participante => (participante.finalizado ? 'mdi-check-circle' : 'mdi-clock');
    },
    iconColor() {
      return participante => (participante.finalizado ? 'primary' : 'amber darken-1');
    },
    isCompradorAtual() {
      return (item) => {
        if (isEmpty(this.compradores)) {
          return false;
        }
        return this.compradores.map(c => c.id)
          .includes(item.comprador.id);
      };
    },
  },
  methods: {
    mapParticipantes() {
      const participantes = flatten(this.cotacao.produtos.map(p => p.participantes));
      this.participantes = uniqBy(participantes, 'comprador.id');
    },
    getCompradores() {
      this.loading = true;
      CompradorService.getComprador()
        .then(({ data }) => {
          this.compradores = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCompradores();
    this.mapParticipantes();
  },
};
</script>
