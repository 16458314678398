import {
  SET_USER, SET_PASSWORD, CLEAR_DATA, LOADING, SET_ERROR,
} from './mutations.type';

export default {
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_PASSWORD](state, password) {
    state.password = password;
  },
  [CLEAR_DATA](state) {
    state.user = '';
    state.password = '';
    state.error = '';
  },
  [LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_ERROR](state, errorMessage) {
    state.error = errorMessage;
  },
};
