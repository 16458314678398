<template>
  <div class="bg-initial">
    <v-container fluid fill-height class="overlay">
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12" class="login-card">
          <px-floating-card
            color="primary"
            class="mx-auto"
            max-width="400"
          >
            <div slot="header">
              <v-card-text align="center">
                <v-row align="center" justify="center">
                  <img class="logo" src="../../assets/logo-branco-sem-fundo.png"
                       alt="Logo da Proxpect"/>
                  <h1>PROXPECT</h1>
                </v-row>
              </v-card-text>
            </div>
            <div class="error--text"
                 v-if="error"
                 align="center">
              {{error}}
            </div>
            <v-form
              class="login-content"
              ref="form"
            >
              <v-text-field
                solo
                v-model="user"
                :error="$v.user.$error"
                @blur="$v.user.$touch()"
                label="Usuário"
                required
              />
              <v-text-field
                solo
                v-model="password"
                label="Senha"
                type="password"
                :error="$v.password.$error"
                @blur="$v.password.$touch()"
                v-on:keyup.enter="doLogin"
                required
              />
            </v-form>
            <div slot="actions">
              <v-btn color="primary"
                     :disabled="$v.$invalid"
                     :loading="loading"
                     @click="doLogin">Entrar
              </v-btn>
            </div>
          </px-floating-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import EventService from '@/events';
import axios from '@/plugins/axios/axios-config';
import { LOGIN } from './store/actions.type';

export default {
  computed: {
    ...mapGetters({
      redirect: 'redirect',
    }),
    ...mapGetters('login', {
      loading: 'loading',
      error: 'error',
    }),
    user: {
      get() {
        return this.$store.getters['login/user'];
      },
      set(user) {
        this.$store.dispatch('login/user', user);
      },
    },
    password: {
      get() {
        return this.$store.getters['login/password'];
      },
      set(password) {
        this.$store.dispatch('login/password', password);
      },
    },
  },
  methods: {
    ...mapActions('login', {
      logar: LOGIN,
    }),
    doLogin() {
      if (!this.user || !this.password) {
        return;
      }
      this.logar().then(() => {
        EventService.createLogin(axios, null);
        if (this.redirect) {
          this.$router.push(this.redirect);
          return;
        }
        this.$router.push({ name: 'main' });
      });
    },
  },
  validations: {
    user: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>

<style scoped>
  .bg-initial {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/capa3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .bg-initial .overlay {
    background-color: rgba(21, 101, 192, 0.4);
    width: 100%;
  }

  .logo {
    width: 100px;
  }

  .login-content {
    padding-top: 15px;
  }
</style>
