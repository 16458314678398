<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && compra">
      <v-card>
        <v-card-text>
          <v-row dense>
            <v-col class="headline mb-1 text--primary" cols="12" sm="10">
              {{ compra.fornecedor.pessoa.nome }}
              <px-popover-fornecedor :fornecedor="compra.fornecedor" show-score />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon link
                         target="_blank"
                         :to="linkResposta"
                         v-on="on">
                    <v-icon>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
                <span>Abrir resposta</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="2" class="text-right">
              <px-refresh @refresh="onRefresh"/>
            </v-col>
            <p>
              <v-icon>mdi-domain</v-icon>
              {{ compra.fornecedor.pessoa.cnpj | cnpj }}
            </p>
          </v-row>
          <px-grupro-compra-cotacao-fechamento-participantes  class="mt-3"
                                                              :compra="compra"
                                                              :cotacao="cotacao"/>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import CotacaoConjuntaCompraService from '../../../cotacao-conjunta-compra';
import GruproCompraCotacaoFechamentoParticipantes from './GruproCompraCotacaoFechamentoParticipantes.vue';

export default {
  props: {
    cotacao: Object,
  },
  data() {
    return {
      grupoCompra: this.$route.params.idGrupo,
      loading: false,
      compra: null,
    };
  },
  computed: {
    linkResposta() {
      return `/${this.grupoCompra}/cotacao/${this.cotacao.id}/resposta/${this.compra.respostaCotacaoConjunta.id}`;
    },
  },
  methods: {
    onRefresh() {
      this.$emit('update');
    },
    getCompraConjunta() {
      this.loading = true;
      return CotacaoConjuntaCompraService.getCompraConjunta(this.cotacao.id)
        .then(({ data }) => {
          this.compra = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCompraConjunta();
  },
  components: {
    pxGruproCompraCotacaoFechamentoParticipantes: GruproCompraCotacaoFechamentoParticipantes,
  },
};
</script>
