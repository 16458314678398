<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-right">
        <px-grupo-compra-cotacao-visualizacao-adicionar-fornecedor
          :cotacao="cotacao"
          v-if="isUserAdmin && !readOnly && isAberta"
          @update="emitUpdate"/>
      </v-col>
    </v-row>
    <v-list v-if="cotacao.fornecedores && cotacao.fornecedores.length">
      <template v-for="(fornecedor, index) in cotacao.fornecedores">
        <v-list-item :key="fornecedor.id">
          <v-list-item-content>
            <v-row no-gutters dense>
              <v-col cols="12" sm="8" class="body-1">
                {{ fornecedor.pessoa.nome }}
                <px-popover-fornecedor :fornecedor="fornecedor" show-score/>
              </v-col>
              <v-col cols="12" sm="4">
                {{ fornecedor.endereco.municipio.nome }} - {{ fornecedor.endereco.estado.sigla }}
              </v-col>
            </v-row>
            <v-list-item-subtitle>
              {{ fornecedor.pessoa.cnpj | cnpj }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="onRemove(fornecedor)"
                   :disabled="loading"
                   v-if="isUserAdmin && !readOnly">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index !== (cotacao.fornecedores.length - 1)"
                   :key="`v-div-f${fornecedor.id}`"/>
      </template>
    </v-list>
  </div>
</template>

<script>
import { SituacaoCotacao } from 'px-business-components';
import GrupoCompraCotacaoVisualizacaoAdicionarFornecedor from './GrupoCompraCotacaoVisualizacaoAdicionarFornecedor.vue';
import CotacaoGrupoService from '../cotacoes-grupo-service';

const ABERTAS = [SituacaoCotacao.keys.PENDENTE_ENVIO.key,
  SituacaoCotacao.keys.ELABORACAO.key,
  SituacaoCotacao.keys.ABERTA.key];

export default {
  props: {
    cotacao: Object,
    isUserAdmin: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    onRemove(item) {
      this.loading = true;
      CotacaoGrupoService.removeFornecedor(this.cotacao.id, item.id)
        .then(() => {
          this.$toast('Fornecedor removido', { color: 'success' });
          this.emitUpdate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    isAberta() {
      return ABERTAS.includes(this.cotacao.situacao);
    },
  },
  components: {
    pxGrupoCompraCotacaoVisualizacaoAdicionarFornecedor:
    GrupoCompraCotacaoVisualizacaoAdicionarFornecedor,
  },
};
</script>
