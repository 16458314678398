<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon :small="small">
          mdi-file-document-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card class="mx-auto"
            max-width="400">
      <v-container>
        <div>
          <p class="mb-1 font-weight-bold caption">Especificação:</p>
          <p class="large-text mb-1">{{ especificacao | naoInformado }}</p>
        </div>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    especificacao: String,
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
};
</script>
