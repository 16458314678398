<template>
  <div v-if="isRespondida(item)">
    <v-simple-table class="ma-5 grey lighten-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Prazo</th>
            <th class="text-left">Valor unitário</th>
            <th class="text-left">ICMS</th>
            <th class="text-left">IPI</th>
            <th class="text-left">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="prazo in item.prazos" :key="`${prazo.id}details`">
            <td>
              <px-forma-pagamento :forma-pagamento="prazo.formaPagamento"
                                  :prazo-pagamento="prazo.prazoPagamento"/>
            </td>
            <td>
              <px-resposta-prazo-preco :value="prazo.precoUnitario" :situacao="prazo.situacao"/>
            </td>
            <td>
              <px-imposto v-if="isPrazoRespondido(prazo)"
                                 :imposto="prazo.impostos" tipo="ICMS"/>
            </td>
            <td>
              <px-imposto v-if="isPrazoRespondido(prazo)"
                                 :imposto="prazo.impostos" tipo="IPI"/>
            </td>
            <td>
              <px-resposta-prazo-preco class="font-weight-bold"
                :value="prazo.total" :situacao="prazo.situacao"/>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="ml-2" v-if="showEspecificacao">
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Especificação solicitada</span>
        <div class="large-text">
          {{ item.cotacaoConjuntaProduto.especificacaoProduto | naoInformado }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Especificação do fornecedor</span>
        <div class="large-text">
          {{ item.especificacaoProduto | naoInformado }}
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <span class="caption font-weight-bold">Arquivos</span>
        <div class="large-text" v-if="item.arquivos.length">
          <px-anexo-chip :files="item.arquivos"
                         download-base-path="files/download-fornecedor"/>
        </div>
        <div v-else>
          Nenhum anexo informado
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SituacaoRespostaCotacaoProduto, SituacaoRespostaPrazo } from 'px-business-components';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    showEspecificacao: {
      type: Boolean,
      default: false,
    },
    checkSituacao: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      produtos: null,
    };
  },
  computed: {
    isPrazoRespondido() {
      return prazo => prazo.situacao === SituacaoRespostaPrazo.keys.RESPONDIDA.key;
    },
    isRespondida() {
      return item => (!this.checkSituacao ? true
        : item.situacao === SituacaoRespostaCotacaoProduto.keys.RESPONDIDA.key);
    },
  },
};
</script>
