<template>
  <px-custom-dialog transition="fadet"
                    width="600"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Editando responsáveis
    </template>
    <template>
      <v-row class="mt-2">
          <px-autocomplete-user-grupo required
                                      class="required d-flex flex-grow-1 mr-2"
                                      :grupo-id="grupoId"
                                      :error="$v.user.$error"
                                      @blur="$v.user.$touch()"
                                      v-model="user" />
          <v-btn icon x-large
                 :loading="loading"
                 :disabled="$v.$invalid"
                 @click="onAdd">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
      </v-row>
      <v-subheader class="font-weight-bold mt-3">Responsáveis</v-subheader>
      <v-list>
        <template v-for="(admin, index) in cotacao.administradores">
          <v-list-item :key="admin.id">
            <v-list-item-content>
              {{ admin.nome }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon :disabled="loadingRemove" @click="onRemoveAdmin(admin)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index !== (cotacao.administradores.length - 1)"
                     :key="`v-div-usr-f${admin.id}`"/>
        </template>
      </v-list>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import CotacaoGrupoService from '../cotacoes-grupo-service';

export default {
  props: {
    cotacao: Object,
    grupoId: [String, Number],
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingRemove: false,
      user: null,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    onAdd() {
      this.loading = true;
      CotacaoGrupoService.adicionarAdmin(this.cotacao.id, this.user.user.id)
        .then(() => {
          this.$toast('Responsável adicionado', { color: 'success' });
          this.emitUpdate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRemoveAdmin(user) {
      this.loadingRemove = true;
      CotacaoGrupoService.removerAdmin(this.cotacao.id, user.id)
        .then(() => {
          this.$toast('Responsável removido', { color: 'success' });
          this.emitUpdate();
        })
        .finally(() => {
          this.loadingRemove = false;
        });
    },
    buildInitialState() {
      this.user = null;
      this.$v.$reset();
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    user: {
      required,
    },
  },
};
</script>
