<template>
  <div>
    <px-view-header title="Participante" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && participante">
      <v-card>
        <v-card-text>
          <p class="headline mb-1 text--primary">
            {{ participante.entidade.nome }}
          </p>
        </v-card-text>
      </v-card>

      <px-participante-detalhes-usuarios class="mt-3"/>
    </div>
  </div>
</template>

<script>
import ParticipanteService from '../participantes-service';
import ParticipanteDetalhesUsuarios from './ParticipanteDetalhesUsuarios.vue';

export default {
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      idParticipante: this.$route.params.idParticipante,
      loading: false,
      participante: null,
      breadcrumbs: [
        { text: 'Administrador' },
        { text: 'Participantes' },
        { text: 'Detalhes' },
      ],
    };
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    getParticipante() {
      this.loading = false;
      ParticipanteService.getParticipante(this.grupoId, this.idParticipante)
        .then(({ data }) => {
          this.participante = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getParticipante();
  },
  components: {
    pxParticipanteDetalhesUsuarios: ParticipanteDetalhesUsuarios,
  },
};
</script>
