<template>
  <div>
    <px-view-header title="Carrinho"/>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && carrinho">
      <v-alert
        type="info"
        outlined
        dismissible
        class="mb-10">
        Seu carrinho é exibido por empresa/filial e agrupado de acordo com a organização de cada
        fornecedor.
      </v-alert>
      <div v-if="!carrinho || !carrinho.length" align="center"
           class="headline mb-5">
        Ainda não há nenhum item no carrinho
      </div>

      <px-carrinho-empresa v-for="item in carrinho"
                           :key="item.comprador.id"
                           :empresa-carrinho="item"
                           class="mt-3" @change="onChange"/>
    </div>
  </div>
</template>

<script>
import { chain, first } from 'lodash';
import CarrinhoEmpresa from './CarrinhoEmpresa.vue';
import CarrinhoService from './carrinho-service';

export default {
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
      carrinho: null,
    };
  },
  methods: {
    onChange() {
      this.getCarrinho();
    },
    groupCarrinho(data) {
      return chain(data)
        .groupBy('comprador.id')
        .map(value => ({
          comprador: first(value).comprador,
          catalogos: value,
        }))
        .value();
    },
    getCarrinho() {
      this.loading = true;
      CarrinhoService.getCarrinho(this.grupoId)
        .then(({ data }) => {
          this.carrinho = this.groupCarrinho(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCarrinho();
  },
  components: {
    pxCarrinhoEmpresa: CarrinhoEmpresa,
  },
};
</script>
