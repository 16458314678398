<template>
  <div class="mt-5">
    <div class="mb-2">
      <px-autocomplete-comprador
        required
        dense
        :clearable="false"
        class="required"
        v-if="showSelecaoComprador"
        :error="$v.comprador.$error"
        @blur="$v.comprador.$touch()"
        @change="onChangeEmpresa"
        v-model="comprador"/>
    </div>
    <div>
      <px-input-number required
                       dense
                       label="Quantidade"
                       class="required"
                       autocomplete="off"
                       :error="$v.quantidade.$error"
                       @blur="$v.quantidade.$touch()"
                       v-model="quantidade"/>
    </div>
    <div class="mt-1">
      <v-btn block color="primary" :loading="loadingAdd" @click="onAddCarrinho">
        Adicionar ao carrinho
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompradorService from '@/api/comprador-service';
import { ADD_ITEM } from '@/store/carrinho/actions.type';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      showSelecaoComprador: false,
      quantidade: null,
      comprador: null,
    };
  },
  computed: {
    ...mapGetters('carrinho', ['loadingAdd']),
  },
  methods: {
    ...mapActions('carrinho', {
      addItem: ADD_ITEM,
    }),
    onChangeEmpresa() {
      this.$emit('comprador', this.comprador);
    },
    getComprador() {
      CompradorService.getComprador()
        .then(({ data }) => {
          [this.comprador] = data;
          this.onChangeEmpresa();
          if (data.length > 1) {
            this.showSelecaoComprador = true;
            return;
          }
          this.showSelecaoComprador = false;
        });
    },
    buildItem() {
      return {
        comprador: this.comprador,
        oferta: {
          id: this.oferta.id,
        },
        quantidade: this.quantidade,
      };
    },
    onAddCarrinho() {
      if (this.validateForm()) {
        return;
      }
      this.addItem({ grupoId: this.grupoId, payload: this.buildItem() })
        .then(() => {
          this.$toast('Item adicionado ao carrinho', { color: 'success' });
          this.quantidade = null;
          this.$v.$reset();
        });
    },
  },
  created() {
    this.getComprador();
  },
  validations: {
    quantidade: {
      required,
    },
    comprador: {
      required,
    },
  },
};
</script>
