<template>
  <div>
    <v-row>
      <v-col cols="12" class="headline mt-3">
        Localização aproximada
      </v-col>
    </v-row>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <v-row>
      <v-col cols="12">
        <gmap-map
          v-show="!loading"
          :center="center"
          :zoom="zoom"
          style="width:100%;  height: 400px;"
        >
          <gmap-marker
            v-if="marker"
            :position="marker"
          ></gmap-marker>
        </gmap-map>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { gmapApi } from 'vue2-google-maps';

export default {
  props: {
    fornecedor: Object,
  },
  data() {
    return {
      center: {
        lat: -28.67928,
        lng: -49.3594,
      },
      zoom: 6,
      marker: null,
      geocoder: null,
      loading: false,
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    onInitializeMaps() {
      this.codeAddress();
    },
    codeAddress() {
      this.loading = true;
      const address = Vue.filter('endereco')(this.fornecedor.endereco);
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const { location } = results[0].geometry;
          this.marker = location;
          this.center = location;
          this.zoom = 16;
          this.loading = false;
        } else {
          // eslint-disable-next-line no-console
          console.error(`Erro ao consultar coordenadas: ${status}`);
          this.loading = false;
        }
      });
    },
  },
  watch: {
    google() {
      if (this.google) {
        this.geocoder = new this.google.maps.Geocoder();
        this.onInitializeMaps();
      }
    },
  },
};
</script>
