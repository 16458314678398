<template>
  <v-card
    min-height="330px"
    :max-height="height"
  >
    <v-img
      src="../../assets/header-proxpect.jpeg"
      height="200px"
    ></v-img>

    <div class="profile-image-contaier">
      <div class="d-sm-flex">
        <v-img
          class="profile-image"
          src="../../assets/profile-3.jpeg"
          max-height="120px"
          height="120px"
          max-width="120px"
        ></v-img>
        <div class="align-self-end ml-2">
          <div>
            <h2 class="d-inline-block">{{ fornecedor.pessoa.nome }}</h2>
            <px-regime-tributario :regime-tributario="fornecedor.pessoa.regimeTributario"/>
            <px-show-avaliacao-icon :avaliacao="fornecedor.pessoa.entidade.pontuacaoMedia"/>
          </div>
          <div class="body-2 grey--text">
            {{ fornecedor.pessoa.cnpj | cnpj }} &#8231;
            {{ fornecedor.endereco | endereco }}
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    fornecedor: Object,
    score: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasDesricao() {
      return false;
    },
    height() {
      if (this.isSmall) {
        return '';
      }
      let height = 300;
      if (this.hasDesricao) {
        height += 60;
      }
      return `${height}px`;
    },
  },
};
</script>

<style scoped>
.profile-image-contaier {
  position: relative;
  bottom: 60px;
  left: 30px;
  width: 90%;
}

.profile-image {
  border: white 3px solid;
}
</style>
