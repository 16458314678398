<template>
  <v-hover v-slot:default="{ hover }">
    <v-card target="_blank"
            align="left"
            link
            max-width="280"
            :to="`/${grupoId}/catalogo/oferta/${oferta.id}`"
            :elevation="hover ? 6 : 1">
      <v-chip small
              label
              color="orange darken-2"
              class="destaque-flag caption"
              text-color="white"
              v-if="oferta.destaque">
        DESTAQUE
      </v-chip>
      <px-oferta-foto height="280"
                      :foto="oferta.produto.foto"/>
      <v-card-title class="body-1 oferta-title">
        <span :title="oferta.titulo">
          {{ oferta.titulo | truncate(45)}}
        </span>
      </v-card-title>
      <v-card-text v-if="oferta.disponivel">
        <div class="oferta-preco-original-container">
          <span class="text-line-through" v-if="!isSamePreco">
            {{ oferta.precoOriginal | currency }}
          </span>
          <px-oferta-tag-desconto :oferta="oferta"/>
        </div>
        <div class="text--primary">
          <span class="title">{{ oferta.precoAVista | currency }}</span> à vista
        </div>
        <div class="green--text">
          {{ oferta.precoAPrazo | currency }} a prazo
        </div>
        <div class="mt-2">
          Por:
          <router-link target="_blank"
                       :title="oferta.fornecedor.pessoa.nome"
                       :to="`/${grupoId}/pag/fornecedor/${oferta.fornecedor.id}`">
            {{ oferta.fornecedor.pessoa.nome | truncate(30) }}
          </router-link>
        </div>
      </v-card-text>
      <v-card-text v-else class="oferta-nao-disponivel-container">
        Não disponível
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import FileUrlPathService from '@/api/file-url-path-service';
import OfertaTagDesconto from './oferta/OfertaTagDesconto.vue';

export default {
  props: {
    index: [String, Number],
    oferta: Object,
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  methods: {},
  computed: {
    foto() {
      if (!this.oferta.produto.foto) {
        return null;
      }
      return FileUrlPathService.getPath(this.oferta.produto.foto.path);
    },
    isSamePreco() {
      return this.oferta.precoOriginal === this.oferta.precoAVista;
    },
  },
  components: {
    pxOfertaTagDesconto: OfertaTagDesconto,
  },
};
</script>

<style scoped>
.destaque-flag {
  position: absolute;
  top: 15px;
  z-index: 3;
  left: -5px;
}

.oferta-title {
  min-height: 80px;
  word-break: break-word;
}

.oferta-preco-original-container {
  min-height: 22px;
}

.oferta-nao-disponivel-container {
  min-height: 92px;
}
</style>
