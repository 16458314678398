import axios from 'axios';

const getPrazosPage = params => axios.get('/open/prazo-pagamento/page', {
  params,
});

const getPrazosCatalogoPage = (idCatalogo, params) => axios.get(`/fornecedor/catalogos/${idCatalogo}/prazos-pagamentos`, {
  params,
});

const getPrazosCatalogoPageCompleto = (idCatalogo, params) => axios.get(`/fornecedor/catalogos/${idCatalogo}/prazos-pagamentos/completo`, {
  params,
});

export default {
  getPrazosPage,
  getPrazosCatalogoPage,
  getPrazosCatalogoPageCompleto,
};
