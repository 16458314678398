export default {
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    if (!state.searchText) {
      return '';
    }
    return `(produto.descricao like '%${state.searchText.toLowerCase()}%' or identificador like '%${state.searchText.toLowerCase()}%')`;
  },
};
