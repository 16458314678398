<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && !avaliacoes.content.length"
         align="center"
         class="headline mb-5">
      Ainda não há nenhuma avaliação para sua empresa
    </div>
    <template v-if="avaliacoes.content.length">
      <v-row class="text--primary ml-2 body-1">
        <v-col cols="12">
          {{ avaliacoes.total }} avaliações
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" v-for="avaliacao in avaliacoes.content" :key="avaliacao.id">
          <px-avaliacao-item :pontuacao="avaliacao.avaliacao.pontuacao"
                             :comentario="avaliacao.avaliacao.comentario"/>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import AvaliacaoService from '@/api/avaliacao-service';

export default {
  props: {
    entidade: Object,
  },
  data() {
    return {
      loading: false,
      avaliacoes: {
        content: [],
        total: 0,
      },
    };
  },
  methods: {
    getAvaliacoes() {
      this.loading = true;
      AvaliacaoService.getAvaliacoes({
        query: `entidade=${this.entidade.id} order by createdIn desc`,
        limit: 100,
        offset: 0,
      })
        .then(({ data }) => {
          this.avaliacoes = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAvaliacoes();
  },
};
</script>
