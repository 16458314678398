<template>
  <span>{{ admins }}</span>
</template>

<script>
import { first } from 'lodash';

export default {
  props: {
    administradores: Array,
  },
  computed: {
    admins() {
      if (!this.administradores) {
        return null;
      }
      const text = first(this.administradores.map(a => a.nome));
      if (this.administradores.length > 1) {
        return `${text} (+${this.administradores.length - 1})`;
      }
      return text;
    },
  },
};
</script>
