<template>
  <div>
    <px-oferta-foto height="500"
                    width="500"
                    align="center"
                    :foto="oferta.produto.foto"/>
  </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
};
</script>
