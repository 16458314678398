<template>
  <v-container>
    <v-card class="mt-3">
      <v-list>
        <template v-for="(compra) in compras">
          <v-list-item dense :key="compra.idCotacaoConjuntaProdutoParticipante">
            <v-list-item-content>
              <v-row dense>
                <v-col cols="12" sm="5">
                   <px-forma-pagamento :forma-pagamento="compra.formaPagamento"
                                       :prazo-pagamento="compra.prazoPagamento"/>
                </v-col>
                <v-col cols="12" sm="5">
                  {{compra.itens.length}} produto(s)
                </v-col>
                <v-col cols="12" sm="2">
                  <px-anexo v-model="compra.arquivos" class="ma-0" :max="1">
                    <template v-slot:activator="{ on }">
                      <v-btn x-small color="primary" v-on="on">
                        Ordem de compra
                      </v-btn>
                    </template>
                  </px-anexo>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="compra.index < (keys - 1)"
            :key="`div2-${compra.idCotacaoConjuntaProdutoParticipante}`"/>
        </template>
      </v-list>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-textarea
          outlined
          auto-grow
          label="Observações internas"
          counter="1500"
          rows="2"
          v-model="observacao"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          outlined
          auto-grow
          class="required"
          label="Mensagem para o fornecedor"
          counter="1500"
          rows="2"
          v-model="mensagem"
          :error="$v.mensagem.$error"
          @blur="$v.mensagem.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="mr-2"
               :loading="loading"
               :disabled="$v.$invalid"
               @click="enviar">
          Enviar
        </v-btn>
        <v-btn @click="previous">
          Voltar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  groupBy, first, map,
} from 'lodash';
import { required } from 'vuelidate/lib/validators';
import CotacaoConjuntaCompra from '../cotacao-conjunta-compra';

export default {
  props: {
    resposta: Object,
    totais: Object,
    compraConjunta: Object,
  },
  data() {
    return {
      compras: {},
      mensagem: null,
      observacao: null,
      loading: false,
    };
  },
  computed: {
    keys() {
      return Object.keys(this.compras).length;
    },
  },
  methods: {
    createStringKey(total) {
      return total.formaPagamento + (total.prazoPagamento ? total.prazoPagamento.id : '');
    },
    previous() {
      this.$emit('previous');
    },
    mapSelected() {
      this.compras = this.totais.produtos.map(p => ({
        ...p,
        key: this.createStringKey(p.selected),
      }));

      let index = 0;
      this.compras = groupBy(this.compras, 'key');
      this.compras = map(this.compras, ((c) => {
        const compra = {
          itens: c,
          formaPagamento: first(c).selected.formaPagamento,
          prazoPagamento: first(c).selected.prazoPagamento,
          idCotacaoConjuntaProdutoParticipante: first(c).idCotacaoConjuntaProdutoParticipante,
          arquivos: [],
          mensagem: null,
          index,
        };
        index += 1;
        return compra;
      }));
    },
    mapItens(compra) {
      return compra.itens.map(item => ({
        prazo: {
          id: item.selected.id,
        },
        participante: {
          id: item.idCotacaoConjuntaProdutoParticipante,
        },
      }));
    },
    buildPayloadPrazos() {
      return map(this.compras, c => ({
        prazoPagamento: c.prazoPagamento,
        formaPagamento: c.formaPagamento,
        arquivos: c.arquivos,
        itens: this.mapItens(c),
      }));
    },
    buildPayload() {
      return {
        mensagem: this.mensagem,
        observacao: this.observacao,
        frete: this.resposta.frete,
        prazos: this.buildPayloadPrazos(),
      };
    },
    enviar() {
      this.loading = true;
      CotacaoConjuntaCompra.comprar(this.compraConjunta.id, this.buildPayload())
        .then(() => {
          this.$toast('Compra criada', { color: 'success' });
          this.$emit('update');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.mapSelected();
  },
  validations: {
    mensagem: {
      required,
    },
  },
};
</script>
