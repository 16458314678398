import axios from 'axios';

const getFornecedores = params => axios.get('/cotacoes', {
  params,
});

const createOrUpdate = (idGrupo, data) => {
  if (data.id) {
    return axios.put(`/grupo-compras/${idGrupo}/fornecedores/${data.id}`, data);
  }
  return axios.post(`/grupo-compras/${idGrupo}/fornecedores`, data);
};

const get = (idGrupo, id) => axios.get(`/grupo-compras/${idGrupo}/fornecedores/${id}`);
const list = (idGrupo, params) => axios.get(`/grupo-compras/${idGrupo}/fornecedores`, {
  params,
});

export default {
  getFornecedores,
  createOrUpdate,
  get,
  list,
};
