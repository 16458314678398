<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    max-width="350"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn x-small
             v-on="on"
             :disabled="disabled || loading"
             @click.stop.prevent="">
        {{listLength}} fornecedor(es) sem estoque
      </v-btn>
    </template>
    <v-card>
      <v-row class="text--primary px-3">
        <v-list>
          <template v-for="(resposta, index) in fornecedores">
            <v-list-item
              :key="resposta.id">
              <v-list-item-content>
                <v-list-item-title>{{ resposta.fornecedor.pessoa.nome }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="!resposta.outroMotivo">{{ situacao(resposta)  }}</span>
                  <v-tooltip top v-if="resposta.outroMotivo">
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                         {{ situacao(resposta) }}
                        </span>
                    </template>
                    <span>{{resposta.outroMotivo}}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="showDivider(index)" :key="`div-${resposta.id}`"></v-divider>
          </template>
        </v-list>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
import { SituacaoSolicitacao } from 'px-business-components';

export default {
  props: {
    cotacaoId: [String, Number],
    searchMethod: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      loading: true,
      menuOpened: false,
      fornecedores: [],
    };
  },
  computed: {
    disabled() {
      return !this.fornecedores || this.fornecedores.length <= 0;
    },
    listLength() {
      if (this.fornecedores) {
        return this.fornecedores.length;
      }
      return 0;
    },
    showDivider() {
      return index => index !== (this.fornecedores.length - 1);
    },
    situacao() {
      return item => SituacaoSolicitacao.keys[item.situacaoSolicitacaoResposta].description;
    },
  },
  methods: {
    getSemEstoque() {
      this.loading = true;
      this.searchMethod(this.cotacaoId)
        .then(({ data }) => {
          this.fornecedores = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getSemEstoque();
  },
};
</script>
