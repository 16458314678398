<template>
  <v-card
    class="mx-auto parent-list-overflow"
    height="450"
    max-height="450"
  >
    <v-card-title class="headline">
      Cotações pendentes
      <v-btn x-small link
             color="primary"
             class="ml-2"
             :to="`/${idGrupo}/cadastro`">
        <v-icon x-small>mdi-plus</v-icon>Cotação
      </v-btn>
    </v-card-title>
    <v-card-text class="text--primary list-overflow">
      <div v-if="loading">
        <px-circular-loading/>
      </div>
      <div>
        <v-list class="mt-3" v-if="!loading && cotacoes.length">
        <template v-for="cotacao in cotacoes">
          <v-list-item link :to="`/${idGrupo}/cotacao/${cotacao.id}`" :key="cotacao.id">
            <v-list-item-content>
              <v-row dense no-gutters>
                <v-col cols="12" sm="6">
                  <px-situacao-cotacao-icon :situacao="cotacao.situacao"/>
                  {{ cotacao.identificador }}
                </v-col>
                <v-col cols="12" sm="3" class="text-center">
                  {{ cotacao.quantidadeProdutos }}
                  <div>
                    produto(s)
                  </div>
                </v-col>
                <v-col cols="12" sm="3" class="text-center">
                  {{ cotacao.quantidadeRespostas }}
                  <div>
                    <small>
                      resposta(s)
                    </small>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`div-${cotacao.id}`"/>
        </template>
      </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CotacaoService from '@/modules/cotacoes/cotacoes-grupo-service';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      cotacoes: [],
    };
  },
  computed: {
    ...mapGetters(['idGrupo']),
  },
  methods: {
    getCotacoes() {
      this.loading = true;
      CotacaoService.getList({
        query: `grupoCompra.id = ${this.idGrupo} and situacao in ("ABERTA", "PENDENTE_ENVIO", "COMPRA", "ELABORACAO") order by createdIn desc`,
        limit: 50,
        oportunidades: false,
      })
        .then(({ data }) => {
          this.cotacoes = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCotacoes();
  },
};
</script>

<style scoped>
.parent-list-overflow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list-overflow {
  width: 100%;
  height: 395px;
  max-height: 395px;
  padding-right: 17px;
  overflow-y: scroll;
  box-sizing: content-box;
}
</style>
