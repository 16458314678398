<template>
  <transition name="fade">
    <v-card v-if="produtos.length">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Produto</th>
            <th class="text-left">Quantidade</th>
            <th class="text-left">Especificação</th>
            <th class="text-left">Preço praticado</th>
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(produto, index) in produtos" :key="index">
            <td>{{ produto.produto.descricao }}</td>
            <td>{{ produto.quantidade }} ({{ produto.unidadeMedida.descricao }})</td>
            <td>
              <template v-if="produto.especificacaoProduto">
                <px-span-tooltip top :text="especificacao(produto)"
                                 :tooltip="produto.especificacaoProduto"/>
              </template>
              <template v-else>
                Não informado
              </template>
              <div class="d-inline-block ml-2">
              <px-anexo dot read-only :downloadable="false" v-model="produto.arquivos">
                <template v-slot:activator="{ on }">
                  <v-btn icon
                         small
                         v-on="on">
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
              </px-anexo>
              </div>
            </td>
            <td>
              {{ produto.precoPraticado | currency }}
            </td>
            <td>
              <v-btn icon :disabled="editing" @click="onClickEdit(index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="onClickRemove(index)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    produtos: {
      type: Array,
      required: true,
    },
    editing: Boolean,
  },
  computed: {
    showDivider() {
      return index => index !== (this.produtos.length - 1);
    },
    especificacao() {
      return item => Vue.filter('truncate')(item.especificacaoProduto, 35);
    },
  },
  methods: {
    onClickEdit(index) {
      this.$emit('edit', index);
    },
    onClickRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>

<style scoped>
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 1s;
  }

  .fade-leave {
    transition: none;
  }

  .fade-leave-active {
    transition: none;
  }

</style>
