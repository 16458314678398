import currency from 'currency.js';
import { TipoFrete } from 'px-business-components';

function calcularTotal(ofertas, path) {
  const precos = ofertas.map(oferta => ({
    valor: oferta.oferta[path],
    quantidade: oferta.quantidade,
  }));
  let total = currency(0);
  precos.forEach((preco) => {
    const valorOferta = currency(preco.valor).multiply(currency(preco.quantidade));
    total = total.add(valorOferta);
  });
  return total.value;
}

function calculaFreteCifFob(freteCep, totalAVista) {
  if (freteCep.ofertaFreteGratis && totalAVista >= freteCep.taxaMinimaEntrega) {
    return {
      tipoFrete: TipoFrete.keys.CIF.key,
      valorFrete: 0,
    };
  }
  return {
    tipoFrete: TipoFrete.keys.FOB.key,
    valorFrete: 0,
  };
}

function calculaCif(freteCep, totalAVista) {
  if (freteCep.ofertaFreteGratis && totalAVista >= freteCep.taxaMinimaEntrega) {
    return {
      tipoFrete: TipoFrete.keys.CIF.key,
      valorFrete: 0,
    };
  }

  return {
    tipoFrete: TipoFrete.keys.CIF.key,
    valorFrete: freteCep.valorTaxaEntrega,
  };
}

function calculaFrete(freteCep, totalAVista) {
  if (freteCep.tipoFrete === TipoFrete.keys.FOB.key) {
    return calculaFreteCifFob(freteCep, totalAVista);
  }

  return calculaCif(freteCep, totalAVista);
}

export default {
  calcularTotal,
  calculaFrete,
};
