<template>
  <v-autocomplete
    outlined
    hide-details
    clearable
    return-object
    hide-selected
    hide-no-data
    no-filter
    multiple
    chips
    small-chips
    deletable-chips
    item-text="pessoa.nome"
    label="Fornecedor"
    v-model="localValue"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    @blur="onBlur"
    @focus="onFocus"
    v-bind="$attrs"
  />
</template>

<script>
import { debounce } from 'vuetify/lib/util/helpers';
import FornecedorService from '@/api/fornecedor-service';

export default {
  props: {
    value: Array,
    path: {
      type: String,
      default: 'fornecedor/all/comprador',
    },
    cotacoes: Array,
  },
  data() {
    return {
      loading: false,
      items: [],
      model: null,
      search: null,
      debounceSearch: null,
    };
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  watch: {
    search(val) {
      this.debounceSearch(val);
    },
  },
  methods: {
    buildFilter(val) {
      const text = val || '';
      let filter = `nome like "%${text.toLowerCase()}%" or cnpj like "%${text.toLowerCase()}%"`;
      if (this.value && this.value.length) {
        const ids = this.value.map(cot => cot.id)
          .join(' and id != ');
        filter = `${filter} and id != ${ids}`;
      }
      return `${filter} order by nome`;
    },
    getCotacoes() {
      if (!this.cotacoes || !this.cotacoes.length) {
        return [];
      }
      return this.cotacoes.map(cot => cot.id).join(',');
    },
    searchFornecedores(val) {
      this.loading = true;

      FornecedorService.getFornecedoresCotacao(this.path, {
        cotacoes: this.getCotacoes(),
        query: this.buildFilter(val),
      })
        .then((response) => {
          this.items = response.data;
          if (this.value && this.value.length) {
            this.items = [...this.items, ...this.value];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus() {
      this.debounceSearch(null);
    },
  },
  created() {
    this.debounceSearch = debounce(this.searchFornecedores, 1000);
    if (this.value) {
      this.items = [this.value];
    }
  },
};
</script>
