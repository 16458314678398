<template>
  <div class="mt-1 ml-2">
    <span class="display-1">
      {{ oferta.titulo }}
    </span>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span class="subtitle-1" v-on="on">{{ oferta.codigo }}</span>
        </template>
        <span>Código de referência</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
};
</script>
