<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn small icon v-on="{ ...onTooltip, ...on }">
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </template>
        <span>
          Editar produto
        </span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto"
            min-width="300"
            max-width="400">
      <v-card-text>
        <v-row dense no-gutters>
          <v-col cols="12">
            <px-input-number label="Quantidade"
                             required
                             class="required"
                             :error="$v.quantidade.$error"
                             @blur="$v.quantidade.$touch()"
                             v-model="quantidade"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :disabled="$v.$invalid"
               :loading="loading"
               @click="save">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import CarrinhoService from './carrinho-service';

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-pencil',
    },
    oferta: {
      type: Object,
      required: true,
    },
    grupoId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      opened: false,
      loading: false,
      quantidade: null,
    };
  },
  methods: {
    close() {
      this.opened = false;
    },
    buildInitialState() {
      this.quantidade = this.oferta.quantidade;
    },
    buildPayload() {
      return {
        quantidade: this.quantidade,
      };
    },
    save() {
      this.loading = true;
      CarrinhoService.updateOferta(this.grupoId, this.oferta.id, this.buildPayload())
        .then(() => {
          this.$toast('Oferta atualizada', { color: 'success' });
          this.$emit('change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    opened(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    quantidade: {
      required,
    },
  },
};
</script>
