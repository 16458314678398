<template>
  <v-navigation-drawer
    app
    :temporary="isSmall"
    :permanent="!isSmall"
    :expand-on-hover="!isSmall"
    :mini-variant.sync="mini"
    v-model="localValue"
    width="320"
    class="parent-list-overflow"
  >
    <div class="list-overflow">
      <v-list-item class="pl-2">
        <v-avatar color="orange" size="40" class="mr-2">
          <v-img src="../../assets/default-user.png"/>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title class="title">
            <strong v-if="user">{{ user.nome }}</strong>
          </v-list-item-title>
          <v-list-item-subtitle>
            Bem-vindo!
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>

      <v-list dense>
        <v-list-item
          target="_blank"
          active-class="orange--text"
          @click="openModuloGestao"
        >
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Módulo de gestão</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-open-in-new</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list>
        <v-list-item
          link
          :to="`/${idGrupo}/catalogo`"
          active-class="orange--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-store</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Catálogo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense>
        <v-list-group
          active-class="grey--text text--darken-2"
          value="true"
          v-model="expandIndicadores"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-chart-areaspline</v-icon>
            </v-list-item-icon>
            <v-list-item-title dense>Indicadores</v-list-item-title>
          </template>
          <v-list-item
            link
            active-class="orange--text"
            :class="groupItemClass"
            :disabled="!hasModuleGerencia"
            :to="`/${idGrupo}/meu-painel`"
          >
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Meu painel</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            active-class="orange--text"
            :class="groupItemClass"
            :to="`/${idGrupo}/indicadores-catalogo`"
          >
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Indicadores do catálogo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list dense>
        <v-list-group
          active-class="grey--text text--darken-2"
          value="true"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-currency-usd-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title dense>Negócios</v-list-item-title>
          </template>
          <v-list-item
            link
            active-class="orange--text"
            :class="groupItemClass"
            :disabled="!hasModuleCompraConjunta"
            :to="`/${idGrupo}/oportunidades`"
          >
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Oportunidades</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            active-class="orange--text"
            :class="groupItemClass"
            :disabled="!hasModuleCompraConjunta"
            :to="`/${idGrupo}/cotacoes`"
          >
            <v-list-item-icon>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Minhas cotações</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            active-class="orange--text"
            :class="groupItemClass"
            :to="`/${idGrupo}/compras`"
          >
            <v-list-item-icon>
              <v-icon>mdi-shopping</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Minhas compras</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list dense>
        <v-list-group
          active-class="grey--text text--darken-2"
          value="true"
          v-model="expandMinhaEmpresa"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>
            <v-list-item-title dense>Empresa</v-list-item-title>
          </template>
          <v-list-item
            link
            :class="groupItemClass"
            :to="`/${idGrupo}/minha-empresa`"
            active-class="orange--text"
          >
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Minha empresa</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-list dense>
        <v-list-group
          v-if="isGrupoAdmin"
          active-class="grey--text text--darken-2"
          value="true"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-account-lock</v-icon>
            </v-list-item-icon>
            <v-list-item-title dense>Administrador</v-list-item-title>
          </template>
          <v-list-item
            link
            :class="groupItemClass"
            :to="`/${idGrupo}/grupo-compras`"
            active-class="orange--text"
          >
            <v-list-item-icon>
              <v-icon>mdi-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Grupo de compras</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :class="groupItemClass"
            :to="`/${idGrupo}/participantes`"
            active-class="orange--text"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Participantes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :class="groupItemClass"
            :to="`/${idGrupo}/fornecedores`"
            active-class="orange--text"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Fornecedores</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

const gestaoUrl = process.env.VUE_APP_GESTAO_URL;

export default {
  props: ['value'],
  data() {
    return {
      mini: true,
      expandIndicadores: false,
      expandFornecedores: false,
      expandMinhaEmpresa: false,
      gestaoUrl,
    };
  },
  computed: {
    ...mapGetters('user', ['user', 'hasModuleGerencia', 'hasModuleCompraConjunta', 'hasModuleGerenciaOrCompraConjunta']),
    ...mapGetters(['idGrupo', 'isGrupoAdmin']),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
    groupItemClass() {
      return this.mini ? '' : 'group-item';
    },
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    miniComputed: {
      get() {
        return this.isSmall;
      },
    },
  },
  methods: {
    openModuloGestao() {
      window.open(`${this.gestaoUrl}/meu-painel`, '_blank');
    },
  },
  created() {
    if (this.isSmall) {
      this.mini = false;
    }
  },
};
</script>

<style scoped>
.group-item {
  padding-left: 30px !important;
}

.parent-list-overflow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list-overflow {
  width: 100%;
  height: 100%;
  padding-right: 17px;
  overflow-y: scroll;
  box-sizing: content-box;
}
</style>
