<template>
  <div>
    <v-row dense no-gutters>
      <template v-for="(item, index) in ofertas">
        <v-col cols="12" sm="6" md="4" lg="3"
               class="mt-6"
               :key="item.id"
               align="center">
          <px-catalogo-produto-card :oferta="item"
                                    :index="index"/>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import CatalogoProdutoCard from './CatalogoProdutoCard.vue';

export default {
  props: {
    ofertas: Array,
  },
  components: {
    pxCatalogoProdutoCard: CatalogoProdutoCard,
  },
};
</script>
