<template>
  <v-menu
    left
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="430"
    max-width="500"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon
                 v-on="{ ...onTooltip, ...on }"
                 @click.stop.prevent=""
          >
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <span>Filtros personalizados</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        Filtros personalizados
      </v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-switch hide-details
                      class="pb-2 mr-2"
                      label="Filtrar produtos"
                      v-model="filters.includeProdutos"
                      @change="emitChange"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    filters: Object,
  },
  data() {
    return {
      menuOpened: false,
    };
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
};
</script>
