<template>
  <div class="d-inline-block">
    <v-tooltip top v-if="!produto.$recusado">
      <template v-slot:activator="{ on }">
        <v-btn icon class="ml-2" color="error" v-on="on" @click="onRemoveProduto(produto)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>Não entrar neste produto</span>
    </v-tooltip>
    <v-tooltip top v-else>
      <template v-slot:activator="{ on }">
        <v-btn icon class="ml-2" color="success" v-on="on" @click="onAddProduto(produto)">
          <v-icon>mdi-undo-variant</v-icon>
        </v-btn>
      </template>
      <span>Entrar neste produto</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    produto: Object,
  },
  data() {
    return {
      dialog: false,
      itemCotacao: {
        comprador: null,
        produtos: [],
      },
    };
  },
  methods: {
    onRemoveProduto(produto) {
      this.$emit('remove', produto);
    },
    onAddProduto(produto) {
      this.$emit('add', produto);
    },
  },
};
</script>
