import { get } from 'lodash';

function comparePrazos(a, b) {
  const prazoA = get(a, 'prazoPagamento.descricao') || '0';
  const prazoB = get(b, 'prazoPagamento.descricao') || '0';
  if (prazoA > prazoB) {
    return 1;
  }

  if (prazoA < prazoB) {
    return -1;
  }

  return 0;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  comparePrazos,
};
