<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && usuarios">
      <v-card>
        <v-card-title>
          Usuários
          <px-participante-cadastro-usuario :grupo-id="grupoId"
                                            :participante-id="idParticipante"
                                            @change="onChange">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-2" small color="primary" v-on="on">
                <v-icon small>mdi-plus</v-icon>
                Usuário
              </v-btn>
            </template>
          </px-participante-cadastro-usuario>
        </v-card-title>
        <v-card-text>
          <div v-if="!loading && !usuarios.length"
               align="center"
               class="body-1 mb-5 ml-2">
            Ainda não há usuários vinculados
          </div>
          <v-list>
            <template v-for="(user, index) in usuarios">
              <v-list-item :key="user.id">
                <v-list-item-content>
                  {{ user.user.nome }}
                </v-list-item-content>
                <div v-if="user.isGrupoAdmin">
                  <v-chip label color="secondary">
                    Admin
                  </v-chip>
                </div>
                <v-list-item-action>
                  <v-btn icon @click="onClickRemove(user)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="index !== (usuarios.length - 1)"
                         :key="`v-div-${user.id}`"/>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </div>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import ParticipanteService from '../participantes-service';
import ParticipanteCadastroUsuario from './ParticipanteCadastroUsuario.vue';

export default {
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      idParticipante: this.$route.params.idParticipante,
      loading: false,
      usuarios: [],
      breadcrumbs: [
        { text: 'Administrador' },
        { text: 'Participantes' },
        { text: 'Detalhes' },
      ],
    };
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    onChange() {
      this.getParticipante();
    },
    getParticipante() {
      this.loading = false;
      ParticipanteService.getUsuarios(this.grupoId, this.idParticipante)
        .then(({ data }) => {
          this.usuarios = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(participante) {
      ParticipanteService.removeUsuario(this.grupoId,
        participante.grupoCompraEntidade.entidade.id,
        participante.id)
        .then(() => {
          this.getParticipante();
          this.$toast('Usuário removido', { color: 'success' });
        });
    },
    onClickRemove(participante) {
      this.$refs.confirm.open('Você deseja remover o usuário?',
        'O usuário não terá mais acesso ao grupo',
        { width: 300 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.remove(participante);
        });
    },
  },
  created() {
    this.getParticipante();
  },
  components: {
    pxParticipanteCadastroUsuario: ParticipanteCadastroUsuario,
  },
};
</script>
