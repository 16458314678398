import axios from 'axios';

const getRespostas = (idCotacao, params) => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes`, { params });
const getRespostasProdutos = (idCotacao, params) => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes-produtos`, { params });
const getResposta = (idCotacao, id) => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes/${id}`, {});
const getSemEstoque = idCotacao => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes/sem-estoque`, {});
const getRespostaSimulacao = (idCotacao, id) => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes/${id}/simular-compra`, {});
const getRespostaTotais = (idCotacao, id) => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes/${id}/meus-totais`, {});
const getRespostaTotaisByComprador = (idCotacao, id, idComprador) => axios.get(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes/${id}/meus-totais/comprador/${idComprador}`, {});

const escolherFornecedor = (idCotacao, idResposta) => axios.post(`/cotacoes-conjuntas/${idCotacao}/respostas-cotacoes/${idResposta}/comprar`, {});
const notificarNegociacao = idResposta => axios.post(`/notificacoes/negociacao_cotacao_conjunta/${idResposta}`, {});
const notificarAcompanhamento = idCotacao => axios.post(`/notificacoes/cotacao_conjunta/${idCotacao}`, {});

export default {
  getRespostas,
  getRespostasProdutos,
  getResposta,
  getSemEstoque,
  getRespostaSimulacao,
  getRespostaTotais,
  getRespostaTotaisByComprador,
  escolherFornecedor,
  notificarNegociacao,
  notificarAcompanhamento,
};
