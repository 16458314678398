<template>
  <v-btn :color="color" icon @click="onClick">
    <v-icon>mdi-refresh</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: String,
  },
  methods: {
    onClick() {
      this.$emit('refresh');
    },
  },
};
</script>
