<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && fornecedor">
      <px-pagina-fornecedor-header :fornecedor="fornecedor"/>
      <px-pagina-fornecedor-detalhes class="mt-3" :fornecedor="fornecedor"/>
    </div>
  </div>
</template>

<script>
import PaginaFornecedorHeader from './PaginaFornecedorHeader.vue';
import PaginaFornecedorDetalhes from './PaginaFornecedorDetalhes.vue';
import PaginaFornecedorService from './pagina-fornecedor-service';

export default {
  data() {
    return {
      id: this.$route.params.id,
      fornecedor: null,
      loading: false,
    };
  },
  methods: {
    getFornecedor() {
      this.loading = true;
      PaginaFornecedorService.getFornecedorPagina(this.id)
        .then(({ data }) => {
          this.fornecedor = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFornecedor();
  },
  components: {
    pxPaginaFornecedorHeader: PaginaFornecedorHeader,
    // pxPaginaFornecedorTabs: PaginaFornecedorTabs,
    pxPaginaFornecedorDetalhes: PaginaFornecedorDetalhes,
  },
};
</script>
