import Vue from 'vue';
import Vuetify, {
  VSnackbar, VBtn, VIcon, VRow, VCol, VSpacer, VBreadcrumbs, VTextField,
  VCard, VCardText, VCardActions, VCardTitle, VCardSubtitle,
  VSelect, VDatePicker, VMenu, VProgressCircular, VDialog,
  VList, VListItem, VListItemAction, VListItemContent, VListItemGroup,
  VListItemSubtitle, VListItemTitle, VToolbarTitle, VToolbar,
  VChip, VChipGroup, VTooltip, VAutocomplete, VContainer, VContent, VBadge,
  VTimePicker, VTextarea, VTimeline, VTimelineItem, VRating, VDivider,
  VListItemIcon, VTabItem, VTabsItems, VTabs, VTab, VSubheader, VAvatar,
  VPagination,
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VRow,
    VCol,
    VSpacer,
    VBreadcrumbs,
    VTextField,
    VCard,
    VCardText,
    VCardActions,
    VCardTitle,
    VCardSubtitle,
    VSelect,
    VDatePicker,
    VMenu,
    VProgressCircular,
    VDialog,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemGroup,
    VListItemSubtitle,
    VListItemTitle,
    VToolbarTitle,
    VToolbar,
    VChip,
    VChipGroup,
    VTooltip,
    VAutocomplete,
    VContainer,
    VContent,
    VBadge,
    VTimePicker,
    VTextarea,
    VTimeline,
    VTimelineItem,
    VRating,
    VDivider,
    VListItemIcon,
    VTabItem,
    VTabsItems,
    VTabs,
    VTab,
    VSubheader,
    VAvatar,
    VPagination,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken3,
        secondary: colors.orange.accent4,
        accent: colors.orange.accent4,
      },
    },
  },
});
