var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.fornecedores.content,"loading":_vm.loading,"server-items-length":_vm.fornecedores.total,"footer-props":_vm.footerOptions,"no-data-text":"Nenhum fornecedor encontrado","loading-text":"Carregando fornecedores...","options":_vm.optionsList},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.fornecedor.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fornecedor.pessoa.nome)+" "),_c('px-open-pagina-fornecedor',{attrs:{"id":item.fornecedor.id,"is-icon":false}})]}},{key:"item.fornecedor.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cnpj")(item.fornecedor.pessoa.cnpj))+" ")]}},{key:"item.situcacao",fn:function(ref){
var item = ref.item;
return [_c('px-ativo',{attrs:{"ativo":item.ativo}})]}},{key:"item.permiteCatalogo",fn:function(ref){
var item = ref.item;
return [_c('px-sim-nao',{attrs:{"ativo":item.catalogoHabilitado}})]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('px-cadastro-fornecedor',{attrs:{"fornecedor":item,"id-grupo":_vm.idGrupo},on:{"change":_vm.emitUpdate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})]}}])}),_c('px-confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }