import {
  SET_GRUPO, SET_USER_GROUP,
} from './mutations.type';

export default {
  [SET_GRUPO](state, grupo) {
    state.grupo = grupo;
  },
  [SET_USER_GROUP](state, userGrupo) {
    state.userGrupo = userGrupo;
  },
};
