<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && !respostas.length"
         align="center"
         class="headline ma-10">
      Nenhuma resposta encontrada por aqui
    </div>
    <v-expansion-panels multiple focusable v-if="!loading && respostas && respostas.length">
      <v-expansion-panel
        v-for="resposta in respostas"
        :key="resposta.id"
      >
        <v-expansion-panel-header>
          <v-row dense align="center">
            <v-col cols="12" sm="3" class="text-truncate">
              <px-status-negociacao :status-negociacao="resposta.statusNegociacao"/>
              {{ resposta.fornecedor.pessoa.nome }}
              <px-popover-fornecedor :fornecedor="resposta.fornecedor" show-score />
            </v-col>
            <v-col cols="12" sm="2">
                {{ resposta.frete.tipoFrete }}&nbsp;
              <div class="caption">
               {{ resposta.frete.prazoEntrega | pluralize(['dia útil', 'dias úteis']) }}
              </div>
            </v-col>
            <v-col v-for="prazo in priceKeys" cols="6" sm="2" :key="`${resposta.id}${prazo.key}`">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <template v-if="resposta.$totais[prazo.key].total">
                      {{ resposta.$totais[prazo.key].total | currency }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </span>
                </template>
                <span>
                <span>{{ resposta.$totais[prazo.key].quantidadeProdutos }} produto(s)</span>
                </span>
              </v-tooltip>
              <div class="caption">
               <px-forma-pagamento :forma-pagamento="resposta.$totais[prazo.key].formaPagamento"
                                   :prazo-pagamento="resposta.$totais[prazo.key].prazoPagamento"/>
              </div>
            </v-col>
            <v-col cols="12" sm="1" @click.prevent.stop="">
              <v-btn icon link
                :to="`/${grupoCompra}/cotacao/${cotacao.id}/resposta/${resposta.id}`">
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <px-grupo-compra-cotacao-resposta-produtos :resposta="resposta"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { first, find, groupBy } from 'lodash';
import { comparePrazos } from '@/utils/prazo-utils';
import CotacaoConjuntaRespostas from '../../cotacao-conjunta-resposta';
import GrupoCompraCotacaoRespostaProdutos from './GrupoCompraCotacaoRespostaProdutos.vue';

export default {
  props: {
    cotacao: Object,
    searchText: String,
    sort: Object,
    initSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      grupoCompra: this.$route.params.idGrupo,
      respostas: [],
      priceKeys: [],
    };
  },
  methods: {
    createPrazosObjects(prazo) {
      return {
        ...prazo,
        key: this.createStringKey(prazo),
      };
    },
    createStringKey(total) {
      return total.formaPagamento + (total.prazoPagamento ? total.prazoPagamento.id : '');
    },
    createKeyPagamento(resposta) {
      return resposta.totais.map(total => ({
        ...total,
        key: this.createStringKey(total),
      }));
    },
    createKeyRespostas(respostas) {
      respostas.forEach((resposta) => {
        Object.keys(resposta.$totais)
          .forEach((key) => { /* eslint-disable no-param-reassign */
            resposta.$totais[key] = first(resposta.$totais[key]);
          });
        this.priceKeys.forEach((priceKey) => {
          const hasKey = find(resposta.$totais, { key: priceKey.key });
          if (!hasKey) {
            resposta.$totais[priceKey.key] = priceKey;
          }
        });
      });
    },
    mapRespostas(data) {
      const respostas = data.map(resposta => ({
        ...resposta,
        totais: this.createKeyPagamento(resposta),
      }));
      return respostas.map(resposta => (
        {
          ...resposta,
          $totais: groupBy(resposta.totais, 'key'),
        }
      ));
    },
    getRespostas() {
      this.loading = true;
      CotacaoConjuntaRespostas.getRespostas(this.cotacao.id, {
        query: this.buildQuery(),
      })
        .then(({ data }) => {
          this.priceKeys = this.cotacao.prazosPagamentos
            .map(prazo => this.createPrazosObjects(prazo))
            .sort(comparePrazos);
          this.respostas = this.mapRespostas(data);
          this.createKeyRespostas(this.respostas);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildQuery() {
      return `${this.buildFilter()} ${this.buildSort()}`.trim();
    },
    buildFilter() {
      if (!this.searchText) {
        return '';
      }
      return `fornecedor.nome like "%${this.searchText.toLowerCase()}%"`;
    },
    buildSort() {
      if (!this.sort) {
        return '';
      }
      return `order by ${this.sort.value}`;
    },
  },
  watch: {
    searchText(newText, oldText) {
      if (newText === oldText) {
        return;
      }
      this.getRespostas();
    },
    sort(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.getRespostas();
    },
  },
  created() {
    if (this.initSearch) {
      this.getRespostas();
    }
  },
  components: {
    pxGrupoCompraCotacaoRespostaProdutos: GrupoCompraCotacaoRespostaProdutos,
  },
};
</script>
