<template>
  <div>
    <v-row dense class="mx-1">
      <px-participante-cadastro :grupo-id="grupoId" @change="emitChange">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary">
            <v-icon left>mdi-plus</v-icon>
            Participante
          </v-btn>
        </template>
      </px-participante-cadastro>

      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por uma empresa" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="emitChange"/>
    </v-row>
  </div>
</template>

<script>
import ParticipanteCadastro from './cadastro/ParticipanteCadastro.vue';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  computed: {
    searchText: {
      get() {
        return this.$store.getters['participantes/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('participantes/searchText', searchText);
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
  components: {
    pxParticipanteCadastro: ParticipanteCadastro,
  },
};
</script>
