<template>
  <nav>
    <v-app-bar dark
               app
               color="primary">
      <v-app-bar-nav-icon v-if="isSmall" @click="toggleNavigation"/>

      <img class="header-logo" src="../../assets/logo-branco-sem-fundo.png"
           alt="Logo da Proxpect"/>
      <v-toolbar-title><strong>PROXPECT</strong></v-toolbar-title>

      <span class="ml-5 pl-3 group-name" v-if="!isSelecaoGrupo">
        {{ grupoCompra.nome }}
        <v-btn x-small
               link
               to="/selecao-grupo"
               class="ml-1 black--text"
               color="white">
          Alterar
        </v-btn>
      </span>

      <v-spacer/>

      <px-carrinho-button v-if="!isSelecaoGrupo" class="mx-5"/>
      <px-notificacoes v-if="!isSelecaoGrupo"/>
      <px-user-info v-if="!isSelecaoGrupo"/>
    </v-app-bar>

    <px-naviagtion v-if="!isSelecaoGrupo" v-model="navigationOpened" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import PxCarrinhoButton from '@/modules/carrinho/PxCarrinhoButton.vue';
import Navigation from '../navigation/Navigation.vue';

export default {
  data() {
    return {
      navigationOpened: false,
    };
  },
  computed: {
    ...mapGetters(['grupoCompra']),
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isSelecaoGrupo() {
      return this.$route.name === 'selecao-grupo';
    },
  },
  methods: {
    toggleNavigation() {
      this.navigationOpened = !this.navigationOpened;
    },
    changeNavigation(value) {
      if (this.navigationOpened !== value) {
        this.navigationOpened = value;
      }
    },
  },
  components: {
    PxCarrinhoButton,
    pxNaviagtion: Navigation,
  },
};
</script>

<style scoped>
  .header-logo {
    max-width: 60px;
  }

  .group-name {
    border-left: 2px solid white;
  }
</style>
