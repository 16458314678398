export default {
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    if (!state.searchText) {
      return '';
    }
    return `entidade.nome like '%${state.searchText.toLowerCase()}%'`;
  },
};
