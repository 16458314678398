<template>
  <v-tooltip top v-if="oferta">
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="on" link
             target="_blank"
             :to="`/${grupoId}/catalogo/oferta/${oferta.id}`">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>
        Abrir oferta
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-open-in-new',
    },
    oferta: {
      type: Object,
      required: true,
    },
    grupoId: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
