<template>
  <px-custom-dialog transition="fadet"
                    width="450"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Adicionar participante
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-entidade required
                                    class="required"
                                    :error="$v.entidade.$error"
                                    @blur="$v.entidade.$touch()"
                                    v-model="entidade"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid"
             @click="save">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ParticipanteService from '../participantes-service';

export default {
  props: {
    grupoId: [Number, String],
  },
  data() {
    return {
      dialog: false,
      loading: false,
      entidade: null,
    };
  },
  methods: {
    buildSaveState() {
      return {
        entidade: {
          id: this.entidade.id,
        },
        grupoCompra: {
          id: this.grupoId,
        },
      };
    },
    save() {
      this.loading = true;
      ParticipanteService.create(this.buildSaveState())
        .then(() => {
          this.$toast('Participante adicionado', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
      this.entidade = null;
      this.$v.$reset();
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    entidade: {
      required,
    },
  },
};
</script>
