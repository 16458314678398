<template>
  <div>
    <px-view-header title="Detalhes da compra" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link :to="`/${grupoId}/compras`">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && compra">
      <px-compra-detalhes-dados-basicos :compra="compra" @movimentar="onMovimentar"/>
      <px-compra-detalhes-comprador :compra="compra"/>
      <px-compra-detalhes-produtos :compra="compra"/>
      <px-compra-mensagem class="mt-3" :compra="compra" v-if="compra.fornecedor.hasUsers"/>
    </div>
  </div>
</template>

<script>
import CompraDetalhesDadosBasicos from './CompraDetalhesDadosBasicos.vue';
import CompraDetalhesProdutos from './CompraDetalhesProdutos.vue';
import CompraDetalhesComprador from './CompraDetalhesComprador.vue';
import CompraMensagem from './CompraMensagem.vue';
import CompraService from './compra-service';

export default {
  data() {
    return {
      compra: null,
      loading: false,
      grupoId: this.$route.params.idGrupo,
      compraId: this.$route.params.id,
      breadcrumbs: [
        { text: 'Negócios' },
        {
          text: 'Compras',
          to: '/compras',
        },
        { text: 'Detalhes' },
      ],
    };
  },
  methods: {
    getCompra() {
      this.loading = true;
      CompraService.getCompraCompleto(this.compraId)
        .then((response) => {
          this.compra = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onMovimentar() {
      this.getCompra();
    },
  },
  created() {
    this.getCompra();
  },
  components: {
    pxCompraDetalhesDadosBasicos: CompraDetalhesDadosBasicos,
    pxCompraDetalhesProdutos: CompraDetalhesProdutos,
    pxCompraDetalhesComprador: CompraDetalhesComprador,
    pxCompraMensagem: CompraMensagem,
  },
};
</script>
