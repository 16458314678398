import Router from '../../router';

const GRUPO_COMPRA_HEADER = 'GRUPRO-COMPRA';

const grupoCompraInterceptor = (config) => {
  if (Router.currentRoute.name && !Router.currentRoute.name.includes('main')) {
    return config;
  }

  const { idGrupo } = Router.currentRoute.params;

  if (!config.headers[GRUPO_COMPRA_HEADER]) {
    // eslint-disable-next-line no-param-reassign
    config.headers[GRUPO_COMPRA_HEADER] = idGrupo;
  }

  return config;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  grupoCompraInterceptor,
};
