<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            icon
            v-on="{ ...onTooltip, ...on }"
          >
            <v-icon>
              mdi-file-document-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Especificação</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto"
            min-width="300"
            max-width="500">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <p class="mb-1 font-weight-bold caption orange--text">Solicitado:</p>
            <p class="large-text mb-1">{{ especificacao | naoInformado }}</p>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="mb-1 font-weight-bold caption green--text">Fornecido:</p>
            <p class="large-text mb-1">{{ especificacaoFornecida | naoInformado }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    especificacao: String,
    especificacaoFornecida: String,
  },
  data() {
    return {
      opened: false,
    };
  },
};
</script>
