<template>
  <v-container class="mb-4" fluid>
    <v-card class="mb-4">
      <div class="px-4 pt-4">
        <v-row dense>
          <v-col cols="12" sm="10">
            <px-autocomplete-comprador
              required
              class="required"
              :error="$v.produto.comprador.$error"
              @blur="$v.produto.comprador.$touch()"
              v-model="produto.comprador"/>
          </v-col>
          <v-col cols="12" sm="2">
            <px-input-money
              ref="precoPraticado"
              label="Preço praticado"
              v-model="produto.precoPraticado"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <px-autocomplete-produto v-model="produto.produto"
                                     class="required"
                                     show-details
                                     only-padrao
                                     base-path="/produtos/page"
                                     :error="$v.produto.produto.$error"
                                     @blur="$v.produto.produto.$touch()"
                                     required/>
          </v-col>
          <v-col cols="12" sm="2">
            <px-input-number label="Quantidade"
                             required
                             class="required"
                             :error="$v.produto.quantidade.$error"
                             @blur="$v.produto.quantidade.$touch()"
                             v-model="produto.quantidade"/>
          </v-col>
          <v-col cols="12" sm="3">
            <px-autocomplete-unidade-medida required
                                            class="required"
                                            v-model="produto.unidadeMedida"
                                            :error="$v.produto.unidadeMedida.$error"
                                            @blur="$v.produto.unidadeMedida.$touch()"
            />
          </v-col>
          <v-col align-self="center" align="center" cols="12" sm="1">
            <px-anexo v-model="produto.arquivos" :max="3" @loading="onFileLoad"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              outlined
              auto-grow
              v-model="produto.especificacaoProduto"
              label="Especificação"
              counter="1500"
              rows="1"
            />
          </v-col>
        </v-row>
      </div>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary"
               :disabled="$v.$invalid || hasFileLoading"
               @click="addProduto">
          <v-icon>mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
    <px-grupo-compra-cadastro-cotacao-produtos-list :produtos="cotacao.produtos"
                                                    :editing="editing"
                                                    @edit="onEdit"
                                                    @remove="onRemove"/>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { get } from 'lodash';
import GrupoCompraCadastroCotacaoProdutosList from './GrupoCompraCadastroCotacaoProdutosList.vue';

const initalState = comprador => ({
  comprador,
  produto: null,
  quantidade: null,
  precoPraticado: 0,
  unidadeMedida: {
    id: null,
    descricao: null,
  },
  arquivos: [],
  especificacaoProduto: null,
});

export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
    compradorDefault: Object,
  },
  data() {
    return {
      produto: initalState(this.compradorDefault),
      editing: false,
      hasFileLoading: false,
    };
  },
  methods: {
    addProduto() {
      this.produto.arquivos = this.produto.arquivos.filter(produto => produto.id);
      this.cotacao.produtos.push(this.produto);
      this.produto = initalState(this.compradorDefault);
      this.$refs.precoPraticado.$el.getElementsByTagName('input')[0].value = 0;
      this.$v.$reset();
      this.editing = false;
    },
    onRemove(index) {
      this.cotacao.produtos.splice(index, 1);
      this.$emit('resetfornecedor');
    },
    onEdit(index) {
      this.produto = { ...this.cotacao.produtos[index] };
      if (this.produto.precoPraticado) {
        this.$refs.precoPraticado.$el.getElementsByTagName('input')[0].value = this.produto.precoPraticado.toFixed(2);
      }
      this.cotacao.produtos.splice(index, 1);
      this.editing = true;
      this.$emit('resetfornecedor');
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    setEspecificacao(newValue, oldValue) {
      if (!oldValue && this.produto.especificacaoProduto) {
        return;
      }
      this.produto.especificacaoProduto = this.produto.produto
        ? this.produto.produto.especificacao : null;
    },
  },
  watch: {
    'produto.produto': function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.setEspecificacao(newValue, oldValue);
      if (!oldValue && get(this.produto.unidadeMedida, 'id')) {
        return;
      }
      this.produto.unidadeMedida = newValue ? { ...newValue.unidadeMedida } : null;
    },
  },
  validations: {
    produto: {
      comprador: {
        required,
      },
      produto: {
        required,
      },
      quantidade: {
        required,
      },
      unidadeMedida: {
        required,
      },
    },
  },
  created() {
    this.produto.comprador = this.compradorDefault;
  },
  components: {
    pxGrupoCompraCadastroCotacaoProdutosList: GrupoCompraCadastroCotacaoProdutosList,
  },
};
</script>
