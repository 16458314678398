<template>
  <div id="avaliacoes">
    <v-row>
      <v-col cols="12" class="headline mt-3">
        <v-icon color="yellow darken-1">
          mdi-star
        </v-icon>
        {{ fornecedor.pessoa.entidade.pontuacaoMedia | pxSimpleNumber(1) }}
        ({{ fornecedor.pessoa.entidade.quantidadeComentarios }} comentários)
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" v-for="avaliacao in avaliacoesSub" :key="avaliacao.id">
          <px-avaliacao-item :pontuacao="avaliacao.avaliacao.pontuacao"
                             :comentario="avaliacao.avaliacao.comentario"/>
      </v-col>
    </v-row>

    <div v-if="!loading && !avaliacoes.content.length"
         class="body-1 mb-5 ml-2">
      Ainda não há nenhum comentário para a empresa
    </div>
    <v-row v-if="avaliacoes.content.length > 6">
      <v-col cols="12">
        <px-pagina-fornecedor-avaliacao-todas :avaliacoes="avaliacoes.content">
          <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on">
              Mostrar todos
            </v-btn>
          </template>
        </px-pagina-fornecedor-avaliacao-todas>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AvaliacaoService from '@/api/avaliacao-service';
import PaginaFornecedorAvaliacaoTodas from './PaginaFornecedorAvaliacaoTodas.vue';

export default {
  props: {
    fornecedor: Object,
  },
  data() {
    return {
      loading: false,
      avaliacoes: {
        content: [],
        total: 0,
      },
    };
  },
  computed: {
    avaliacoesSub() {
      return this.avaliacoes.content.slice(0, 6);
    },
  },
  methods: {
    getAvaliacoes() {
      this.loading = true;
      AvaliacaoService.getAvaliacoes({
        query: `company=${this.fornecedor.id} and possuiComentario=true`,
        limit: 100,
        offset: 0,
      })
        .then(({ data }) => {
          this.avaliacoes = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAvaliacoes();
  },
  components: {
    pxPaginaFornecedorAvaliacaoTodas: PaginaFornecedorAvaliacaoTodas,
  },
};
</script>
