import axios from 'axios';

const getFornecedoresPage = params => axios.get('/fornecedor/all/comprador', {
  params,
});

const getFornecedoresCotacao = (path, params) => axios.get(path, { params });
const getFornecedoresScore = params => axios.get('fornecedor/score', { params });
const getFornecedorScore = id => axios.get(`fornecedor/score/${id}`);
const getFornecedorDetalhes = id => axios.get(`fornecedor/${id}/detalhes`);
const get = () => axios.get('/fornecedor');

export default {
  get,
  getFornecedoresPage,
  getFornecedoresCotacao,
  getFornecedoresScore,
  getFornecedorScore,
  getFornecedorDetalhes,
};
