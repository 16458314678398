<template>
  <div class="mt-5 body-1">
    Pedido mínimo: {{ oferta.quantidade }} {{ oferta.unidadeMedida.descricao }}
  </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
};
</script>
