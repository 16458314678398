<template>
  <div>
    <v-row dense class="mx-1">
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um fornecedor ou produto" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="onChangeSearchText"/>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
    onChangeFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        {
          id: 'TODAS',
          description: 'Todas',
          value: null,
        },
        {
          id: 'ABERTAS',
          description: 'Abertas',
          value: ['ABERTA'],
        },
        {
          id: 'CANCELADAS',
          description: 'Canceladas',
          value: ['CANCELADA'],
        },
        {
          id: 'FINALIZADAS',
          description: 'Finalizadas',
          value: ['FINALIZADA'],
        },
      ],
    };
  },
  computed: {
    selectedStatus: {
      get() {
        return this.$store.getters['listagemCompra/selectedStatus'];
      },
      set(selectedStatus) {
        this.$store.dispatch('listagemCompra/selectedStatus', selectedStatus);
      },
    },
    searchText: {
      get() {
        return this.$store.getters['listagemCompra/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemCompra/searchText', searchText);
      },
    },
  },
  methods: {
    onChangeSearchText() {
      this.onChangeFilter();
    },
    onSelectItem() {
      this.onChangeFilter();
    },
  },
  created() {
    [this.selectedStatus] = this.statusOptions;
  },
};
</script>
