<template>
  <v-tooltip v-if="icon" top>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-icon color="success" :small="isProduto">
          {{ icon }}
        </v-icon>
      </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    resposta: Object,
    isProduto: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      if (this.resposta.finalizado) {
        return 'mdi-check-circle';
      }
      if (this.resposta.compra) {
        return 'mdi-progress-check';
      }
      return null;
    },
    text() {
      if (this.resposta.finalizado) {
        if (this.isProduto) {
          return 'Este produto foi comprado';
        }
        return 'Esta resposta possui produtos comprados';
      }

      if (this.resposta.compra) {
        if (this.isProduto) {
          return 'Este produto está em processo de compra';
        }
        return 'Esta resposta possui produtos em processo de compra';
      }
      return null;
    },
  },
};
</script>
