<template>
  <div>
    <v-row dense class="mx-1">
      <v-btn color="primary" link :to="`/${grupoId}/cadastro`">
        <v-icon left>mdi-plus</v-icon>
        Cotação
      </v-btn>
      <v-spacer/>
      <v-btn icon @click="emitChange">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um produto ou identificador" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="emitChange"/>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  computed: {
    searchText: {
      get() {
        return this.$store.getters['oportunidadesFiltro/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('oportunidadesFiltro/searchText', searchText);
      },
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
};
</script>
