<template>
  <div>
    <div class="headline">
      {{ empresaCarrinho.comprador.pessoa.nome }}
    </div>
    <v-divider class="mt-5 mb-5"/>
    <v-expansion-panels multiple class="mb-10">
      <v-expansion-panel
        v-for="catalogo in empresaCarrinho.catalogos"
        :key="catalogo.catalogo.id"
      >
        <v-expansion-panel-header>
          <span class="body-1">
            {{ catalogo.catalogo.fornecedor.pessoa.nome }}
            <px-popover-fornecedor :fornecedor="catalogo.catalogo.fornecedor" show-score/>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <px-carrinho-empresa-catalogo :catalogo="catalogo"
                                        :comprador="empresaCarrinho.comprador"
                                        @change="onChange"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CarrinhoEmpresaCatalogo from './CarrinhoEmpresaCatalogo.vue';

export default {
  props: {
    empresaCarrinho: Object,
  },
  methods: {
    onChange() {
      this.$emit('change');
    },
  },
  components: {
    pxCarrinhoEmpresaCatalogo: CarrinhoEmpresaCatalogo,
  },
};
</script>
