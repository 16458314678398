<template>
  <v-container fluid>
    <v-container fluid class="app-container">
      <template>
        <div>
          <px-view-header title="Grupos de compra" :breadcrumbs="breadcrumbs"/>
          <div v-if="loading">
            <px-circular-loading/>
          </div>
          <div class="mt-10">
            <v-card
              tile
            >
              <v-list-item v-for="grupo in grupos" :key="grupo.id">
                <v-list-item-content>
                  <v-list-item-title>{{ grupo.nome }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn color="primary" link :to="`${grupo.id}/indicadores-catalogo`">
                    Acessar
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </div>
        </div>

      </template>
    </v-container>
  </v-container>
</template>

<script>
import GrupoCompraService from './grupo-compra-service';

export default {
  data() {
    return {
      loading: false,
      grupos: [],
      breadcrumbs: [
        { text: 'Grupos de compra' },
      ],
    };
  },
  methods: {
    getGrupos() {
      this.loading = true;
      GrupoCompraService.getList()
        .then(({ data }) => {
          this.grupos = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getGrupos();
  },
};
</script>
