import axios from 'axios';

const addProduto = (idGrupo, data) => axios.post(`/grupo-compras/${idGrupo}/carrinho`, data);

const getOfertasFlat = idGrupo => axios.get(`/grupo-compras/${idGrupo}/carrinho/ofertas`);

const getCarrinho = idGrupo => axios.get(`/grupo-compras/${idGrupo}/carrinho`);

const removeOferta = (idGrupo, idCarrinho) => axios.delete(`/grupo-compras/${idGrupo}/carrinho/ofertas/${idCarrinho}`);

const gerarCompra = (idGrupo, idCarrinho, data) => axios.post(`/grupo-compras/${idGrupo}/carrinho/${idCarrinho}/finalizar`, data);

const updateOferta = (idGrupo, idOferta, data) => axios.put(`/grupo-compras/${idGrupo}/carrinho/ofertas/${idOferta}`, data);

export default {
  addProduto,
  getOfertasFlat,
  removeOferta,
  getCarrinho,
  gerarCompra,
  updateOferta,
};
