<template>
  <div class="mt-5 body-1">
    <v-skeleton-loader
      :loading="loading"
      type="list-item-two-line"
    >
      <div>
        <template v-if="frete">
          <span v-if="isFob">
            <v-icon>
              mdi-truck-outline
            </v-icon>
            Frete FOB
          </span>
            <span class="green--text" v-else>
            <v-icon color="success">mdi-truck-outline</v-icon>
            <template v-if="frete.valorTaxaEntrega">
              {{ frete.valorTaxaEntrega | currency }} de frete
            </template>
            <template v-else>
              Frete Grátis
            </template>
          </span>
          <div v-if="frete.ofertaFreteGratis" class="green--text">
            <small>
              Esta empresa oferece frete grátis a partir de
              {{ frete.taxaMinimaEntrega | currency }}
            </small>
          </div>
          <div>
            <small>
              {{ prazoPrefix }} {{ frete.prazoEmbarque | pluralize(['dia útil', 'dias úteis']) }}
            </small>
          </div>
        </template>
      </div>
    </v-skeleton-loader>

  </div>
</template>

<script>
import { TipoFrete } from 'px-business-components';
import CatalogoService from '../catalogo-service';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
    comprador: Object,
  },
  data() {
    return {
      loading: false,
      frete: null,
    };
  },
  computed: {
    isFob() {
      return this.frete && this.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    prazoPrefix() {
      if (this.isFob) {
        return 'Embarque em';
      }
      return 'Entrega em';
    },
  },
  methods: {
    getFreteCep(cep) {
      this.loading = true;
      CatalogoService.getFreteCep(this.oferta.catalogo.id, cep)
        .then(({ data }) => {
          this.frete = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    comprador(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.getFreteCep(this.comprador.endereco.cep);
    },
  },
  created() {
    this.getFreteCep(this.comprador.endereco.cep);
  },
};
</script>
