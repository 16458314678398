<template>
  <px-custom-dialog transition="fadet"
                    width="600"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Adicionando produto
    </template>
    <template>
      <v-row dense>
        <v-col cols="12" sm="9">
          <px-autocomplete-comprador
            required
            class="required"
            :error="$v.itemCotacao.comprador.$error"
            @blur="$v.itemCotacao.comprador.$touch()"
            v-model="itemCotacao.comprador"/>
        </v-col>
        <v-col cols="12" sm="3">
          <px-input-money
            ref="precoPraticado"
            label="Preço praticado"
            v-model="itemCotacao.precoPraticado"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="10">
          <px-autocomplete-produto v-model="itemCotacao.produto"
                                   :error="$v.itemCotacao.produto.$error"
                                   @blur="$v.itemCotacao.produto.$touch()"
                                   @change="changeProduto"
                                   class="required"
                                   show-details
                                   only-padrao
                                   base-path="/produtos/page"
                                   required/>
        </v-col>
        <v-col align-self="center" align="center" cols="12" sm="2">
          <px-anexo v-model="itemCotacao.arquivos" :max="3" @loading="onFileLoad"/>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <px-input-number label="Quantidade"
                           required
                           class="required"
                           :error="$v.itemCotacao.quantidade.$error"
                           @blur="$v.itemCotacao.quantidade.$touch()"
                           v-model="itemCotacao.quantidade"/>
        </v-col>
        <v-col cols="12" sm="6">
          <px-autocomplete-unidade-medida required
                                          class="required"
                                          :error="$v.itemCotacao.unidadeMedida.$error"
                                          @blur="$v.itemCotacao.unidadeMedida.$touch()"
                                          v-model="itemCotacao.unidadeMedida"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            v-model="itemCotacao.especificacaoProduto"
            label="Especificação"
            counter="1500"
            rows="2"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid || hasFileLoading"
             @click="save">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import CotacaoGrupoService from '../cotacoes-grupo-service';

const initalState = comprador => ({
  comprador,
  produto: null,
  quantidade: null,
  precoPraticado: 0,
  unidadeMedida: {
    id: null,
    descricao: null,
  },
  arquivos: [],
  especificacaoProduto: null,
});

export default {
  props: {
    cotacao: Object,
    compradorDefault: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      hasFileLoading: false,
      loading: false,
      itemCotacao: initalState(this.compradorDefault),
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    buildSaveState() {
      return {
        produto: this.itemCotacao.produto,
        arquivos: this.itemCotacao.arquivos,
        especificacaoProduto: this.itemCotacao.especificacaoProduto,
        unidadeMedida: this.itemCotacao.unidadeMedida,
        quantidade: this.itemCotacao.quantidade,
        participantes: [{
          comprador: this.itemCotacao.comprador,
          precoPraticado: this.itemCotacao.precoPraticado,
          quantidade: this.itemCotacao.quantidade,
        }],
      };
    },
    save() {
      this.loading = true;
      const payload = this.buildSaveState();
      CotacaoGrupoService.inserirProduto(this.cotacao.id, payload)
        .then(() => {
          this.close();
          this.$emit('update');
          this.$toast('Produto adicionado com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeProduto() {
      if (this.itemCotacao.produto) {
        this.itemCotacao.unidadeMedida = this.itemCotacao.produto.unidadeMedida;
      }
    },
    buildInitialState() {
      this.itemCotacao = initalState(this.compradorDefault);
      if (this.$refs.precoPraticado) {
        this.$refs.precoPraticado.$el.getElementsByTagName('input')[0].value = 0;
      }
      this.$v.$reset();
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    itemCotacao: {
      comprador: {
        required,
      },
      produto: {
        required,
      },
      quantidade: {
        minValue(value) {
          return value > 0;
        },
      },
      unidadeMedida: {
        id: {
          required,
        },
      },
    },
  },
};
</script>
