<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row dense class="text--primary mb-2">
          <v-col cols="10">
            <px-situacao-cotacao-icon :situacao="cotacao.situacao"/>
            {{ cotacao.identificador }}
          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn color="primary"
                   :loading="loadingEnviar"
                   v-if="isPendente && isUserAdmin"
                   @click="enviarCotacao">
              Enviar
            </v-btn>
            <px-grupo-compra-cancelar-cotacao v-if="isUserAdmin"
                                              :cotacao="cotacao"
                                              @update="emitUpdate"/>
            <px-refresh @refresh="emitUpdate"/>
          </v-col>
        </v-row>
        <div class="text--primary">
          <v-row dense>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Prazo para entrada</span>
              <div>
                {{ cotacao.datafechamento | date(true) }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Prazo de entrega</span>
              <div>
                {{ cotacao.prazoEntrega | date | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Válido até</span>
              <div>
                {{ cotacao.validade | date | naoInformado }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Responsável</span>
              <px-grupo-compra-editar-admins v-if="isUserAdmin"
                                             :cotacao="cotacao"
                                             :grupo-id="grupoId"
                                             @update="emitUpdate">
                <template v-slot:activator="{ on }">
                  <v-btn class="ml-1" color="primary" x-small text v-on="on">
                    Alterar
                  </v-btn>
                </template>
              </px-grupo-compra-editar-admins>
              <div>
                <px-admins-text :administradores="cotacao.administradores" />
              </div>
            </v-col>
            <v-col cols="12" sm="8">
              <span class="caption font-weight-bold">Observação</span>
              <div>
                {{ cotacao.observacao | naoInformado }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <px-grupo-compra-cotacao-visualizacao-pendente-tabs class="mt-3"
                                                        :cotacao="cotacao"
                                                        :grupo-compra="grupoCompra"
                                                        :is-user-admin="isUserAdmin"
                                                        @update="emitUpdate"/>
  </div>
</template>

<script>
import { SituacaoCotacao } from 'px-business-components';
import GrupoCompraCotacaoVisualizacaoPendenteTabs
  from './GrupoCompraCotacaoVisualizacaoPendenteTabs.vue';
import GrupoCompraEditarAdmins from '../editar-admins/GrupoCompraEditarAdmins.vue';
import GrupoCompraCancelarCotacao from '../cancelar-cotacao/GrupoCompraCancelarCotacao.vue';
import CotacaoGrupoService from '../cotacoes-grupo-service';
import PxAdminsText from './PxAdminsText.vue';

export default {
  props: {
    cotacao: Object,
    grupoCompra: Object,
    isUserAdmin: Boolean,
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loadingEnviar: false,
    };
  },
  computed: {
    isPendente() {
      return SituacaoCotacao.keys.PENDENTE_ENVIO.key === this.cotacao.situacao;
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    enviarCotacao() {
      this.loadingEnviar = true;
      CotacaoGrupoService.enviarCotacao(this.cotacao.id)
        .then(() => {
          this.$toast('Solicitação enviada aos fornecedores', { color: 'success' });
          this.emitUpdate();
        })
        .finally(() => {
          this.loadingEnviar = false;
        });
    },
  },
  components: {
    pxGrupoCompraCotacaoVisualizacaoPendenteTabs: GrupoCompraCotacaoVisualizacaoPendenteTabs,
    pxGrupoCompraEditarAdmins: GrupoCompraEditarAdmins,
    pxGrupoCompraCancelarCotacao: GrupoCompraCancelarCotacao,
    PxAdminsText,
  },
};
</script>
