<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <v-simple-table v-if="!loading && empresas.length">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Nome</th>
          <th class="text-left">CNPJ</th>
          <th class="text-left">Endereço</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="empresa in empresas" :key="empresa.is">
          <td>{{ empresa.pessoa.nome }}</td>
          <td>{{ empresa.pessoa.cnpj | cnpj }}</td>
          <td>{{ empresa.endereco | endereco }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import CompradorService from '@/api/comprador-service';

export default {
  data() {
    return {
      loading: false,
      empresas: [],
    };
  },
  created() {
    this.loading = true;
    CompradorService.getComprador()
      .then((response) => {
        this.empresas = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
