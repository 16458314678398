<template>
  <div>
    <px-view-header title="Visualizar cotação" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>

    <div v-if="!loading && cotacao">
      <component v-bind:is="currentComponent"
                 :cotacao="cotacao"
                 :grupo-compra="grupoCompra"
                 :is-user-admin="isAdmin"
                 @update="onUpdate"></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SituacaoCotacao } from 'px-business-components';
import GrupoCompraCotacaoVisualizacaoPendente from './GrupoCompraCotacaoVisualizacaoPendente.vue';
import GrupoCompraCotacaoVisualizacaoAberta from './analise/GrupoCompraCotacaoVisualizacaoAberta.vue';
import GrupoCompraCotacaoVisualizacaoFechamento
  from './analise/fechamento/GrupoCompraCotacaoVisualizacaoFechamento.vue';
import CotacaoGrupoService from '../cotacoes-grupo-service';

const PENDENTES = [SituacaoCotacao.keys.PENDENTE_ENVIO.key, SituacaoCotacao.keys.ELABORACAO.key];

export default {
  data() {
    return {
      origin: this.$route.query.origin,
      cotacao: null,
      loading: true,
      idCotacao: this.$route.params.idCotacao,
      breadcrumbs: [
        { text: 'Compra conjunta' },
        { text: 'Grupos de compra' },
      ],
    };
  },
  computed: {
    ...mapGetters(['grupoCompra', 'isGrupoAdmin']),
    ...mapGetters('user', ['user']),
    isOriginOportunidades() {
      return this.origin === 'oportunidades';
    },
    isAdmin() {
      if (!this.cotacao) {
        return false;
      }
      return this.cotacao.administradores.map(a => a.id)
        .includes(this.user.id) || this.isGrupoAdmin;
    },
    isPendente() {
      return PENDENTES.includes(this.cotacao.situacao);
    },
    isCompra() {
      return SituacaoCotacao.keys.COMPRA.key === this.cotacao.situacao;
    },
    isFinalizada() {
      return SituacaoCotacao.keys.FINALIZADA.key === this.cotacao.situacao;
    },
    currentComponent() {
      if (this.isPendente) {
        return 'px-grupo-compra-cotacao-visualizacao-pendente';
      }
      if (this.isCompra || this.isFinalizada) {
        return 'px-grupo-compra-cotacao-visualizacao-fechamento';
      }
      return 'px-grupo-compra-cotacao-visualizacao-aberta';
    },
  },
  methods: {
    onUpdate() {
      this.getCotacao();
    },
    getCotacao() {
      this.loading = true;
      CotacaoGrupoService.get(this.idCotacao)
        .then(({ data }) => {
          this.cotacao = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickBack() {
      if (this.isOriginOportunidades) {
        this.$router.push({
          name: 'main.oportunidades',
        });
        return;
      }
      this.$router.push({
        name: 'main.cotacoes',
      });
    },
  },
  created() {
    this.getCotacao();
  },
  components: {
    pxGrupoCompraCotacaoVisualizacaoPendente: GrupoCompraCotacaoVisualizacaoPendente,
    pxGrupoCompraCotacaoVisualizacaoAberta: GrupoCompraCotacaoVisualizacaoAberta,
    pxGrupoCompraCotacaoVisualizacaoFechamento: GrupoCompraCotacaoVisualizacaoFechamento,
  },
};
</script>
