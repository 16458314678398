<template>
  <div>
    <div class="mt-5" v-if="loading">
      <px-circular-loading/>
    </div>
    <template v-if="!loading && produtos">
      <px-grupo-compra-resposta-produtos :produtos="produtos"
                                         :show-especificacao-line="showEspecificacaoLine"
                                         :show-especificacao="showEspecificacao"/>
    </template>
  </div>
</template>

<script>
import { comparePrazos } from '@/utils/prazo-utils';
import CotacaoConjuntaRespostas from '../../cotacao-conjunta-resposta';
import GrupoCompraRespostaProdutos from './GrupoCompraRespostaProdutos.vue';

export default {
  props: {
    resposta: {
      type: Object,
      required: true,
    },
    showEspecificacao: {
      type: Boolean,
      default: false,
    },
    showEspecificacaoLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      produtos: null,
    };
  },

  methods: {

    sortPrazos(item) {
      return {
        ...item,
        $active: false,
        prazos: item.prazos.sort(comparePrazos),
      };
    },
    getProdutos() {
      this.loading = true;
      CotacaoConjuntaRespostas.getRespostasProdutos(this.resposta.cotacaoConjunta.id, {
        query: `respostaCotacao.id=${this.resposta.id} order by id`,
      })
        .then((response) => {
          this.produtos = response.data.map(this.sortPrazos);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getProdutos();
  },
  components: {
    pxGrupoCompraRespostaProdutos: GrupoCompraRespostaProdutos,
  },
};
</script>
