import VueCookies from 'vue-cookies';
import { COOKIES_NAME } from './tokens.constants';

export default {
  redirect(state) {
    return state.redirect;
  },
  tokens(state) {
    return state.tokens;
  },
  token(state) {
    if (!state.tokens) {
      return null;
    }
    return state.tokens.token;
  },
  tokenHeader(state, getters) {
    const { token } = getters;
    if (!token) {
      return null;
    }
    return `Bearer ${token}`;
  },
  refreshTokenHeader(state, getters) {
    const { refreshToken } = getters;
    if (!refreshToken) {
      return null;
    }
    return `Bearer ${refreshToken}`;
  },
  refreshToken(state) {
    if (!state.tokens) {
      return null;
    }
    return state.tokens.refreshToken;
  },
  isAuthenticated(state) {
    return state.tokens && state.tokens.token;
  },
  tokenFromStorage() {
    const tokens = VueCookies.get(COOKIES_NAME);
    if (!tokens) {
      return null;
    }
    return tokens.token;
  },
};
