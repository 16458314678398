<template>
  <v-card>
    <v-card-text>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">Negociações</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="text--primary">
        <px-acompanhamentos allow-files
                            :disabled="!isCotacaoAberta || !isAdmin"
                            :item-type="messageType"
                            :item-id="resposta.id"
                            :user="user"
                            @on-message="onMessage"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { SituacaoCotacao } from 'px-business-components';
import { mapGetters } from 'vuex';
import CotacaoConjuntaResposta from '../../../cotacao-conjunta-resposta';

export default {
  props: {
    resposta: Object,
    isAdmin: Boolean,
  },
  data() {
    return {
      messageType: this.$px.types.NEGOCIACAO_COTACAO_CONJUNTA,
    };
  },
  computed: {
    isCotacaoAberta() {
      return this.resposta.cotacaoConjunta.situacao === SituacaoCotacao.keys.ABERTA.key;
    },
    ...mapGetters('user', ['user']),
  },
  methods: {
    onMessage() {
      CotacaoConjuntaResposta.notificarNegociacao(this.resposta.id);
    },
  },
};
</script>
