<template>
  <div>
    <v-data-table
            :headers="headers"
            :items="compras.content"
            show-expand
            :expanded.sync="expanded"
            class="elevation-1 mt-5"
            :loading="loading"
            :server-items-length="compras.total"
            @update:options="updateOptions"
            :footer-props="footerOptions"
            no-data-text="Nenhuma compra encontrada"
            loading-text="Carregando compras..."
            :options="optionsList"
    >
      <template v-slot:item.fornecedor.nome="{ item }">
        <px-situacao-compra-icon :situacao="item.situacao"/>
        {{ item.fornecedor.pessoa.nome }}
      </template>
      <template v-slot:item.comprador="{ item }">
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.comprador.pessoa.nome"/>
      </template>
      <template v-slot:item.dataCompra="{ item }">
        {{item.dataCompra | date}}
      </template>
      <template v-slot:item.quantidadeProdutos="{ item }">
        {{item.quantidadeProdutos}} produto(s)
      </template>
      <template v-slot:item.saving="{ item }">
        {{item.saving | currency}}
      </template>
      <template v-slot:item.total="{ item }">
        {{item.total | currency}}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon @click="goToCompra(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <px-avaliacao-dialog v-if="!item.avaliacao"
                             :compra-id="item.id"
                             :disabled="isOrigemCadastro(item) || !isFinalizada(item)"
                             :name="item.fornecedor.pessoa.nome"
                             @update="emitUpdate"/>
        <px-show-avaliacao-menu v-if="item.avaliacao"
                                :avaliacao="item.avaliacao.avaliacao"/>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <listagem-compra-detail :compra="item"/>
        </td>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { SituacaoCompra } from 'px-business-components';
import ListagemCompraDetail from './ListagemCompraDetail.vue';

export default {
  components: { ListagemCompraDetail },
  props: {
    compras: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      expanded: [],
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Fornecedor',
          value: 'fornecedor.nome',
        },
        {
          text: 'Empresa',
          value: 'comprador',
        },
        {
          text: 'Data da compra',
          value: 'dataCompra',
        },
        {
          text: 'Saving',
          value: 'saving',
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
    };
  },
  computed: {
    isOrigemCadastro() {
      return item => item.processoAprovacao !== true;
    },
    comprador() {
      return item => Vue.filter('truncate')(item.comprador.pessoa.nome, 20);
    },
    isFinalizada() {
      return item => SituacaoCompra.keys.APROVADA.key === item.situacao;
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    goToCompra(item) {
      this.$router.push({
        name: 'main.compras.detalhes',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
