<template>
  <v-form>
    <v-row>
      <v-col class="d-flex" cols="12" sm="4">
        <px-date-picker input-class="required"
                        label="Data de fechamento"
                        :error="$v.cotacao.dataFechamento.$error"
                        @blur="$v.cotacao.dataFechamento.$touch()"
                        v-model="cotacao.dataFechamento"/>
      </v-col>
      <v-col cols="4">
        <px-time-picker input-class="required"
                        label="Hora de fechamento"
                        :error="$v.cotacao.horaFechamento.$error"
                        @blur="$v.cotacao.horaFechamento.$touch()"
                        v-model="cotacao.horaFechamento" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="12" sm="6">
        <px-date-picker class="requied" v-model="cotacao.prazoEntrega" label="Prazo de entrega"/>
      </v-col>
      <v-col class="d-flex" cols="12" sm="6">
        <px-date-picker  v-model="cotacao.validade" label="Prazo de resposta"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          outlined
          rows="2"
          v-model="cotacao.observacao"
          label="Observações"
          counter="1500"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    cotacao: Object,
  },
  data() {
    return {};
  },
  methods: {},
  components: {},
  validations: {
    cotacao: {
      dataFechamento: {
        required,
      },
      horaFechamento: {
        required,
      },
    },
  },
};
</script>
