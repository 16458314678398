<template>
  <div>
    <px-view-header title="Grupo de compras" :breadcrumbs="breadcrumbs"/>

    <v-card>
      <v-card-text>
        <p class="headline mb-1 text--primary">
          {{grupoCompra.nome}}
        </p>
        <p>
          <v-icon>mdi-domain</v-icon>
          {{grupoCompra.identificador}}
        </p>
        <div class="text--primary">
          <v-row v-if="grupoCompra.ocultarEmpresasComprador">
            <v-col cols="12">
              <v-icon color="success">mdi-check</v-icon>
              Oculta informações das empresas para os compradores
            </v-col>
          </v-row>
          <v-row v-if="grupoCompra.ocultarEmpresasFornecedor">
            <v-col cols="12">
              <v-icon color="success">mdi-check</v-icon>
              Oculta empresas para os fornecedores
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <px-grupo-tabs class="mt-3" :grupo="grupoCompra"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GrupoTabs from './GrupoTabs.vue';

export default {
  data() {
    return {
      breadcrumbs: [
        { text: 'Administrador' },
        { text: 'Grupo de compras' },
      ],
    };
  },
  computed: {
    ...mapGetters(['grupoCompra', 'isGrupoAdmin']),
  },
  created() {
    if (!this.isGrupoAdmin) {
      this.$router.push({
        name: 'main.meu-painel',
      });
    }
  },
  components: {
    pxGrupoTabs: GrupoTabs,
  },
};
</script>
