<template>
  <div>
    <px-grupo-compra-cotacao-analise-header @change-search-text="changeSearch"
                                            @change-sort="changeSort"
                                            @update="onUpdate"/>

    <div class="mb-3 text-right" v-if="cotacao.id">
      <px-cotacao-resposta-sem-estoque :cotacao-id="cotacao.id" :search-method="semEstoqueMethod"/>
    </div>

    <px-grupo-compra-cotacao-analise-view-fornecedor class="mt-3"
                                                     ref="viewFornecedor"
                                                     :cotacao="cotacao"
                                                     :search-text="searchText"
                                                     :sort="selectedSort"/>
    <px-grupo-compra-cotacao-analise-tabs class="mt-3"
                                          :cotacao="cotacao"
                                          :grupo-compra="grupoCompra"
                                          :is-user-admin="isUserAdmin"
                                          @update="emitUpdate"/>
  </div>
</template>

<script>
import GrupoCompraCotacaoAnaliseHeader from './GrupoCompraCotacaoAnaliseHeader.vue';
import GrupoCompraCotacaoAnaliseViewFornecedor from './GrupoCompraCotacaoAnaliseViewFornecedor.vue';
import GrupoCompraCotacaoAnaliseTabs from './GrupoCompraCotacaoAnaliseTabs.vue';
import CotacaoConjuntaRespostaService from '../../cotacao-conjunta-resposta';

export default {
  props: {
    cotacao: Object,
    grupoCompra: Object,
    isUserAdmin: Boolean,
  },
  data() {
    return {
      searchText: null,
      selectedSort: null,
      semEstoqueMethod: CotacaoConjuntaRespostaService.getSemEstoque,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    changeSearch(searchText) {
      this.searchText = searchText;
    },
    changeSort(sort) {
      this.selectedSort = sort;
    },
    onUpdate() {
      if (this.$refs.viewFornecedor) {
        this.$refs.viewFornecedor.getRespostas();
      }
    },
  },
  components: {
    pxGrupoCompraCotacaoAnaliseHeader: GrupoCompraCotacaoAnaliseHeader,
    pxGrupoCompraCotacaoAnaliseViewFornecedor: GrupoCompraCotacaoAnaliseViewFornecedor,
    pxGrupoCompraCotacaoAnaliseTabs: GrupoCompraCotacaoAnaliseTabs,
  },
};
</script>
