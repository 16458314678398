<template>
  <div>
    <px-view-header title="Fechamento" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <v-stepper v-if="!loading && resposta && totais" class="elevation-1"
               v-model="currentStep" vertical>
      <v-stepper-step :complete="currentStep > 1" step="1">
        Produtos e prazos
      </v-stepper-step>
      <v-stepper-content step="1">
        <px-grupo-compra-fechamento-cotacao-produtos :resposta="resposta"
                                                     :totais="totais"
                                                     @next="next"/>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">
        Dados básicos
      </v-stepper-step>
      <v-stepper-content step="2">
        <px-grupo-compra-fechamento-cotacao-dados-basicos v-if="currentStep===2"
                                                          :resposta="resposta"
                                                          :totais="totais"
                                                          :compra-conjunta="compraConjunta"
                                                          @update="onUpdate"
                                                          @previous="previous"/>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { comparePrazos } from '@/utils/prazo-utils';
import CotacaoConjuntaRespostaService from '@/modules/cotacoes/cotacao-conjunta-resposta';
import CotacaoConjuntaCompraService from '@/modules/cotacoes/cotacao-conjunta-compra';
import GrupoCompraFechamentoCotacaoProdutos from './GrupoCompraFechamentoCotacaoProdutos.vue';
import GrupoCompraFechamentoCotacaoDadosBasicos from './GrupoCompraFechamentoCotacaoDadosBasicos.vue';

export default {
  data() {
    return {
      idCotacao: this.$route.params.idCotacao,
      idResposta: this.$route.params.idResposta,
      idComprador: this.$route.params.idComprador,
      currentStep: 1,
      resposta: null,
      totais: null,
      compraConjunta: null,
      loading: false,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Resposta' },
      ],
    };
  },
  methods: {
    next() {
      this.currentStep = this.currentStep + 1;
    },
    previous() {
      this.currentStep = this.currentStep - 1;
    },
    getResposta() {
      this.loading = true;
      CotacaoConjuntaRespostaService.getResposta(this.idCotacao, this.idResposta)
        .then(({ data }) => {
          this.resposta = data;
          return Promise.all([this.getSimulacao(), this.getCompraConjunta()]);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSimulacao() {
      return CotacaoConjuntaRespostaService.getRespostaTotaisByComprador(
        this.resposta.cotacaoConjunta.id,
        this.resposta.id,
        this.idComprador,
      )
        .then(({ data }) => {
          this.totais = data;
          this.totais.produtos = this.totais.produtos.map(this.mapProdutos);
        });
    },
    getCompraConjunta() {
      return CotacaoConjuntaCompraService.getCompraConjunta(this.resposta.cotacaoConjunta.id)
        .then(({ data }) => {
          this.compraConjunta = data;
        });
    },
    mapProdutos(produto) {
      return {
        ...produto,
        prazos: produto.prazos.sort(comparePrazos),
      };
    },
    onUpdate() {
      this.onClickBack();
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getResposta();
  },
  components: {
    pxGrupoCompraFechamentoCotacaoProdutos: GrupoCompraFechamentoCotacaoProdutos,
    pxGrupoCompraFechamentoCotacaoDadosBasicos: GrupoCompraFechamentoCotacaoDadosBasicos,
  },
};
</script>
