<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="300"
    max-width="300"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon
                 small
                 color="blue"
                 v-on="{ ...onTooltip, ...on }"
                 @click.stop.prevent=""
          >
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </template>
        <span>Prazos disponíveis</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Prazos disponíveis</v-card-title>
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <div v-if="!loading && (!prazos || !prazos.length)">
          Não há prazos disponíves para o valor a ser comprado
        </div>
        <v-list dense v-if="!loading && prazos && prazos.length">
          <template v-for="(prazo, index) in prazos">
            <v-list-item :key="prazo.id">
              {{ prazo.descricao }} dias
            </v-list-item>
            <v-divider :key="`div-${prazo.id}`" v-if="index !== (prazos.length - 1)"/>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import PrazoPagamentoService from '@/api/prazo-pagamento-service';

export default {
  props: {
    catalogoId: [Number, String],
    valorAPrazo: Number,
  },
  data() {
    return {
      menuOpened: false,
      loading: false,
      prazos: null,
    };
  },
  methods: {
    getPrazos() {
      this.loading = true;
      PrazoPagamentoService.getPrazosCatalogoPage(this.catalogoId, {
        limit: 20,
        offset: 0,
        query: `valorMinimo <= ${this.valorAPrazo}`,
      })
        .then(({ data }) => {
          this.prazos = data.content;
          this.$emit('prazos', !!this.prazos.length);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getPrazos();
  },
};
</script>
