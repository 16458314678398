export default {
  user(state) {
    return state.user;
  },
  password(state) {
    return state.password;
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};
