<template>
  <v-card class="mt-3">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="7">
          <p class="mb-1">
              <span class="headline text--primary">
                {{ compra.comprador.pessoa.nome }}
              </span>
          </p>
        </v-col>
      </v-row>
      <p class="mt-2">
        <v-icon>mdi-domain</v-icon>
        {{ compra.comprador.pessoa.cnpj | cnpj }}
      </p>
      <p>
        <v-icon>mdi-map-marker</v-icon>
        {{ compra.comprador.endereco | endereco }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    compra: Object,
  },
};
</script>
