<template>
  <v-alert
    v-if="isFromCatalogo && isPendenteComprador"
    type="info"
    outlined
    dismissible>
      {{freteDescricao}}
  </v-alert>
</template>

<script>
import { OrigemCompra, SituacaoCompra, TipoFrete } from 'px-business-components';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      loadingCancelar: false,
    };
  },
  computed: {
    isFob() {
      return this.compra.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    isFromCatalogo() {
      return this.compra.origemCompra === OrigemCompra.keys.CATALOGO_COMPRA_CONJUNTA.key;
    },
    isPendenteComprador() {
      return this.compra.situacao === SituacaoCompra.keys.PENDENTE_FORNECEDOR.key;
    },
    freteDescricao() {
      if (this.isFob) {
        return 'Sua compra está pendente com o fornecedor. Você poderá retirar seus produtos assim que a compra for aprovada, respeitando o prazo de embarque definido.';
      }
      return 'Sua compra está pendente com o fornecedor. Os seus produtos serão enviados assim que a compra for aprovada, respeitando o prazo de entrega definido.';
    },
  },
};
</script>
