<template>
  <div>
    <v-row dense class="mx-1">
      <px-cadastro-fornecedor :id-grupo="idGrupo"
                              @change="emitChange"/>
      <v-spacer/>
      <v-btn icon @click="onClickRefresh">
        <v-icon left>mdi-refresh</v-icon>
      </v-btn>
      <px-search-text label="Busque por um fornecedor" v-model="searchText"
                      style="max-width: 400px"
                      :on-search="emitChange"/>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CadastroFornecedor from './CadastroFornecedor.vue';

export default {
  props: {
    onClickRefresh: {
      type: Function,
      required: true,
    },
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
  computed: {
    ...mapGetters(['idGrupo']),
    searchText: {
      get() {
        return this.$store.getters['listagemFornecedores/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('listagemFornecedores/searchText', searchText);
      },
    },
  },
  components: {
    pxCadastroFornecedor: CadastroFornecedor,
  },
};
</script>
