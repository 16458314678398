<template>
  <v-chip v-if="desconto > 0" label :small="small" color="success" :class="chipClassC">
    {{ desconto }}% OFF
  </v-chip>
</template>

<script>
import currency from 'currency.js';

export default {
  props: {
    oferta: Object,
    small: {
      type: Boolean,
      default: true,
    },
    chipClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    chipClassC() {
      return `ml-2 caption ${this.chipClass}`;
    },
    desconto() {
      const desconto = currency(100)
        .subtract(
          currency(this.oferta.precoAVista)
            .multiply(100)
            .divide(this.oferta.precoOriginal),
        )
        .value;
      if (desconto < 0) {
        return 0;
      }
      return Math.round(desconto);
    },
  },
};
</script>
