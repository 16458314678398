<template>
  <px-lateral-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn color="primary"
             v-on="on">
        Enviar ordem de compra
      </v-btn>
    </template>
    <template v-slot:title>
      Enviar ordem de compra
    </template>
    <template>
      <v-row>
        <v-col cols="11">
          <v-textarea
            outlined
            auto-grow
            class="required"
            label="Mensagem para o fornecedor"
            counter="1500"
            rows="2"
            :error="$v.mensagem.$error"
            @blur="$v.mensagem.$touch()"
            v-model="mensagem"
          />
        </v-col>
        <v-col cols="1">
          <px-anexo v-model="arquivos" :max="3" @loading="onFileLoad"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close">
        Cancelar
      </v-btn>
      <v-btn color="primary"
             :loading="loading"
             @click="enviar">
        Enviar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import CompraService from './compra-service';

export default {
  props: {
    compra: Object,
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      dialog: false,
      hasFileLoading: false,
      mensagem: null,
      arquivos: [],
    };
  },
  methods: {
    buildInitialState() {
      this.mensagem = this.compra.mensagem;
    },
    close() {
      this.dialog = false;
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    validateFile() {
      if (this.hasFileLoading) {
        this.$toast('Aguarde até o término do envio dos arquivos', { color: 'error' });
        return true;
      }
      return false;
    },
    enviar() {
      if (this.validateForm() || this.validateFile()) {
        return;
      }
      this.aprovar();
    },
    aprovar() {
      this.loading = true;
      CompraService.aprovar(this.compra.id, {
        id: this.compra.id,
        mensagem: this.mensagem,
        arquivos: this.arquivos,
      })
        .then(() => {
          this.$toast('Ordem enviada com sucesso', { color: 'success' });
          this.$emit('movimentar');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.compra) {
        this.buildInitialState();
      }
    },
  },
  validations: {
    mensagem: {
      required,
    },
  },
};
</script>
