<template>
  <v-row class="mt-2">
    <div class="d-flex flex-grow-1 pt-3 px-3">
      <px-search-text label="Busque por um fornecedor" v-model="searchText"
                      :on-search="onChangeSearchText"/>
    </div>
    <div class="d-flex my-3 mr-3">
      <px-refresh color="primary" @refresh="emitUpdate"/>
    </div>
    <div class="d-flex my-3 mr-3">
      <px-select-menu prepend-icon="mdi-sort-variant"
                      v-model="selectedSort"
                      :options="sortOptions"
                      @change="onChangeSort"/>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    cotacao: Object,
  },
  data() {
    return {
      searchText: null,
      selectedSort: null,
      sortOptions: [
        {
          description: 'Por fornecedor',
          value: 'fornecedor.nome',
        },
      ],
    };
  },
  methods: {
    onChangeSearchText() {
      this.$emit('change-search-text', this.searchText);
    },
    onChangeSort() {
      this.$emit('change-sort', this.selectedSort);
    },
    emitUpdate() {
      this.$emit('update');
    },
  },
  created() {
    [this.selectedSort] = this.sortOptions;

    this.$emit('change-sort', this.selectedSort);
  },
};
</script>
