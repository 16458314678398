<template>
  <div v-if="!loading">
    <v-row class="text-right">
      <v-col cols="12">
        <px-grupo-compra-adicionar-produto :cotacao="cotacao"
                                           :comprador-default="compradores[0]"
                                           v-if="isUserAdmin && !readOnly"
                                           @update="onUpdate">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" class="mr-2" v-on="on">
              <v-icon>mdi-plus</v-icon>
              Produto
            </v-btn>
          </template>
        </px-grupo-compra-adicionar-produto>
        <px-grupo-compra-entrar-cotacao v-if="!readOnly"
                                        :cotacao="cotacao"
                                        :comprador-default="compradores[0]"
                                        @update="onUpdate">
          <template v-slot:activator="{ on }">
            <v-btn color="success" v-on="on">
              Entrar na cotação
            </v-btn>
          </template>
        </px-grupo-compra-entrar-cotacao>
      </v-col>
    </v-row>
    <v-expansion-panels multiple accordion>
      <v-expansion-panel
        v-for="produto in cotacao.produtos"
        :key="produto.id"
      >
        <v-expansion-panel-header>
          <v-row no-gutters dense>
            <v-col cols="12" sm="8">
              {{ produto.id }} - {{ produto.produto.descricao }}
              <small class="caption">({{ produto.participantes.length }} participante(s))</small>
              <div class="caption">
                <px-anexo read-only dot
                          v-model="produto.arquivos"
                          download-base-path="files/download-comprador">
                  <template v-slot:activator="{ on }">
                    <v-btn icon
                           x-small
                           v-on="on">
                      <v-icon>mdi-paperclip</v-icon>
                    </v-btn>
                  </template>
                </px-anexo>
                {{ produto.especificacaoProduto | naoInformado }}
                <px-cotacao-respostas-produtos-especificacao
                  small
                  :especificacao="produto.especificacaoProduto"/>
              </div>
            </v-col>
            <v-col cols="12" sm="2">
              <v-tooltip top v-if="produto.menorPrecoPraticado && hasMoreParticipantes(produto)">
                <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ produto.menorPrecoPraticado | currency }}
                      </span>
                </template>
                <span>Menor preço praticado</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="2" v-if="produto.quantidade">
              {{ produto.quantidade }} {{ produto.unidadeMedida.descricao }}
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(participante, index) in produto.participantes">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-row no-gutters dense>
                  <v-col cols="12" sm="10">
                    {{ participante.comprador.pessoa.nome }}
                    <v-tooltip top v-if="showPrecoQuantidade(participante)">
                      <template v-slot:activator="{ on }">
                      <span v-on="on">
                        ({{ participante.precoPraticado | currency }})
                      </span>
                      </template>
                      <span>Preço praticado</span>
                    </v-tooltip>
                    <v-chip class="ml-2"
                            color="secondary"
                            small label
                            v-if="isCompradorAtual(participante.comprador)">
                      Minha empresa
                    </v-chip>
                  </v-col>
                  <v-col cols="12" sm="2" v-if="showPrecoQuantidade(participante)">
                    {{ participante.quantidade }}
                    {{ produto.unidadeMedida.descricao }}
                    <px-grupo-compra-cotacao-visualizacao-participacoes-options
                      v-if="!readOnly"
                      :cotacao="cotacao"
                      :participante="participante"
                      :is-comprador-atual="isCompradorAtual(participante.comprador)"
                      @update="onUpdate"/>
                  </v-col>
                  <v-list-item-subtitle>
                    {{ participante.comprador.pessoa.cnpj | cnpj }}
                  </v-list-item-subtitle>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="showDivider(index, produto.participantes)"
                       :key="`div-${index}`"/>
          </template>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import CompradorService from '@/api/comprador-service';
import GrupoCompraEntrarCotacao from '../entrar-cotacao/GrupoCompraEntrarCotacao.vue';
import GrupoCompraAdicionarProduto from '../adicionar-produto/GrupoCompraAdicionarProduto.vue';
import GrupoCompraCotacaoVisualizacaoParticipacoesOptions
  from './GrupoCompraCotacaoVisualizacaoParticipacoesOptions.vue';

export default {
  props: {
    cotacao: Object,
    grupoCompra: Object,
    isUserAdmin: Boolean,
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      compradores: [],
    };
  },
  computed: {
    showDivider() {
      return (index, items) => index !== (items.length - 1);
    },
    showPrecoQuantidade() {
      return (item) => {
        if (this.grupoCompra.ocultarEmpresasComprador) {
          return this.isCompradorAtual(item.comprador);
        }
        return true;
      };
    },
    hasMoreParticipantes() {
      return item => item.participantes.length > 1;
    },
    isCompradorAtual() {
      return (item) => {
        if (isEmpty(this.compradores)) {
          return false;
        }
        return this.compradores.map(c => c.id)
          .includes(item.id);
      };
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
    getCompradores() {
      this.loading = true;
      CompradorService.getComprador()
        .then(({ data }) => {
          this.compradores = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCompradores();
  },
  components: {
    pxGrupoCompraCotacaoVisualizacaoParticipacoesOptions:
      GrupoCompraCotacaoVisualizacaoParticipacoesOptions,
    pxGrupoCompraEntrarCotacao: GrupoCompraEntrarCotacao,
    pxGrupoCompraAdicionarProduto: GrupoCompraAdicionarProduto,
  },
};
</script>
