<template>
  <div>
    <div v-if="this.loading">
      <px-circular-loading/>
    </div>
    <div v-if="!this.loading">
      <template v-for="(produto, index) in produtos">
        <v-list-item :key="produto.id">
          <v-list-item-content>
            <v-list-item-title>
              <v-row dense>
                <v-col cols="12" sm="7" class="text-truncate">
                  {{ produto.produto.descricao }}
                  <px-historico-preco-produto small
                                              :produto="produto.produto"
                                              :unidade-medida="produto.unidadeMedida"/>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ produto.quantidade }} {{ produto.unidadeMedida.descricao }}
                      </span>
                    </template>
                    <span>Quantidade</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        {{ produto.precoUnitario | currency }}
                      </span>
                    </template>
                    <span>Preço unitário</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ produto.total | currency }}</span>
                    </template>
                    <span>Preço total</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="showDivider(index)" :key="`v-div${produto.id}`"/>
      </template>
    </div>
  </div>
</template>

<script>
import CompraService from './compra-service';

export default {
  props: {
    compra: {},
  },
  computed: {
    showDivider() {
      return index => index !== (this.produtos.length - 1);
    },
  },
  data() {
    return {
      produtos: {},
      loading: false,
    };
  },
  created() {
    this.loading = true;
    CompraService.getCompraProdutos(this.compra.id)
      .then((data) => {
        this.produtos = data.data;
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
