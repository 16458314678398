<template>
  <v-card
    class="mx-auto parent-list-overflow"
    height="450"
    max-height="450"
  >
    <v-card-title class="headline">
      Compras pendentes
    </v-card-title>
    <v-card-text class="text--primary list-overflow">
      <div v-if="loading">
        <px-circular-loading/>
      </div>
      <div>
        <v-list class="mt-3" v-if="!loading && compras.length">
          <template v-for="compra in compras">
            <v-list-item link :to="`/${idGrupo}/compra/${compra.id}/detalhes`" :key="compra.id">
              <v-list-item-content>
                <v-row dense no-gutters>
                  <v-col cols="12" sm="8">
                    <px-situacao-compra-icon :situacao="compra.situacao"/>
                    {{ compra.fornecedor.pessoa.nome }}
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    {{ compra.total | currency }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`div-${compra.id}`"/>
          </template>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CompraService from '@/modules/compra/compra-service';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
      compras: [],
    };
  },
  computed: {
    ...mapGetters(['idGrupo']),
  },
  methods: {
    getCotacoes() {
      this.loading = true;
      CompraService.getComprasList({
        query: `grupoCompra.id = ${this.grupoId} and (origemCompra = "COTACAO_CONJUNTA" or origemCompra = "CATALOGO_COMPRA_CONJUNTA") and situacao in ("PENDENTE_COMPRADOR", "PENDENTE_FORNECEDOR") order by dataCompra desc`,
      })
        .then(({ data }) => {
          this.compras = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getCotacoes();
  },
};
</script>

<style scoped>
.parent-list-overflow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list-overflow {
  width: 100%;
  height: 395px;
  max-height: 395px;
  padding-right: 17px;
  overflow-y: scroll;
  box-sizing: content-box;
}
</style>
