<template>
  <div>
    <px-view-header title="Cadastro de cotação" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <v-stepper class="elevation-1" v-model="currentStep" vertical>

      <v-stepper-step :complete="currentStep > 1" step="1">
        Dados básicos
      </v-stepper-step>
      <v-stepper-content step="1">
        <px-grupo-compra-cadastro-cotacao-dados-basicos ref="dadosBasicos" :cotacao="cotacao"/>
        <v-btn class="mr-2"
               color="primary"
               :disabled="!dadosBasicosForm || dadosBasicosForm.$v.$invalid"
               @click="next">Continuar</v-btn>
        <v-btn text @click="onClickBack">Cancelar</v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">Produtos</v-stepper-step>
      <v-stepper-content step="2">
        <px-grupo-compra-cadastro-cotacao-produtos v-if="this.comprador"
                                                   :cotacao="cotacao"
                                                   :comprador-default="comprador"
                                                   @resetfornecedor="resetFornecedor"/>
        <v-btn class="mr-2"
               color="primary"
               :disabled="!cotacao.produtos.length"
               @click="next">
          Continuar
        </v-btn>
        <v-btn text @click="previous">Voltar</v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 3" step="3">Fornecedores</v-stepper-step>
      <v-stepper-content step="3">
        <px-grupo-compra-cadastro-cotacao-fornecedores v-if="currentStep === 3"
                                          :cotacao="cotacao"
                                          @resetfornecedor="resetFornecedor"/>
        <v-btn class="mr-2"
               color="primary"
               :disabled="!cotacao.fornecedores.length"
               @click="onClickEnviar">
          Criar
        </v-btn>
        <v-btn text @click="previous">
          Voltar
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import CompradorService from '@/api/comprador-service';
import CotacaoService from './cotacoes-grupo-service';
import GrupoCompraCadastroCotacaoDadosBasicos from './GrupoCompraCadastroCotacaoDadosBasicos.vue';
import GrupoCompraCadastroCotacaoProdutos from './GrupoCompraCadastroCotacaoProdutos.vue';
import GrupoCompraCadastroCotacaoFornecedores from './GrupoCompraCadastroCotacaoFornecedores.vue';

export default {
  data() {
    return {
      dadosBasicosForm: null,
      loading: false,
      cotacao: {
        grupoCompra: {
          id: Number(this.$route.params.idGrupo),
        },
        dataFechamento: null,
        horaFechamento: null,
        prazoEntrega: null,
        validade: null,
        observacao: null,
        produtos: [],
        fornecedores: [],
      },
      comprador: null,
      currentStep: 1,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Cotações' },
        { text: 'Cadastro' },
      ],
    };
  },
  methods: {
    next() {
      this.currentStep = this.currentStep + 1;
    },
    previous() {
      this.currentStep = this.currentStep - 1;
    },
    resetFornecedor() {
      this.cotacao.fornecedores = [];
    },
    buildCotacao(cotacao) {
      const payload = cloneDeep(cotacao);

      payload.datafechamento = `${cotacao.dataFechamento}T${cotacao.horaFechamento}`;
      payload.dataFechamento = undefined;
      payload.horaFechamento = undefined;

      payload.produtos = payload.produtos.map(produto => ({
        produto: produto.produto,
        arquivos: produto.arquivos,
        especificacaoProduto: produto.especificacaoProduto,
        unidadeMedida: produto.unidadeMedida,
        quantidade: produto.quantidade,
        participantes: [{
          comprador: produto.comprador,
          precoPraticado: produto.precoPraticado,
          quantidade: produto.quantidade,
        }],
      }));
      return payload;
    },
    save(cotacao) {
      this.loading = true;
      CotacaoService.save(cotacao)
        .then(() => {
          this.$toast('Cotação criada com sucesso', { color: 'success' });
          this.onClickBack();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickEnviar() {
      this.save(this.buildCotacao(this.cotacao));
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
  created() {
    CompradorService.getDefault()
      .then((response) => {
        this.comprador = response.data;
      });
  },
  mounted() {
    this.dadosBasicosForm = this.$refs.dadosBasicos;
  },
  components: {
    pxGrupoCompraCadastroCotacaoDadosBasicos: GrupoCompraCadastroCotacaoDadosBasicos,
    pxGrupoCompraCadastroCotacaoProdutos: GrupoCompraCadastroCotacaoProdutos,
    pxGrupoCompraCadastroCotacaoFornecedores: GrupoCompraCadastroCotacaoFornecedores,
  },
};
</script>
