<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="cotacoes.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="cotacoes.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhuma cotação encontrada"
      loading-text="Carregando cotações..."
      :options="optionsList"
    >
      <template v-slot:item.identificador="{ item }">
        <px-lista-cotacao-identificador :cotacao="item"
                                        :save-function="identifierFunction"
                                        @update="emitUpdate"/>
      </template>
      <template v-slot:item.createdIn="{ item }">
        {{item.createdIn | date(true)}}
      </template>
      <template v-slot:item.datafechamento="{ item }">
        {{item.datafechamento | date(true)}}
      </template>
      <template v-slot:item.quantidadeProdutos="{ item }">
        {{item.quantidadeProdutos}} produto(s)
      </template>
      <template v-slot:item.quantidadeRespostas="{ item }">
        {{item.quantidadeRespostas}} resposta(s)
      </template>
      <template v-slot:item.prazoEntrega="{ item }">
        {{item.prazoEntrega | date | naoInformado}}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon link :to="`/${grupoId}/cotacao/${item.id}`">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CotacaoGrupoService from './cotacoes-grupo-service';

export default {
  props: {
    grupoId: [Number, String],
    cotacoes: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      identifierFunction: CotacaoGrupoService.updateIdentifier,
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Identificador',
          value: 'identificador',
        },
        {
          text: 'Data de entrada',
          value: 'createdIn',
        },
        {
          text: 'Produtos',
          sortable: false,
          value: 'quantidadeProdutos',
        },
        {
          text: 'Respostas',
          sortable: false,
          value: 'quantidadeRespostas',
        },
        {
          text: 'Prazo de entrega',
          value: 'prazoEntrega',
        },
        {
          text: 'Data e hora de fechamento',
          value: 'datafechamento',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {},
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    emitUpdate() {
      this.$emit('update');
    },
  },
};
</script>
