<template>
  <px-custom-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
        <v-btn color="primary"
               v-on="on">

          <v-icon>mdi-plus</v-icon>
          Fornecedor
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      Adicionar fornecedor
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-fornecedor v-model="vinculo.fornecedor"
                                      class="required"
                                      :error="$v.vinculo.fornecedor.$error"
                                      @blur="$v.vinculo.fornecedor.$touch()"
                                      required/>
        </v-col>
      </v-row>
      <v-row class="text--primary">
        <v-col cols="12">
          <v-switch
            v-model="vinculo.catalogoHabilitado"
            label="Permite criar catálogo para o grupo"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row class="text--primary">
        <v-col cols="12">
          <v-switch
            v-model="vinculo.ativo"
            label="Fornecedor ativo"
          ></v-switch>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid"
             @click="onClickSave">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Fechar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import GestaoFornecedorService from './gestao-fornecedor-service';

export default {
  props: {
    fornecedor: Object,
    idGrupo: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      vinculo: {
        fornecedor: null,
        ativo: true,
        catalogoHabilitado: false,
      },
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    buildPayload() {
      return {
        id: this.vinculo.id || undefined,
        grupoCompra: {
          id: this.idGrupo,
        },
        fornecedor: this.vinculo.fornecedor,
        ativo: this.vinculo.ativo,
        catalogoHabilitado: this.vinculo.catalogoHabilitado,
      };
    },
    onClickSave() {
      this.loading = true;
      GestaoFornecedorService.createOrUpdate(this.idGrupo, this.buildPayload())
        .then(() => {
          this.close();
          this.$toast('Fornecedor criado', { color: 'success' });
          this.$emit('change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      if (this.fornecedor) {
        this.vinculo = cloneDeep(this.fornecedor);
        return;
      }
      this.$v.$reset();
      this.vinculo = {
        fornecedor: null,
        ativo: true,
        catalogoHabilitado: false,
      };
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    vinculo: {
      fornecedor: {
        required,
      },
    },
  },
};
</script>
