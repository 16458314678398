export default {
  searchText(state) {
    return state.searchText;
  },
  sort(state) {
    if (!state.selectedSort) {
      return 'order by destaque desc, disponivel desc';
    }
    return `order by ${state.selectedSort.value}`;
  },
  stringFilter(state) {
    let filter = '';
    if (state.searchText) {
      const val = state.searchText.toLowerCase().replaceAll(' ', '%').replaceAll('"', '\\"');
      filter = `codigo like "%${val}%" or titulo like "%${val}%" or especificacao like "%${val}%" or fornecedor.nome like "%${val}%"`;
    }
    return filter;
  },
  query(state, getters) {
    return `${getters.stringFilter} ${getters.sort}`.trim();
  },
};
