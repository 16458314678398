import {
  SET_SEARCH_TEXT_ACTION,
} from './actions.type';
import { SET_SEARCH_TEXT } from './mutations.type';

export default {
  [SET_SEARCH_TEXT_ACTION](context, text) {
    context.commit(SET_SEARCH_TEXT, text);
  },
};
