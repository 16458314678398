<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="350"
    max-width="300"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon
             small
             v-on="on"
             @click.stop.prevent=""
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Editando participação</v-card-title>
      <v-card-text class="text--primary" v-if="itemCotacao">
        <v-row>
          <v-col cols="12">
            <px-input-number label="Quantidade"
                             required
                             class="required"
                             :error="$v.itemCotacao.quantidade.$error"
                             @blur="$v.itemCotacao.quantidade.$touch()"
                             v-model="itemCotacao.quantidade"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <px-input-money
              ref="precoPraticado"
              label="Preço praticado"
              v-model="itemCotacao.precoPraticado"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :loading="loading"
               :disabled="$v.$invalid"
               @click="save">
          Salvar
        </v-btn>
        <v-btn @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import CotacaoGrupoService from '../cotacoes-grupo-service';

export default {
  props: {
    cotacao: Object,
    participante: Object,
  },
  data() {
    return {
      menuOpened: false,
      loading: false,
      itemCotacao: null,
    };
  },
  computed: {
    precoPraticado() {
      if (this.participante.precoPraticado) {
        return this.participante.precoPraticado.toFixed(2);
      }
      return null;
    },
  },
  methods: {
    close() {
      this.menuOpened = false;
    },
    buildSaveState() {
      const participacao = { ...this.participante };
      participacao.quantidade = this.itemCotacao.quantidade;
      participacao.precoPraticado = this.itemCotacao.precoPraticado;
      return participacao;
    },
    save() {
      this.loading = true;
      CotacaoGrupoService.editarParticipacao(this.cotacao.id,
        this.participante.id,
        this.buildSaveState())
        .then(() => {
          this.close();
          this.$emit('update');
          this.$toast('Participação atualizada com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.itemCotacao = {
        precoPraticado: this.precoPraticado,
        quantidade: this.participante.quantidade,
      };
      this.$v.$reset();
    },
  },
  watch: {
    menuOpened() {
      if (!this.menuOpened) {
        this.itemCotacao = null;
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    itemCotacao: {
      quantidade: {
        minValue(value) {
          return value > 0;
        },
      },
    },
  },
};
</script>
