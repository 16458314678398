<template>
  <v-card>
    <v-card-text>
      <v-row dense>
        <v-col class="headline mb-1 text--primary" cols="12" sm="6" md="7">
          {{ resposta.fornecedor.pessoa.nome }}
          <px-popover-fornecedor :fornecedor="resposta.fornecedor" show-score />
        </v-col>
        <v-col cols="12"
               sm="6"
               md="5"
               class="text-right">
<!--          <v-btn color="primary" link :to="`./${resposta.id}/fechamento`">-->
          <v-btn color="primary"
                 v-if="isAdmin && isCotacaoAberta"
                 @click="onClickComprar">
            Comprar
          </v-btn>
        </v-col>
      </v-row>
      <p>
        <v-icon>mdi-domain</v-icon>
        {{ resposta.fornecedor.pessoa.cnpj | cnpj }}
      </p>
      <div class="text--primary">
        <v-row dense>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Forma pagamento</span>
            <div>
              <px-forma-pagamento v-if="resposta.formaPagamento"
                                  :forma-pagamento="resposta.formaPagamento"
                                  :prazo-pagamento="resposta.prazoPagamento"/>
              <span v-if="!resposta.formaPagamento">Não informado</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Valido até</span>
            <div>
              <span v-if="resposta.validade">{{ resposta.validade | date }}</span>
              <span v-else>Não informado</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Produtos</span>
            <div>
              {{ quantidadeProdutos }}/{{ resposta.cotacaoConjunta.quantidadeProdutos }}
              produto(s)
            </div>
          </v-col>
        </v-row>
        <v-row dense class="mt-3">
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Tipo de frete</span>
            <div>
              {{ resposta.frete.tipoFrete | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Prazo de embarque</span>
            <div>
              <span v-if="resposta.frete.prazoEntrega">
                {{ resposta.frete.prazoEntrega | pluralize(['dia útil', 'dias úteis'])}}
              </span>
              <span v-else>Não informado</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12" sm="4" v-if="resposta.frete.ofertaFreteGratis">
            <span class="caption font-weight-bold">Frete grátis a partir de</span>
            <div class="large-text">
              {{ resposta.frete.valorMinimoFreteGratis | currency }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Observação do frete</span>
            <div class="large-text">
              {{ resposta.frete.observacaoFrete | naoInformado }}
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="caption font-weight-bold">Observação geral</span>
            <div class="large-text">
              {{ resposta.observacao | naoInformado }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <px-confirm-dialog ref="confirm"/>
  </v-card>
</template>

<script>
import { max } from 'lodash';
import { SituacaoCotacao } from 'px-business-components';
import CotacaoConjuntaRespostaService from '../../cotacao-conjunta-resposta';

export default {
  props: {
    resposta: Object,
    isAdmin: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    quantidadeProdutos() {
      return max(this.resposta.totais.map(t => t.quantidadeProdutos));
    },
    isCotacaoAberta() {
      return this.resposta.cotacaoConjunta.situacao === SituacaoCotacao.keys.ABERTA.key;
    },
  },
  methods: {
    comprar() {
      this.loading = true;
      CotacaoConjuntaRespostaService
        .escolherFornecedor(this.resposta.cotacaoConjunta.id, this.resposta.id)
        .then(() => {
          this.$router.go(-1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickComprar() {
      this.$refs.confirm.open('Você deseja escolher este fornecedor?',
        'Este fornecedor será marcado como escolhido e esta operação não pode ser desfeita',
        { width: 480 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.comprar();
        });
    },
  },
};
</script>
