<template>
  <div :align="align">
    <v-img v-if="fotoPath"
           :src="fotoPath"
           v-bind="$attrs"
    ></v-img>
    <v-img v-else
           v-bind="$attrs"
           src="../assets/no-image.png"
    ></v-img>
  </div>
</template>

<script>
import FileUrlPathService from '@/api/file-url-path-service';

export default {
  props: {
    foto: {
      type: Object,
      required: false,
    },
    align: {
      type: String,
      default: 'start',
    },
  },
  computed: {
    fotoPath() {
      if (!this.foto) {
        return null;
      }
      return FileUrlPathService.getPath(this.foto.path);
    },
  },
};
</script>
