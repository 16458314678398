import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './plugins/vuelidate';
import './plugins/vuecookies';
import './plugins/vuetify-toast';
import './plugins/vue2filters';
import './plugins/axios/axios-config';
import './plugins/echarts/echarts';
import './plugins/vue-the-mask';
import './plugins/messages';
import './plugins/gmaps';
import './plugins/avaliacoes';
import './plugins/notifications';

import './styles/app.css';

import 'px-components';
import 'px-components/dist/pxComponents.css';
import 'px-business-components/dist/pxBusinessComponents.css';
import 'px-business-components';

import './components/vue-components';

import 'moment/locale/pt-br';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
