<template>
  <v-container>
    <div>
      <v-card>
        <v-card-text class="text--primary">
          <v-row>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Fornecedor</span>
              <div>
                {{ resposta.fornecedor.pessoa.nome }}
                ({{ resposta.fornecedor.pessoa.cnpj | cnpj }})
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Frete</span>
              <div>
                {{ resposta.frete.tipoFrete }} ({{ resposta.frete.prazoEntrega }} dia(s) útil(eis))
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-card class="mt-3">
      <v-list>
        <template v-for="(produto, index) in totais.produtos">
          <v-list-item dense :key="produto.idCotacaoConjuntaProdutoParticipante">
            <v-list-item-content>
              <v-row dense>
                <v-col cols="12" sm="4">
                  {{ produto.cotacaoConjuntaProduto.produto.descricao }}
                  <small>
                    ({{ produto.cotacaoConjuntaProduto.quantidade }}
                    {{produto.cotacaoConjuntaProduto.unidadeMedida.descricao}})
                  </small>
                  <div class="caption">
                    {{ produto.especificacaoProduto | truncate(50)}}
                  </div>
                </v-col>
                <v-col cols="12" sm="8" class="justify-end align-content-end">
                  <px-fechamento-cotacao-prazo :produto="produto" />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < (totais.produtos.length - 1)"
                     :key="`div${produto.idCotacaoConjuntaProdutoParticipante}`"/>
        </template>
      </v-list>
    </v-card>
    <v-row>
      <v-col cols="12" class="body-2 text-right">
        Quantidade de compras geradas: {{ quantidadeCompras }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn color="primary" class="mr-2"
               :disabled="isInvalid"
               @click="next">
          Próximo
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { uniqWith, isEqual } from 'lodash';
import { FormaPagamento } from 'px-business-components';
import FechamentoCotacaoPrazo from './FechamentoCotacaoPrazo.vue';

export default {
  props: {
    resposta: Object,
    totais: Object,
  },
  data() {
    return {
      formasPagamentos: FormaPagamento.values,
      formaPagamento: null,
    };
  },
  computed: {
    quantidadeCompras() {
      let selecionados = this.totais.produtos
        .filter(p => p.selected)
        .map(p => p.selected)
        .map(p => ({
          formaPagamento: p.formaPagamento,
          prazoPagamento: p.prazoPagamento,
        }));
      selecionados = uniqWith(selecionados, isEqual);
      return (selecionados || []).length;
    },
    isInvalid() {
      return Boolean(this.totais.produtos.filter(p => !p.selected).length);
    },
  },
  methods: {
    next() {
      this.$emit('next');
    },
    initProdutos() {
      this.totais.produtos = this.totais.produtos.map(p => ({
        ...p,
        selected: null,
      }));
    },
  },
  created() {
    this.initProdutos();
  },
  components: {
    pxFechamentoCotacaoPrazo: FechamentoCotacaoPrazo,
  },
};
</script>
