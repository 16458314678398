<template>
  <v-card>
    <v-card-text class="text--primary">
      <px-acompanhamentos allow-files
                          :item-type="messageType"
                          :item-id="compra.id"
                          :user="user"
                          @on-message="onMessage"/>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import CompraService from './compra-service';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      messageType: this.$px.types.COMPRAS,
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    onMessage() {
      CompraService.notificarMensagem(this.compra.id);
    },
  },
};
</script>
