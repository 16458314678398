<template>
  <div>
    <px-oportunidades-header @change="onClickRefresh"/>

    <div v-if="loading">
      <px-circular-loading/>
    </div>

    <div v-if="!loading && !cotacoes.total"
         align="center"
         class="body-1 mb-5 mt-10">
      Nenhuma oportunidade encontrada
    </div>

    <div v-if="!loading && cotacoes && cotacoes.total">
      <template v-for="cotacao in cotacoes.content">
        <px-oportunidade-item :key="cotacao.id" :cotacao="cotacao"/>
      </template>
      <div class="mt-3 ml-2 caption">
        {{ cotacoes.total }} oportunidades encontradas
      </div>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="pagesLimit"
          :total-visible="8"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import CotacaoConjuntaService from '@/modules/cotacoes/cotacoes-grupo-service';
import OportunidadeItem from './OportunidadeItem.vue';
import OportunidadesHeader from './OportunidadesHeader.vue';

const LIMIT = 10;
const PAGE = 1;

export default {
  mixins: [paginationMixin],
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      page: PAGE,
      pagesLimit: 1,
      loading: false,
      cotacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: PAGE,
        limit: LIMIT,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      optionsList: {
        page: PAGE,
        itemsPerPage: LIMIT,
        sortBy: '',
        sortDesc: false,
        multiSort: false,
      },
    };
  },
  computed: {
    ...mapGetters('oportunidadesFiltro', ['stringFilter']),
  },
  methods: {
    resetPage() {
      this.page = 1;
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    onClickRefresh() {
      this.getCotacoes();
    },
    onChangeOptions() {
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCotacoes();
    },
    setPagesLimit() {
      this.pagesLimit = Math.ceil(this.cotacoes.total / LIMIT);
    },
    buildFilter() {
      let filter = `grupoCompra.id = ${this.grupoId}`;
      if (this.stringFilter) {
        filter = `${filter} and ${this.stringFilter}`;
      }
      return filter;
    },
    getCotacoes() {
      this.loading = true;
      const { limit, offset } = this.listOptions;
      CotacaoConjuntaService.getList({
        limit,
        offset,
        query: this.buildFilter(),
        oportunidades: true,
      })
        .then(({ data }) => {
          this.cotacoes = data;
          this.setPagesLimit();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.optionsList.page = newValue;
      this.onChangeOptions();
    },
  },
  created() {
    this.getCotacoes();
  },
  components: {
    pxOportunidadesHeader: OportunidadesHeader,
    pxOportunidadeItem: OportunidadeItem,
  },
};
</script>
