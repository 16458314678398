<template>
  <div>
    <px-view-header title="Indicadores do catálogo" :breadcrumbs="breadcrumbs"/>

    <px-selecao-mes @change="onChangeData"/>

    <div v-if="loading">
      <px-circular-loading/>
    </div>

    <div v-if="!loading && totalizadores">
      <div>
        <v-row>
          <v-col cols="12" sm="4">
            <v-hover v-slot:default="{ hover }" open-delay="100">
              <px-indicador-card :elevation="hover ? 12 : 2"
                                 :value="totalComprado"
                                 color="blue"
                                 description="valor comprado"
                                 icon="mdi-currency-usd"/>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="4">
            <v-hover v-slot:default="{ hover }" open-delay="100">
              <px-indicador-card :elevation="hover ? 12 : 2"
                                 :value="totalSaving"
                                 color="success"
                                 description="valor economizado"
                                 icon="mdi-finance"/>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="4">
            <v-hover v-slot:default="{ hover }" open-delay="100">
              <px-indicador-card :elevation="hover ? 12 : 2"
                                 :value="totalCompras"
                                 color="blue"
                                 description="compras"
                                 icon="mdi-shopping"/>
            </v-hover>
          </v-col>
        </v-row>
      </div>

      <div v-if="selectedDate">
        <px-indicadores-catalogo-listagem-compra :data-mes="selectedDate"/>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ComprasListagemService from '@/modules/compra/compra-service';
import IndicadoresCatalogoListagemCompra from './IndicadoresCatalogoListagemCompra.vue';

export default {
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
      selectedDate: null,
      totalizadores: null,
      breadcrumbs: [
        { text: 'Indicadores' },
        { text: 'Indicadores do catálogo' },
      ],
    };
  },
  computed: {
    query() {
      return `grupoCompra.id = ${this.grupoId} and origemCompra = "CATALOGO_COMPRA_CONJUNTA" and dataCompra >= ${this.selectedDate.startDate} and dataCompra <= ${this.selectedDate.endDate} and situacao in ("PENDENTE_COMPRADOR", "PENDENTE_FORNECEDOR", "APROVADA")`;
    },
    totalComprado() {
      if (!this.totalizadores) {
        return Vue.filter('currency')(0);
      }
      return Vue.filter('currency')(this.totalizadores.total || 0);
    },
    totalSaving() {
      if (!this.totalizadores) {
        return Vue.filter('currency')(0);
      }
      return Vue.filter('currency')(this.totalizadores.saving || 0);
    },
    totalCompras() {
      if (!this.totalizadores) {
        return 0;
      }
      return this.totalizadores.quantidadeCompras || 0;
    },
  },
  methods: {
    onChangeData(date) {
      this.selectedDate = date;
      this.getTotalizadores();
    },
    getTotalizadores() {
      this.loading = true;
      ComprasListagemService.getTotalizadores({
        query: this.query,
      })
        .then(({ data }) => {
          this.totalizadores = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    pxIndicadoresCatalogoListagemCompra: IndicadoresCatalogoListagemCompra,
  },
};
</script>
