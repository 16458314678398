<template>
  <div>
    <px-view-header title="Fornecedores homologados" :breadcrumbs="breadcrumbs"/>
    <px-listagem-fornecedores-header :on-click-refresh="onClickRefresh"
                                     :on-change-filter="onChangeFilter"
                                     @change="onChangeFilter"/>
    <px-lista-fornecedor :fornecedores="fornecedores"
                         :loading="loading"
                         :on-change-options="onChangeOptions"
                         :options-list="optionsList"
                         @update="onClickRefresh"/>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import { mapGetters } from 'vuex';
import GestaoFornecedorService from '@/modules/fornecedores/gestao-fornecedor-service';
import ListagemFornecedoresHeader from './ListagemFornecedoresHeader.vue';
import ListaFornecedor from './ListaFornecedor.vue';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      breadcrumbs: [
        { text: 'Administrador' },
        { text: 'Fornecedores homologados' },
      ],
      fornecedores: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      loading: false,
      optionsList: {},
    };
  },
  computed: {
    ...mapGetters('listagemFornecedores', ['stringFilter']),
  },
  methods: {
    onChangeFilter() {
      this.resetPage();
      this.getFornecedores();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getFornecedores();
    },
    onClickRefresh() {
      this.getFornecedores();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getFornecedores() {
      this.loading = true;

      this.listOptions.filter = this.stringFilter;

      const { limit, offset } = this.listOptions;
      return GestaoFornecedorService.list(this.grupoId, {
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.fornecedores = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    pxListagemFornecedoresHeader: ListagemFornecedoresHeader,
    pxListaFornecedor: ListaFornecedor,
  },
};
</script>
