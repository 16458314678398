<template>
  <div>
    <v-card class="mt-3">
      <v-list-item link :to="link">
        <v-row dense no-gutters class="mt-3 mb-3">
          <v-col cols="12" sm="6" md="3">
            <px-situacao-cotacao-icon :situacao="cotacao.situacao"/>
            {{ cotacao.identificador }}
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-icon>mdi-clock-outline</v-icon>
                        {{ cotacao.createdIn | date(true) }}
                      </span>
              </template>
              <span>Data de criação</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-icon>mdi-clock-check-outline</v-icon>
                        {{ cotacao.datafechamento | date(true) }}
                      </span>
              </template>
              <span>Data de fechamento</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <px-oportunidade-item-produtos :produtos="cotacao.produtos">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-icon class="mr-1">mdi-package-variant-closed</v-icon>
                  {{ cotacao.produtos.length }} produto(s)
                </span>
              </template>
            </px-oportunidade-item-produtos>
          </v-col>
        </v-row>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import OportunidadeItemProdutos from './OportunidadeItemProdutos.vue';

export default {
  props: {
    cotacao: {
      type: Object,
    },
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  computed: {
    link() {
      return `/${this.grupoId}/cotacao/${this.cotacao.id}?origin=oportunidades`;
    },
  },
  methods: {
    onClickItem() {},
  },
  components: {
    pxOportunidadeItemProdutos: OportunidadeItemProdutos,
  },
};
</script>
