import Vue from 'vue';
import Vuex from 'vuex';
import login from '@/modules/login/store';
import auth from '@/store/auth';
import user from '@/store/user';
import grupo from '@/store/grupo';
import compraConjuntaCotacaoPendente from '@/store/compra-conjunta-cotacao-pendente';
import compraConjuntaCotacaoFiltro from '@/store/cotacao-compra-conjunta-filtro';
import listagemCompra from '@/store/listagem-compra';
import oportunidadesFiltro from '@/store/oportunidades-filtro';
import participantes from '@/store/participantes';
import listagemFornecedores from '@/store/listagem-fornecedores';
import catalogoSearch from '@/store/catalogo-search';
import carrinho from '@/store/carrinho';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    auth,
    user,
    grupo,
    compraConjuntaCotacaoPendente,
    compraConjuntaCotacaoFiltro,
    listagemCompra,
    oportunidadesFiltro,
    participantes,
    listagemFornecedores,
    catalogoSearch,
    carrinho,
  },
});
