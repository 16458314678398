<template>
  <div class="d-inline-block">
    <span v-if="isFob">
      FOB
    </span>
    <span v-else>
      <template v-if="totalFrete.valorFrete > 0">
        {{ totalFrete.valorFrete | currency }}
      </template>
      <template v-else>
        Grátis
      </template>
    </span>
  </div>
</template>

<script>
import { TipoFrete } from 'px-business-components';

export default {
  props: {
    totalFrete: Object,
  },
  computed: {
    isFob() {
      return this.totalFrete.tipoFrete === TipoFrete.keys.FOB.key;
    },
  },
};
</script>
