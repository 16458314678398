export default {
  grupo(state) {
    return state.grupo;
  },
  grupoCompra(state) {
    return state.grupo;
  },
  idGrupo(state) {
    return state.grupo ? state.grupo.id : null;
  },
  userGrupo(state) {
    return state.userGrupo;
  },
  isGrupoAdmin(state) {
    return state.userGrupo.isGrupoAdmin;
  },
};
