<template>
  <div class="text-center">
    <v-menu
      v-model="menuOpened"
      :close-on-content-click="false"
      :nudge-width="300"
      transition="scale-transition"
      offset-x
    >
      <template v-slot:activator="{ on }">
        <v-btn icon small
               v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card v-if="loading" class="text-center">
        <v-card-text>
          <px-circular-loading/>
        </v-card-text>
      </v-card>
      <v-card v-if="!loading">
        <v-container>
          <v-row align="center" justify="center">
            <v-avatar color="orange" size="62">
              <img src="../../assets/default-user.png" alt="Usuário">
            </v-avatar>
          </v-row>
          <v-container v-if="user">
            <v-row class="v-list-item__title" align="center" justify="center">
              <span>{{user.nome}}</span>
            </v-row>
            <v-row class="v-list-item__subtitle" align="center" justify="center">
              <span>{{user.email}}</span>
            </v-row>
          </v-container>
          <v-container>
            <v-row align="center" justify="center">
              <v-btn link to="/minha-conta" rounded outlined>Gerenciar minha conta</v-btn>
            </v-row>
          </v-container>
        </v-container>
        <v-divider/>
        <v-container>
          <v-row align="center" justify="center">
            <v-btn color="primary" depressed @click="logout">Logout</v-btn>
          </v-row>
        </v-container>
        <v-divider/>
        <v-container>
          <v-row align="center" justify="center">
            <v-btn tag="a" text x-small
                   href="https://proxpect.com.br/termos-uso.html"
                   target="_blank">
              Termos de uso
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
    <px-aceite-termos v-model="aceiteTermo" @update="onTermoAceito"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_USER } from '@/store/user/actions.type';
import { LOGOUT } from '@/store/auth/actions.type';
import AceiteTermos from './AceiteTermos.vue';

export default {
  data: () => ({
    menuOpened: false,
    aceiteTermo: false,
  }),
  computed: {
    ...mapGetters('user', ['user', 'loading']),
  },
  methods: {
    ...mapActions('user', {
      getUser: FETCH_USER,
    }),
    logout() {
      this.menu = false;
      this.$store.dispatch(LOGOUT);
      return this.$router.push({ name: 'login' });
    },
    onTermoAceito() {
      this.aceiteTermo = false;
    },
  },
  created() {
    this.getUser()
      .then(() => {
        if (!this.user.termoAceito) {
          this.aceiteTermo = true;
        }
      });
  },
  components: {
    pxAceiteTermos: AceiteTermos,
  },
};
</script>
