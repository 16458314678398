<template>
  <v-card>
    <v-card-text>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">Para minha empresa</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="mt-5" v-if="loading">
        <px-circular-loading/>
      </div>
      <div v-if="!loading && showTotal">
        <px-grupo-compra-resposta-produtos :produtos="totais.produtos"
                                           :check-situacao="false"
                                           show-especificacao-line/>
        <v-divider/>
        <v-row class="text--primary ml-2">
          <v-col cols="12" sm="4" v-for="(total, idx) in totais.totais" :key="idx">
          <span class="caption font-weight-bold">
             <px-forma-pagamento :forma-pagamento="total.formaPagamento"
                                 :prazo-pagamento="total.prazoPagamento"/>
          </span>
            <div>
              {{ total.total | currency }}
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="!loading && !showTotal" class="text--primary ml-4">
        Não participante
      </div>
    </v-card-text>
  </v-card>

</template>

<script>
import { isEmpty } from 'lodash';
import { comparePrazos } from '@/utils/prazo-utils';
import CotacaoConjuntaRespostaService from '../../cotacao-conjunta-resposta';
import GrupoCompraRespostaProdutos from './GrupoCompraRespostaProdutos.vue';

export default {
  props: {
    resposta: Object,
  },
  data() {
    return {
      loading: false,
      totais: null,
    };
  },
  computed: {
    showTotal() {
      return this.totais && !isEmpty(this.totais.totais) && !isEmpty(this.totais.produtos);
    },
  },
  methods: {
    getSimulacao() {
      this.loading = true;
      CotacaoConjuntaRespostaService.getRespostaTotais(this.resposta.cotacaoConjunta.id,
        this.resposta.id)
        .then(({ data }) => {
          this.totais = data;
          this.totais.totais = this.totais.totais.sort(comparePrazos);
          this.totais.produtos = this.totais.produtos.map(p => ({
            ...p,
            prazos: p.prazos.sort(comparePrazos),
          }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getSimulacao();
  },
  components: {
    pxGrupoCompraRespostaProdutos: GrupoCompraRespostaProdutos,
  },
};
</script>
