<template>
  <div>
    <div class="mt-5">
      <px-indicadores-catalogo-lista-compra
        :vendas="vendas" :loading="loading"
        :on-change-options="onChangeOptions"
        :options-list="optionsList"
        @update="onClickRefresh"/>
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination-mixin';
import CompraListagemService from '@/modules/compra/compra-service';
import IndicadoresCatalogoListaCompra from './IndicadoresCatalogoListaCompra.vue';

export default {
  props: {
    dataMes: Object,
  },
  mixins: [paginationMixin],
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      vendas: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      loading: false,
      optionsList: {},
    };
  },
  computed: {
    query() {
      return `grupoCompra.id = ${this.grupoId} and origemCompra = "CATALOGO_COMPRA_CONJUNTA" and dataCompra >= ${this.dataMes.startDate} and dataCompra <= ${this.dataMes.endDate} and situacao in ("PENDENTE_COMPRADOR", "PENDENTE_FORNECEDOR", "APROVADA")`;
    },
  },
  methods: {
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getVendas();
    },
    onClickRefresh() {
      this.getVendas();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    getVendas() {
      this.loading = true;
      const {
        limit,
        offset,
      } = this.listOptions;
      this.listOptions.filter = this.query;

      return CompraListagemService.getCompras({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
      })
        .then((response) => {
          this.vendas = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    'dataMes.startDate': function (newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      this.resetPage();
      this.onClickRefresh();
    },
  },
  components: {
    pxIndicadoresCatalogoListaCompra: IndicadoresCatalogoListaCompra,
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
  },
};
</script>
