<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="vendas.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="vendas.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
       no-data-text="Nenhuma compra encontrada"
      loading-text="Carregando compras..."
      :options="optionsList"
    >
      <template v-slot:item.fornecedor.nome="{ item }">
        <px-situacao-compra-icon :situacao="item.situacao"/>
        {{ item.fornecedor.pessoa.nome }}
      </template>
      <template v-slot:item.comprador="{ item }">
        <px-span-tooltip top :text="comprador(item)" :tooltip="item.comprador.pessoa.nome"/>
      </template>
      <template v-slot:item.dataCompra="{ item }">
        {{ item.dataCompra | date }}
      </template>
      <template v-slot:item.saving="{ item }">
        {{item.saving | currency}}
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
      <template v-slot:item.options="{ item }">
        <v-btn icon link :to="link(item)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    vendas: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Fornecedor',
          value: 'fornecedor.nome',
        },
        {
          text: 'Empresa',
          value: 'comprador',
        },
        {
          text: 'Data da compra',
          value: 'dataCompra',
        },
        {
          text: 'Saving',
          value: 'saving',
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    link() {
      return item => `/${this.grupoId}/compra/${item.id}/detalhes`;
    },
    comprador() {
      return item => Vue.filter('truncate')(item.comprador.pessoa.nome, 20);
    },
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    emitUpdate() {
      this.$emit('update');
    },
  },
};
</script>
