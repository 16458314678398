<template>
  <px-lateral-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on }">
      <v-btn small color="primary"
             v-on="on">
        Finalizar compra
      </v-btn>
    </template>
    <template v-slot:title>
    </template>
    <template>
      <v-row class="">
        <v-col cols="12" class="headline blue--text">
          Total da compra: {{ totalCompra | currency }}
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="12">
          <v-alert
            v-if="!hasPrazoPagamento"
            type="warning"
            outlined
            dismissible>
            Apenas o pagamento à vista está disponível para o valor comprado.
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <px-select-enum label="Forma de pagamento"
                          class="required"
                          :error="$v.compra.formaPagamento.$error"
                          @blur="$v.compra.formaPagamento.$touch()"
                          v-model="compra.formaPagamento"
                          :items="formasPagamentosFilter"
                          @change="onChangeFormaPagamento"/>
        </v-col>
        <v-col cols="12" sm="6">
          <px-autocomplete-prazo-pagamento-catalogo
            :catalogo-id="carrinho.catalogo.id"
            :class="{'required': !isAVista}"
            :disabled="isAVista"
            :filter="filterPrazos"
            v-model="compra.prazoPagamento"
            :error="$v.compra.prazoPagamento.$error"
            @blur="$v.compra.prazoPagamento.$touch()"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            outlined
            auto-grow
            hide-details
            label="Observações internas"
            counter="1500"
            rows="2"
            v-model="compra.observacao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11">
          <v-textarea
            outlined
            auto-grow
            label="Mensagem para o fornecedor"
            counter="1500"
            rows="2"
            v-model="compra.mensagem"
          />
        </v-col>
        <v-col cols="1">
          <px-anexo v-model="compra.arquivos" :max="3" @loading="onFileLoad"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="close">
        Voltar
      </v-btn>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid || hasFileLoading"
             @click="enviar">
        Finalizar
      </v-btn>
    </template>
  </px-lateral-dialog>
</template>

<script>
import currency from 'currency.js';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMixin from '@/mixins/validation-mixin';
import { FormaPagamento } from 'px-business-components';
import { mapActions } from 'vuex';
import { GET_ITENS } from '@/store/carrinho/actions.type';
import CarrinhoService from './carrinho-service';

const initalState = () => ({
  formaPagamento: null,
  prazoPagamento: null,
  observacao: null,
  mensagem: null,
  arquivos: [],
});

export default {
  props: {
    carrinho: Object,
    frete: Object,
    totalVista: [Number, String],
    totalPrazo: [Number, String],
    hasPrazoPagamento: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      grupoId: this.$route.params.idGrupo,
      dialog: false,
      hasFileLoading: false,
      formasPagamentos: FormaPagamento.values,
      compra: initalState(),
    };
  },
  computed: {
    filterPrazos() {
      return `valorMinimo <= ${this.totalPrazo}`;
    },
    formasPagamentosFilter() {
      if (this.hasPrazoPagamento) {
        return this.formasPagamentos;
      }
      return this.formasPagamentos.filter(item => item.key === FormaPagamento.keys.A_VISTA.key);
    },
    totalCompra() {
      return currency(this.totalProdutos).add(this.frete.valorFrete);
    },
    totalProdutos() {
      if (!this.compra || !this.compra.formaPagamento) {
        return this.totalVista;
      }

      if (this.compra.formaPagamento === FormaPagamento.keys.A_VISTA.key) {
        return this.totalVista;
      }

      return this.totalPrazo;
    },
    isAVista() {
      return this.compra.formaPagamento === FormaPagamento.keys.A_VISTA.key;
    },
  },
  methods: {
    ...mapActions('carrinho', {
      getItens: GET_ITENS,
    }),
    onChangeFormaPagamento() {
      if (this.isAVista || !this.compra.formaPagamento) {
        this.compra.prazoPagamento = null;
      }
    },
    buildInitialState() {
      this.compra = initalState();
      this.$v.$reset();
    },
    close() {
      this.dialog = false;
    },
    onFileLoad(loading) {
      this.hasFileLoading = loading;
    },
    validateFile() {
      if (this.hasFileLoading) {
        this.$toast('Aguarde até o término do envio dos arquivos', { color: 'error' });
        return true;
      }
      return false;
    },
    enviar() {
      if (this.validateForm() || this.validateFile()) {
        return;
      }
      this.aprovar();
    },
    aprovar() {
      this.loading = true;
      CarrinhoService.gerarCompra(this.grupoId, this.carrinho.id, this.compra)
        .then(({ data }) => {
          this.getItens(this.grupoId);
          this.$toast('Compra enviada ao fornecedor', { color: 'success' });
          this.$router.push({
            name: 'main.compras.detalhes',
            params: {
              id: data.id,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      if (this.carrinho) {
        this.buildInitialState();
      }
    },
  },
  validations: {
    compra: {
      formaPagamento: {
        required,
      },
      prazoPagamento: {
        required: requiredIf(function () {
          return !this.isAVista;
        }),
      },
    },
  },
};
</script>
