import Vue from 'vue';

const DEFAULT_MESSAGE = 'Aconteceu um erro interno no servidor';
const eventsBasePath = process.env.VUE_APP_API_URL_EVENTS;
const notificationsBasePath = process.env.VUE_APP_API_URL_NOTIFICATIONS;

const responseErrorInterceptor = (config) => {
  const { response } = config;
  if (response.status === 401 || response.status === 400) {
    return Promise.reject(config);
  }

  if (response.config.baseURL === eventsBasePath
    || response.config.baseURL === notificationsBasePath) {
    return Promise.reject(config);
  }

  let message = DEFAULT_MESSAGE;

  if (response.data) {
    // eslint-disable-next-line prefer-destructuring
    message = response.data.message;
  }

  if (response.status === 500 || !message) {
    message = DEFAULT_MESSAGE;
  }

  Vue.prototype.$toast(message, { color: 'error' });
  return Promise.reject(config);
};


export default responseErrorInterceptor;
