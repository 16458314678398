import Vue from 'vue';
import VueRouter from 'vue-router';
import loginRoute from './login';
import grupoRouter from './selecao-grupo';
import mainRoute from './main/main-router';
import googleAnalytcs from './google-analytics';

Vue.use(VueRouter);

const routes = [
  loginRoute,
  grupoRouter,
  mainRoute,
  {
    path: '*',
    redirect: { name: 'login' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

if (process.env.VUE_APP_GA_KEY) {
  window.ga('create', process.env.VUE_APP_GA_KEY, 'auto');
  router.afterEach(googleAnalytcs);
}

export default router;
