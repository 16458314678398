<template>
  <div>
    <px-view-header title="Cotações do grupo" :breadcrumbs="breadcrumbs"/>
    <div class="mt-5">
      <px-grupo-compra-cotacoes-header :on-click-refresh="onRefresh" @change="onChangeFilter"/>
      <px-grupo-compra-cotacoes-list :cotacoes="cotacoes"
                                     :loading="loading"
                                     :grupo-id="grupoId"
                                     :on-change-options="onChangeOptions"
                                     :options-list="optionsList"
                                     @update="onRefresh"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import paginationMixin from '@/mixins/pagination-mixin';
import GrupoCompraCotacoesHeader from './GrupoCompraCotacoesHeader.vue';
import GrupoCompraCotacoesList from './GrupoCompraCotacoesList.vue';
import CotacoesService from './cotacoes-grupo-service';

export default {
  mixins: [paginationMixin],
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
      cotacoes: {
        content: [],
        total: 0,
      },
      listOptions: {
        page: 1,
        limit: 10,
        offset: 0,
        filter: '',
        sort: 'createdIn',
        sortDesc: true,
      },
      optionsList: {},
      breadcrumbs: [
        { text: 'Grupos de compra' },
        { text: 'Grupos' },
      ],
    };
  },
  computed: {
    ...mapGetters('compraConjuntaCotacaoFiltro', ['stringFilter']),
    ...mapGetters(['isGrupoAdmin']),
  },
  methods: {
    onRefresh() {
      this.getCotacoes();
    },
    onChangeFilter() {
      this.resetPage();
      this.getCotacoes();
    },
    onChangeOptions(options) {
      this.optionsList = options;
      this.listOptions = this.optionsToPage(this.listOptions, this.optionsList);
      this.getCotacoes();
    },
    resetPage() {
      this.listOptions = this.resetPagination(this.listOptions);
      this.optionsList = this.pageToOptions(this.listOptions);
    },
    buildFilter() {
      let filter = `grupoCompra.id = ${this.grupoId}`;
      if (this.stringFilter) {
        filter = `${filter} and ${this.stringFilter}`;
      }
      return filter;
    },
    getOportunidadesPram() {
      if (this.isGrupoAdmin) {
        return undefined;
      }
      return false;
    },
    getCotacoes() {
      this.loading = true;
      this.listOptions.filter = this.buildFilter();
      const { limit, offset } = this.listOptions;
      return CotacoesService.getList({
        limit,
        offset,
        query: this.buildQuery(this.listOptions),
        oportunidades: this.getOportunidadesPram(),
      })
        .then((response) => {
          this.cotacoes = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    pxGrupoCompraCotacoesHeader: GrupoCompraCotacoesHeader,
    pxGrupoCompraCotacoesList: GrupoCompraCotacoesList,
  },
  created() {
    this.optionsList = this.pageToOptions(this.listOptions);
    this.listOptions.filter = '';
  },
};
</script>
