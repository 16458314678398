<template>
  <div>
    <v-list>
      <template v-for="(oferta, index) in catalogo.ofertas">
        <px-carrinho-empresa-catalogo-item :key="oferta.id" :oferta="oferta"
                                           @change="emitChange"/>
        <v-divider :key="`${oferta.id}-div`" v-if="index !== (catalogo.ofertas.length - 1)"/>
      </template>
    </v-list>
    <v-divider/>
    <div>
      <px-carrinho-empresa-catalogo-totais :comprador="comprador"
                                           :catalogo="catalogo"/>
    </div>
  </div>
</template>

<script>
import CarrinhoEmpresaCatalogoItem from './CarrinhoEmpresaCatalogoItem.vue';
import CarrinhoEmpresaCatalogoTotais from './CarrinhoEmpresaCatalogoTotais.vue';

export default {
  props: {
    catalogo: Object,
    comprador: Object,
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
  },
  components: {
    pxCarrinhoEmpresaCatalogoItem: CarrinhoEmpresaCatalogoItem,
    pxCarrinhoEmpresaCatalogoTotais: CarrinhoEmpresaCatalogoTotais,
  },
};
</script>
