<template>
  <div>
    <px-compra-catalogo-aviso :compra="compra"/>
    <v-card>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6" md="7">
            <p class="mb-1">
              <span class="headline text--primary">
                {{ compra.fornecedor.pessoa.nome }}
              </span>
              <px-situacao-compra-tag :situacao="compra.situacao"/>
              <px-indicador-recebimento :compra="compra"/>
            </p>
          </v-col>
          <v-col cols="12"
                 sm="6"
                 md="5"
                 class="text-right">
            <px-compra-cancelar-dialog v-if="!hideCancelado"
                                       :compra="compra"
                                       @movimentar="onMovimentar"/>
            <px-compra-enviar-ordem-dialog v-if="isPendenteComprador"
                                           :compra="compra"
                                           @movimentar="onMovimentar"/>
          </v-col>
        </v-row>
        <p class="mt-2">
          <v-icon>mdi-domain</v-icon>
          {{ compra.fornecedor.pessoa.cnpj | cnpj }}
        </p>
        <p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon>mdi-map-marker</v-icon>
              {{ compra.fornecedor.endereco | endereco }}
            </span>
            </template>
            <span>Endereço do fornecedor</span>
          </v-tooltip>
        </p>
        <p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon>mdi-calendar</v-icon>
              {{ compra.dataCompra | date }}
            </span>
            </template>
            <span>Data da compra</span>
          </v-tooltip>
        </p>
        <div class="text--primary">
          <v-row dense>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Forma de pagamento</span>
              <div>
                <px-forma-pagamento :forma-pagamento="compra.formaPagamento"
                                    :prazo-pagamento="compra.prazoPagamento"/>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Tipo de frete</span>
              <div>
                {{ compra.frete.tipoFrete }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">{{ prazoLabel }}</span>
              <div>
                {{ compra.frete.prazoEntrega | pluralize(['dia útil', 'dias úteis']) }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Observações internas</span>
              <div>
                {{ compra.observacao | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Anexos</span>
              <div v-if="compra.arquivos.length">
                <px-anexo-chip :files="compra.arquivos"
                               download-base-path="files/download-comprador"/>
              </div>
              <div v-else>
                Nenhum anexo informado
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Observação do frete</span>
              <div>
                {{ compra.frete.observacaoFrete | naoInformado }}
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Mensagem para o fornecedor</span>
              <div>
                {{ compra.mensagem | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="caption font-weight-bold">Mensagem para o comprador</span>
              <div>
                {{ compra.mensagemFornecedor | naoInformado }}
              </div>
            </v-col>
            <v-col cols="12" sm="4" v-if="isRecusadoCancelado">
              <span class="caption font-weight-bold">Motivo da recusa/cancelamento</span>
              <div>
                {{ compra.motivoRecusa | naoInformado }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <px-confirm-dialog ref="confirm"/>
    </v-card>
  </div>
</template>

<script>
import { OrigemCompra, SituacaoCompra, TipoFrete } from 'px-business-components';
import CompraEnviarOrdemDialog from './CompraEnviarOrdemDialog.vue';
import CompraCancelarDialog from './CompraCancelarDialog.vue';
import IndicadorRecebimento from './IndicadorRecebimento.vue';
import CompraCatalogoAviso from './CompraCatalogoAviso.vue';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      loadingCancelar: false,
    };
  },
  computed: {
    prazoLabel() {
      if (this.isFromCatalogo && !this.isFob) {
        return 'Prazo de entrega';
      }
      return 'Prazo de embarque';
    },
    isFob() {
      return this.compra.frete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    isFromCatalogo() {
      return this.compra.origemCompra === OrigemCompra.keys.CATALOGO_COMPRA_CONJUNTA.key;
    },
    isPendenteComprador() {
      return this.compra.situacao === SituacaoCompra.keys.PENDENTE_COMPRADOR.key;
    },
    hideCancelado() {
      return (this.compra.origemCompra === OrigemCompra.keys.CADASTRO.key
        && !this.compra.processoAprovacao)
        || this.compra.situacao === SituacaoCompra.keys.CANCELADA.key;
    },
    isRecusadoCancelado() {
      return this.compra.situacao === SituacaoCompra.keys.RECUSADA.key
        || this.compra.situacao === SituacaoCompra.keys.CANCELADA.key;
    },
  },
  methods: {
    onMovimentar() {
      this.$emit('movimentar');
    },
  },
  components: {
    pxCompraEnviarOrdemDialog: CompraEnviarOrdemDialog,
    pxCompraCancelarDialog: CompraCancelarDialog,
    pxIndicadorRecebimento: IndicadorRecebimento,
    pxCompraCatalogoAviso: CompraCatalogoAviso,
  },
};
</script>
