export default {
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    const filter = 'situacao = "ELABORACAO"';
    if (state.searchText) {
      return `${filter} and (produto.descricao like '%${state.searchText.toLowerCase()}%' or identificador like '%${state.searchText.toLowerCase()}%')`;
    }
    return filter;
  },
};
