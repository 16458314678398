<template>
  <px-custom-dialog transition="fadet"
                    width="450"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Avaliações
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <v-list>
            <template v-for="(avaliacao, index) in avaliacoes" >
              <px-avaliacao-item :key="avaliacao.id"
                                 :pontuacao="avaliacao.avaliacao.pontuacao"
                                 :comentario="avaliacao.avaliacao.comentario"/>
              <v-divider v-if="index < (avaliacoes.length - 1)"
                         :key="`div-${avaliacao.id}`"/>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </template>
  </px-custom-dialog>
</template>

<script>
export default {
  props: {
    avaliacoes: Array,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
