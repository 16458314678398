export default {
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    let filter = '';
    if (state.searchText) {
      filter = `fornecedor.nome like "%${state.searchText.toLowerCase()}%" or fornecedor.cnpj like "%${state.searchText.toLowerCase()}%"`;
    }
    return filter;
  },
};
