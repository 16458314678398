<template>
  <div class="text--primary">
    <v-row dense class="text--primary mb-2">
      <v-col cols="10">
        <px-situacao-cotacao-icon :situacao="cotacao.situacao"/>
        {{ cotacao.identificador }}
      </v-col>
      <v-col cols="2" class="text-right">
        <px-grupo-compra-cancelar-cotacao v-if="isUserAdmin"
                                          :cotacao="cotacao"
                                          @update="emitUpdate"/>
      </v-col>
    </v-row>
    <div class="text--primary">
      <v-row dense>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Prazo para entrada</span>
          <div>
            {{ cotacao.datafechamento | date(true) }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Prazo de entrega</span>
          <div>
            {{ cotacao.prazoEntrega | date | naoInformado }}
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Válido até</span>
          <div>
            {{ cotacao.validade | date | naoInformado }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4">
          <span class="caption font-weight-bold">Responsável</span>
          <px-grupo-compra-editar-admins v-if="isUserAdmin"
                                         :cotacao="cotacao"
                                         :grupo-id="grupoId"
                                         @update="emitUpdate">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-1" color="primary" x-small text v-on="on">
                Alterar
              </v-btn>
            </template>
          </px-grupo-compra-editar-admins>
          <div>
            <px-admins-text :administradores="cotacao.administradores" />
          </div>
        </v-col>
        <v-col cols="12" sm="8">
          <span class="caption font-weight-bold">Observação</span>
          <div>
            {{ cotacao.observacao | naoInformado }}
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GrupoCompraEditarAdmins from '../../editar-admins/GrupoCompraEditarAdmins.vue';
import GrupoCompraCancelarCotacao from '../../cancelar-cotacao/GrupoCompraCancelarCotacao.vue';
import PxAdminsText from '../PxAdminsText.vue';

export default {
  props: {
    cotacao: Object,
    isUserAdmin: Boolean,
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
  },
  components: {
    pxGrupoCompraCancelarCotacao: GrupoCompraCancelarCotacao,
    pxGrupoCompraEditarAdmins: GrupoCompraEditarAdmins,
    PxAdminsText,
  },
};
</script>
