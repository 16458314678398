<template>
  <div>
    <px-grupo-compra-cotacao-fechamento-compra :cotacao="cotacao" @update="onUpdate"/>
    <px-grupo-compra-cotacao-fechamento-tabs class="mt-3"
                                             :cotacao="cotacao"
                                             :grupo-compra="grupoCompra"
                                             :is-user-admin="isUserAdmin"/>
  </div>
</template>

<script>
import GrupoCompraCotacaoFechamentoTabs from './GrupoCompraCotacaoFechamentoTabs.vue';
import GrupoCompraCotacaoFechamentoCompra from './GrupoCompraCotacaoFechamentoCompra.vue';

export default {
  props: {
    cotacao: Object,
    grupoCompra: Object,
    isUserAdmin: Boolean,
  },
  methods: {
    onUpdate() {
      this.$emit('update');
    },
  },
  components: {
    pxGrupoCompraCotacaoFechamentoTabs: GrupoCompraCotacaoFechamentoTabs,
    pxGrupoCompraCotacaoFechamentoCompra: GrupoCompraCotacaoFechamentoCompra,
  },
};
</script>
