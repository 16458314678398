import axios from 'axios';

const getOfertas = (idGrupo, params) => axios.get(`/grupo-compras/${idGrupo}/ofertas`, {
  params,
});

const getOferta = (idGrupo, id) => axios.get(`/grupo-compras/${idGrupo}/ofertas/${id}`);

const criarDuvida = (idGrupo, id, data) => axios.post(`grupo-compras/${idGrupo}/ofertas/${id}/perguntas`, data);
const getDuvidas = (idGrupo, id, params) => axios.get(`grupo-compras/${idGrupo}/ofertas/${id}/perguntas`, { params });
const getFreteCep = (idCatalogo, cep) => axios.get(`/fornecedor/catalogos/${idCatalogo}/cep/${cep}`);

export default {
  getOfertas,
  getOferta,
  criarDuvida,
  getDuvidas,
  getFreteCep,
};
