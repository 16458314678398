<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-tooltip top>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            icon
            :small="small"
            v-on="{ ...onTooltip, ...on }"
          >
            <v-icon :small="small">
              mdi-chart-line
            </v-icon>
          </v-btn>
        </template>
        <span>Histórico de preço</span>
      </v-tooltip>
    </template>
    <v-card class="mx-auto"
            min-width="550"
            max-width="550">
      <v-card-text class="text--primary">
        <div v-if="loading">
          <px-circular-loading/>
        </div>
        <figure v-if="!loaded || (loaded && historico && historico.length)">
          <v-chart :options="chart"
                   ref="comprasChart"
                   theme="primary"
                   autoresize/>
        </figure>
        <div v-if="loaded && (!historico || !historico.length)"
             align="center"
             class="body-1 mb-5 mt-5">
          Ainda não há históricos de preço
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import chartMixin from '@/mixins/chart-mixin';
import { toEchartData } from '@/utils/chart-utils';
import HistoricoPreco from '@/api/historico-preco';
import { chain } from 'lodash';
import moment from 'moment';

export default {
  mixins: [chartMixin],
  props: {
    produto: Object,
    unidadeMedida: Object,
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
      loaded: false,
      historico: null,
      chart: null,
      loading: false,
    };
  },
  methods: {
    hideLoading() {
      this.$refs.comprasChart.hideLoading();
    },
    buildChartData() {
      return chain(this.historico)
        .map((hist) => {
          const current = moment(hist.dataCompra);
          const dataKey = current.format('YYYYMMDD');
          const description = current.format('DD/MM/YYYY');
          return {
            dataKey,
            description,
            value: hist.precoUnitario || 0,
          };
        })
        .sortBy('dataKey')
        .value();
    },
    buildTitle() {
      if (this.historico && this.historico.length) {
        return {
          text: `Histórico de preço por ${this.unidadeMedida.descricao}`,
        };
      }
      return {
        textStyle: {
          color: 'grey',
          fontSize: 20,
        },
        text: 'No data',
        left: 'center',
        top: 'center',
      };
    },
    buildChart() {
      const historicos = this.buildChartData();
      return {
        title: this.buildTitle(),
        tooltip: {
          formatter: this.tooltipFormatter,
        },
        dataset: {
          source: [
            ['Histórico'],
            ...toEchartData(historicos, 'description', 'value'),
          ],
        },
        xAxis: {
          type: 'category',
          interval: 1,
          maxInterval: 1,
          splitNumber: 1,
        },
        yAxis: {
          axisLabel: {
            formatter: this.axisMoneyFormatter,
          },
        },
        grid: {
          left: '15%',
        },
        series: [{ type: 'line' }],
      };
    },
    getHistorico() {
      const query = `produto = ${this.produto.id} and unidadeMedida=${this.unidadeMedida.id} order by dataCompra desc`;

      this.loading = true;
      HistoricoPreco.getHistoricoPrecos({ query })
        .then((response) => {
          this.historico = response.data;
          this.chart = this.buildChart();
          this.loaded = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    opened(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue && !this.historico) {
        this.getHistorico();
      }
    },
  },
};
</script>

<style scoped>

figure {
  max-width: 540px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

figure .echarts {
  width: 550px;
  max-width: 550px;
}

</style>
