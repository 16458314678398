<template>
  <v-card
    outlined
  >
    <v-card-text class="text--primary" v-if="oferta.disponivel">
      <div>
        <v-chip small
                label
                v-if="oferta.destaque"
                color="orange darken-2"
                class="caption mb-5"
                text-color="white">
          DESTAQUE
        </v-chip>
      </div>
      <px-oferta-info-fornecedor :oferta="oferta"/>
      <px-oferta-info-precos :oferta="oferta"/>
      <px-oferta-info-fretes
        v-if="compradorSelecionado"
        :comprador="compradorSelecionado"
        :oferta="oferta"/>
      <px-oferta-info-pedido-minimo :oferta="oferta"/>
      <px-oferta-info-add-carrinho @comprador="changeComprador" :oferta="oferta"/>
    </v-card-text>
    <v-card-text v-else class="text--primary body-1">
      <v-icon class="mr-2" color="error">mdi-alert-outline</v-icon>
      Não disponível
    </v-card-text>
  </v-card>
</template>

<script>
import OfertaInfoFornecedor from './OfertaInfoFornecedor.vue';
import OfertaInfoPrecos from './OfertaInfoPrecos.vue';
import OfertaInfoFretes from './OfertaInfoFretes.vue';
import OfertaInfoPedidoMinimo from './OfertaInfoPedidoMinimo.vue';
import OfertaInfoAddCarrinho from './OfertaInfoAddCarrinho.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantidade: null,
      compradorSelecionado: null,
    };
  },
  methods: {
    changeComprador(comprador) {
      this.compradorSelecionado = comprador;
      this.$emit('comprador', comprador);
    },
  },
  components: {
    pxOfertaInfoFornecedor: OfertaInfoFornecedor,
    pxOfertaInfoPrecos: OfertaInfoPrecos,
    pxOfertaInfoFretes: OfertaInfoFretes,
    pxOfertaInfoPedidoMinimo: OfertaInfoPedidoMinimo,
    pxOfertaInfoAddCarrinho: OfertaInfoAddCarrinho,
  },
};
</script>
