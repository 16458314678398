import Vue from 'vue';
import ECharts from 'vue-echarts';

import theme from './theme.json';
import themeSecondary from './theme-secondary.json';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

ECharts.registerTheme('primary', theme);
ECharts.registerTheme('secondary', themeSecondary);

Vue.component('v-chart', ECharts);
