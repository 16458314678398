<template>
  <px-custom-dialog transition="fadet"
                    width="450"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Adicionar participante
    </template>
    <template>
      <v-row>
        <v-col cols="12">
          <px-autocomplete-entidade-usuario required
                                            class="required"
                                            :entidade-grupo-id="participanteId"
                                            :error="$v.usuario.$error"
                                            @blur="$v.usuario.$touch()"
                                            v-model="usuario"/>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             :disabled="$v.$invalid"
             @click="save">
        Salvar
      </v-btn>
      <v-btn @click="close">
        Cancelar
      </v-btn>
    </template>
  </px-custom-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ParticipanteService from '../participantes-service';

export default {
  props: {
    grupoId: [Number, String],
    participanteId: [Number, String],
  },
  data() {
    return {
      dialog: false,
      loading: false,
      usuario: null,
    };
  },
  methods: {
    buildSaveState() {
      return {
        user: {
          id: this.usuario.id,
        },
        grupoCompraEntidade: {
          id: this.participanteId,
        },
      };
    },
    save() {
      this.loading = true;
      ParticipanteService.createUsuario(this.grupoId, this.buildSaveState())
        .then(() => {
          this.$toast('Usuário adicionado', { color: 'success' });
          this.$emit('change');
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    buildInitialState() {
      this.usuario = null;
      this.$v.$reset();
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    usuario: {
      required,
    },
  },
};
</script>
