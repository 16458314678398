import axios from 'axios';

const getList = () => axios.get('/grupo-compras');
const get = id => axios.get(`/grupo-compras/${id}`);
const getAcesso = id => axios.get(`/grupo-compras/${id}/me`);

export default {
  getList,
  get,
  getAcesso,
};
