import axios from 'axios';

const getParticipantes = (id, params) => axios.get(`/grupo-compras/${id}/entidades`, { params });
const getParticipante = (id, participante) => axios.get(`/grupo-compras/${id}/entidades/${participante}`);
const removeParticipante = (id, entidadeGrupo) => axios.delete(`/grupo-compras/${id}/entidades/${entidadeGrupo}`);
const getUsuarios = (id, participante) => axios.get(`/grupo-compras/${id}/entidades/${participante}/usuarios`);
const removeUsuario = (id, entidade, participante) => axios.delete(`/grupo-compras/${id}/entidades/${entidade}/usuarios/${participante}`);
const createUsuario = (idGrupo, data) => axios.post(`/grupo-compras/${idGrupo}/entidades/usuarios`, data);
const create = data => axios.post('/grupo-compras/entidades', data);

export default {
  getParticipantes,
  getParticipante,
  removeParticipante,
  getUsuarios,
  removeUsuario,
  createUsuario,
  create,
};
