<template>
  <div>
    <v-row dense class="mx-1">
      <v-chip close
              class="mt-2"
              v-if="values" @click:close="onClickClose">
        {{values.length}} selecionado(s)
      </v-chip>
      <v-spacer/>
      <px-search-text label="Busque por um fornecedor" v-model="filters.searchText"
                      style="max-width: 400px"
                      :on-search="onUpdateFilters"/>
    </v-row>
    <px-line-filter :options="statusOptions" v-model="filters.selectedStatus"
                    :on-select-item="onUpdateFilters">
      <px-lista-fornecedor-header-menu :filters="filters" @change="onUpdateFilters"/>
    </px-line-filter>
  </div>
</template>

<script>
import PxListaFornecedorHeaderMenu from './PxListaFornecedorHeaderMenu.vue';

export default {
  props: {
    values: Array,
    comprador: Object,
  },
  data() {
    return {
      statusOptions: [],
      filters: {
        searchText: null,
        selectedStatus: null,
        includeProdutos: true,
      },
    };
  },
  methods: {
    onUpdateFilters() {
      this.$emit('update', this.filters);
    },
    onClickClose() {
      this.$emit('resetfornecedor');
    },
  },
  created() {
    if (!this.comprador || !this.comprador.endereco) {
      this.statusOptions = [];
      return;
    }

    this.statusOptions = [
      {
        id: 'TODAS',
        description: 'Todas',
        value: null,
      },
      {
        id: 'MUNICIPIO',
        description: this.comprador.endereco.municipio.nome,
        property: 'municipio',
        value: this.comprador.endereco.municipio.id,
      },
      {
        id: 'MESORREGIAO',
        description: this.comprador.endereco.mesorregiao.nome,
        property: 'mesorregiao',
        value: this.comprador.endereco.mesorregiao.id,
      },
      {
        id: 'UF',
        description: this.comprador.endereco.estado.sigla,
        property: 'estado',
        value: this.comprador.endereco.estado.id,
      },
    ];

    [this.filters.selectedStatus] = this.statusOptions;
  },
  components: {
    PxListaFornecedorHeaderMenu,
  },
};
</script>
