<template>
  <v-card class="mt-3">
    <v-card-text>
      <v-data-table
        show-expand
        hide-default-footer
        disable-pagination
        :headers="headers"
        :items="compra.produtos"
        item-key="id"
        class="elevation-0"
      >
        <template v-slot:item.descricao="{ item }">
          {{ descricaoProduto(item) }}&nbsp;
          <px-open-oferta v-if="isFromCatalogo"
                          :oferta="item.oferta"
                          :grupo-id="grupoId"/>
          <px-historico-preco-produto small
                                      :produto="item.produto"
                                      :unidade-medida="item.unidadeMedida"/>
        </template>
        <template v-slot:item.quantidade="{ item }">
          <span>
            {{ item.quantidade }}&nbsp;
          {{ item.unidadeMedida.descricao }}
          </span>
        </template>
        <template v-slot:item.precoUnitario="{ item }">
          <span>{{ item.precoUnitario | currency }}</span>
        </template>
        <template v-slot:item.icms="{ item }">
          <px-imposto :imposto="item.impostos" tipo="ICMS"/>
        </template>
        <template v-slot:item.ipi="{ item }">
          <px-imposto :imposto="item.impostos" tipo="IPI"/>
        </template>
        <template v-slot:item.total="{ item }">
          <span>{{ item.total | currency }}</span>
          <px-situacao-cotacao-produto :situacao="item.situacao"/>
        </template>
        <template v-if="isFromCotacao" v-slot:item.options="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon link target="_blank" :to="linkCotacao(item)">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>Ir para a cotação</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-row dense class="text-right text--primary mt-3">
            <v-col cols="12" sm="6" class="body-1">
                <span class="font-weight-bold">
                  FRETE: {{ compra.frete.totalTransporte | currency }}
                </span>
            </v-col>
            <v-col cols="12" sm="6" class="body-1">
                <span class="orange--text text--darken-4 font-weight-bold">
                  TOTAL: {{ compra.total | currency }}
                </span>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="grey lighten-4 expanded-item">
            <v-row class="mt-3">
              <v-col cols="12" sm="6">
                <span class="caption font-weight-bold">Especificação do produto</span>
                <div class="large-text">
                  {{ item.especificacaoProduto | naoInformado }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" v-if="isFromCotacao">
                <span class="caption font-weight-bold">Especificação cotada</span>
                <div class="large-text">
                  {{ item.especificacaoProdutoOrigem | naoInformado }}
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { OrigemCompra } from 'px-business-components';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
    };
  },
  computed: {
    descricaoProduto() {
      return (item) => {
        if (this.isFromCatalogo && item.tituloProduto) {
          return item.tituloProduto;
        }
        return item.produto.descricao;
      };
    },
    headers() {
      const defaultHeaders = [
        {
          text: 'Produto',
          sortable: false,
          value: 'descricao',
        },
        {
          text: 'Quantidade',
          value: 'quantidade',
          sortable: false,
        },
        {
          text: 'Preço unitário',
          value: 'precoUnitario',
        },
      ];

      const endHeaders = [
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: '',
          value: 'options',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ];

      const impostoHeaders = [
        {
          text: 'ICMS',
          value: 'icms',
          sortable: false,
        },
        {
          text: 'IPI',
          value: 'ipi',
          sortable: false,
        },
      ];

      if (!this.isFromCatalogo) {
        return [...defaultHeaders, ...impostoHeaders, ...endHeaders];
      }
      return [...defaultHeaders, ...endHeaders];
    },
    isFromCotacao() {
      return this.compra.origemCompra === OrigemCompra.keys.COTACAO.key;
    },
    isFromCatalogo() {
      return this.compra.origemCompra === OrigemCompra.keys.CATALOGO_COMPRA_CONJUNTA.key;
    },
    linkCotacao() {
      return item => `/cotacao/${item.respostaCotacaoProduto.respostaCotacao.cotacao.id}/respostas/${item.respostaCotacaoProduto.respostaCotacao.id}`;
    },
  },
};
</script>
