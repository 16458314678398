<template>
  <v-card>
    <v-card-text class="text--primary">
      <!--      <v-row class="body-1">-->
      <!--        <v-col cols="12" class="large-text my-4">-->
      <!--          Aqui vai a descrição completa da empresa, com até 500 caracteres. Descrição -->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <v-divider/>-->
      <!--      <v-row>-->
      <!--        <v-col cols="12" class="headline mt-3">-->
      <!--          Características-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <v-list>-->
      <!--        <v-list-item>-->
      <!--          <v-list-item-icon>-->
      <!--            <v-icon large>mdi-clock-outline</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-content class="body-1">-->
      <!--            Entrega 24 horas-->
      <!--          </v-list-item-content>-->
      <!--        </v-list-item>-->
      <!--        <v-list-item>-->
      <!--          <v-list-item-icon>-->
      <!--            <v-icon large>mdi-truck</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-content class="body-1">-->
      <!--            Frete CIF-->
      <!--          </v-list-item-content>-->
      <!--        </v-list-item>-->
      <!--        <v-list-item>-->
      <!--          <v-list-item-icon>-->
      <!--            <v-icon large>mdi-currency-usd-circle-outline</v-icon>-->
      <!--          </v-list-item-icon>-->
      <!--          <v-list-item-content class="body-1">-->
      <!--            Cobertura do preço mais baixo-->
      <!--          </v-list-item-content>-->
      <!--        </v-list-item>-->
      <!--      </v-list>-->
      <!--      <v-divider/>-->
      <!--      <v-row>-->
      <!--        <v-col cols="12" class="headline mt-3">-->
      <!--          Fotos-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <v-row>-->
      <!--        <v-col-->
      <!--          cols="6"-->
      <!--          sm="4"-->
      <!--        >-->
      <!--          <v-img-->
      <!--            src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"-->
      <!--            gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"-->
      <!--          ></v-img>-->
      <!--        </v-col>-->

      <!--        <v-col-->
      <!--          cols="6"-->
      <!--          sm="4"-->
      <!--        >-->
      <!--          <v-img src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg">-->
      <!--            <div class="fill-height bottom-gradient"></div>-->
      <!--          </v-img>-->
      <!--        </v-col>-->

      <!--        <v-col-->
      <!--          cols="6"-->
      <!--          sm="4"-->
      <!--        >-->
      <!--          <v-img src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg">-->
      <!--            <div class="fill-height repeating-gradient"></div>-->
      <!--          </v-img>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <!--      <v-divider/>-->
      <px-pagina-fornecedor-contato :fornecedor="fornecedor"/>
      <v-divider/>
      <template v-if="fornecedor.score && fornecedor.score.score">
        <px-pagina-fornecedor-score :fornecedor="fornecedor"/>
        <v-divider/>
      </template>
      <template v-if="fornecedor.pessoa.entidade.pontuacaoMedia">
        <px-pagina-fornecedor-avaliacao :fornecedor="fornecedor"/>
        <v-divider/>
      </template>
      <px-pagina-fornecedor-localizacao :fornecedor="fornecedor"/>
    </v-card-text>
  </v-card>
</template>

<script>
import PaginaFornecedorLocalizacao from './PaginaFornecedorLocalizacao.vue';
import PaginaFornecedorContato from './PaginaFornecedorContato.vue';
import PaginaFornecedorAvaliacao from './PaginaFornecedorAvaliacao.vue';
import PaginaFornecedorScore from './PaginaFornecedorScore.vue';

export default {
  props: {
    fornecedor: Object,
  },
  components: {
    pxPaginaFornecedorLocalizacao: PaginaFornecedorLocalizacao,
    pxPaginaFornecedorContato: PaginaFornecedorContato,
    pxPaginaFornecedorAvaliacao: PaginaFornecedorAvaliacao,
    pxPaginaFornecedorScore: PaginaFornecedorScore,
  },
};
</script>
