<template>
  <div>
    <v-menu
      v-model="opened"
      close-on-content-click
      open-on-hover
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" v-bind:on="on">
        </slot>
      </template>
      <v-card class="mx-auto"
              min-width="350"
              max-width="500">
        <v-container>
          <template v-for="(produto, index) in produtos">
            <v-list-item :key="produto.id">
              <v-list-item-content>
                <v-row dense no-gutters>
                  <v-col cols="12" align="center">
                    {{ produto.produto.descricao }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`div-${produto.id}`"
                       v-if="index !== (produtos.length - 1)"/>
          </template>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    produtos: Array,
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
  },
};
</script>
