var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.compras.content,"show-expand":"","expanded":_vm.expanded,"loading":_vm.loading,"server-items-length":_vm.compras.total,"footer-props":_vm.footerOptions,"no-data-text":"Nenhuma compra encontrada","loading-text":"Carregando compras...","options":_vm.optionsList},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.fornecedor.nome",fn:function(ref){
var item = ref.item;
return [_c('px-situacao-compra-icon',{attrs:{"situacao":item.situacao}}),_vm._v(" "+_vm._s(item.fornecedor.pessoa.nome)+" ")]}},{key:"item.comprador",fn:function(ref){
var item = ref.item;
return [_c('px-span-tooltip',{attrs:{"top":"","text":_vm.comprador(item),"tooltip":item.comprador.pessoa.nome}})]}},{key:"item.dataCompra",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dataCompra))+" ")]}},{key:"item.quantidadeProdutos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantidadeProdutos)+" produto(s) ")]}},{key:"item.saving",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saving))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goToCompra(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),(!item.avaliacao)?_c('px-avaliacao-dialog',{attrs:{"compra-id":item.id,"disabled":_vm.isOrigemCadastro(item) || !_vm.isFinalizada(item),"name":item.fornecedor.pessoa.nome},on:{"update":_vm.emitUpdate}}):_vm._e(),(item.avaliacao)?_c('px-show-avaliacao-menu',{attrs:{"avaliacao":item.avaliacao.avaliacao}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('listagem-compra-detail',{attrs:{"compra":item}})],1)]}}])}),_c('px-confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }