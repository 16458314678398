import Vue from 'vue';
import Vue2Filters from 'vue2-filters';

Vue.use(Vue2Filters, {
  pluralize: {
    includeNumber: true,
  },
  number: {
    format: '0',
    thousandsSeparator: '.',
    decimalSeparator: ',',
  },
  currency: {
    symbol: 'R$',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false,
  },
});
