import axios from 'axios';

const getCompras = params => axios.get('/compras', {
  params,
});

const getComprasList = params => axios.get('/compras/list', {
  params,
});

const getTotalizadores = params => axios.get('/compras/totalizadores', { params });

const getCompra = id => axios.get(`/compras/${id}`, {});
const getCompraCompleto = id => axios.get(`/compras/${id}/completo`, {});
const getCompraProdutos = id => axios.get(`/compras/produtos/${id}`, {});
const cancelar = (id, compra) => axios.put(`/compras/${id}/cancelar`, compra);
const aprovar = (id, compra) => axios.put(`/compras/${id}/aprovar`, compra);

const save = (compra, isPendenteFornecedor) => {
  if (compra.id) {
    return axios.put(`/compras/${compra.id}`, compra);
  }
  if (isPendenteFornecedor) {
    return axios.post('/compras/pendente-fornecedor', compra);
  }
  return axios.post('/compras', compra);
};

const remover = id => axios.delete(`/compras/${id}`);
const notificarMensagem = id => axios.post(`/notificacoes/compras/${id}`, {});

export default {
  getCompra,
  getComprasList,
  getCompraCompleto,
  getCompras,
  getCompraProdutos,
  cancelar,
  aprovar,
  save,
  remover,
  getTotalizadores,
  notificarMensagem,
};
