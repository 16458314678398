<template>
  <v-container class="mb-4">
    <px-lista-selecao-fornecedores :comprador="cotacao.comprador"
                                   :produtos="cotacao.produtos"
                                   v-model="cotacao.fornecedores"
                                   @resetfornecedor="emitReset"/>
  </v-container>
</template>

<script>
export default {
  props: {
    cotacao: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitReset() {
      this.$emit('resetfornecedor');
    },
  },
};
</script>
