import Vue from 'vue';

import UserInfo from './user-info/UserInfo.vue';
import PxSituacaoCompraIcon from './PxSituacaoCompraIcon.vue';
import PxStatusNegociacao from './PxStatusNegociacao.vue';
import PxEspecificacaoProdutoComparavel from './PxEspecificacaoProdutoComparavel.vue';
import PxPopoverFornecedor from './PxPopoverFornecedor.vue';
import PxAutocompleteComprador from './PxAutocompleteComprador.vue';
import PxAutocompleteFornecedorCotacao from './PxAutocompleteFornecedorCotacao.vue';
import PxAutocompleteCotacaoProduto from './PxAutocompleteCotacaoProduto.vue';
import PxListaSelecaoFornecedores from './lista-selecao-fornecedor/PxListaSelecaoFornecedores.vue';
import PxIndicadorCompra from './PxIndicadorCompra.vue';
import PxAutocompleteUserGrupo from './PxAutocompleteUserGrupo.vue';
import PxRespostaPrazoPreco from './PxRespostaPrazoPreco.vue';
import PxRefresh from './PxRefresh.vue';
import PxFornecedorScore from './PxFornecedorScore.vue';
import PxOpenPaginaFornecedor from './PxOpenPaginaFornecedor.vue';
import PxHistoricoPrecoProduto from './PxHistoricoPrecoProduto.vue';
import ListaCotacaoIdentificador from './ListaCotacaoIdentificador.vue';
import CotacaoRespostaSemEstoque from './CotacaoRespostaSemEstoque.vue';
import CotacaoRespostasProdutosEspecificacao from './CotacaoRespostasProdutosEspecificacao.vue';
import PxAutocompleteEntidade from './PxAutocompleteEntidade.vue';
import PxAutocompleteEntidadeUsuario from './PxAutocompleteEntidadeUsuario.vue';
import PxOfertaFoto from './PxOfertaFoto.vue';
import PxAutocompletePrazoPagamentoCatalogo from './PxAutocompletePrazoPagamentoCatalogo.vue';
import PxOpenOferta from './PxOpenOferta.vue';

Vue.component('pxUserInfo', UserInfo);
Vue.component('pxSituacaoCompraIcon', PxSituacaoCompraIcon);
Vue.component('pxStatusNegociacao', PxStatusNegociacao);
Vue.component('PxEspecificacaoProdutoComparavel', PxEspecificacaoProdutoComparavel);
Vue.component('PxPopoverFornecedor', PxPopoverFornecedor);
Vue.component('PxAutocompleteComprador', PxAutocompleteComprador);
Vue.component('PxAutocompleteFornecedorCotacao', PxAutocompleteFornecedorCotacao);
Vue.component('PxAutocompleteCotacaoProduto', PxAutocompleteCotacaoProduto);
Vue.component('PxListaSelecaoFornecedores', PxListaSelecaoFornecedores);
Vue.component('PxAutocompleteUserGrupo', PxAutocompleteUserGrupo);
Vue.component('PxIndicadorCompra', PxIndicadorCompra);
Vue.component('PxRespostaPrazoPreco', PxRespostaPrazoPreco);
Vue.component('PxRefresh', PxRefresh);
Vue.component('PxFornecedorScore', PxFornecedorScore);
Vue.component('PxOpenPaginaFornecedor', PxOpenPaginaFornecedor);
Vue.component('PxHistoricoPrecoProduto', PxHistoricoPrecoProduto);
Vue.component('PxListaCotacaoIdentificador', ListaCotacaoIdentificador);
Vue.component('PxCotacaoRespostaSemEstoque', CotacaoRespostaSemEstoque);
Vue.component('PxCotacaoRespostasProdutosEspecificacao', CotacaoRespostasProdutosEspecificacao);
Vue.component('PxAutocompleteEntidade', PxAutocompleteEntidade);
Vue.component('PxAutocompleteEntidadeUsuario', PxAutocompleteEntidadeUsuario);
Vue.component('PxOfertaFoto', PxOfertaFoto);
Vue.component('PxAutocompletePrazoPagamentoCatalogo', PxAutocompletePrazoPagamentoCatalogo);
Vue.component('PxOpenOferta', PxOpenOferta);
