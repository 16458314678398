<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menuOpened"
    :nudge-width="450"
    max-width="650"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on">
        <v-icon>mdi-plus</v-icon>
        Fornecedor
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Adicionando fornecedor</v-card-title>
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <px-autocomplete-fornecedor v-model="fornecedor"
                                        class="required"
                                        :error="$v.fornecedor.$error"
                                        @blur="$v.fornecedor.$touch()"
                                        required/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary"
               :loading="loading"
               :disabled="$v.$invalid"
               @click="save">
          Salvar
        </v-btn>
        <v-btn @click="close">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import CotacaoGrupoService from '../cotacoes-grupo-service';

export default {
  props: {
    cotacao: Object,
  },
  data() {
    return {
      menuOpened: false,
      loading: false,
      fornecedor: null,
    };
  },
  methods: {
    close() {
      this.menuOpened = false;
    },
    save() {
      this.loading = true;
      CotacaoGrupoService.adicionarFornecedor(this.cotacao.id, this.fornecedor.id)
        .then(() => {
          this.close();
          this.$emit('update');
          this.$toast('Fornecedor adicionado', { color: 'success' });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildInitialState() {
      this.fornecedor = null;
      this.$v.$reset();
    },
  },
  watch: {
    menuOpened() {
      if (!this.menuOpened) {
        return;
      }
      this.buildInitialState();
    },
  },
  validations: {
    fornecedor: {
      required,
    },
  },
};
</script>
