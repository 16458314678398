<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on"
             @click.stop="onClickCancel"
             :disabled="!canCancel"
             :loading="loading">
        <v-icon>
          mdi-cancel
        </v-icon>
      </v-btn>
    </template>
    <span>Cancelar cotação</span>
    <px-confirm-dialog ref="confirm"/>
  </v-tooltip>
</template>

<script>
import { SituacaoCotacao } from 'px-business-components';
import CotacaoGrupoService from '../cotacoes-grupo-service';

const SITUACOES_ABERTAS = [SituacaoCotacao.keys.ABERTA.key,
  SituacaoCotacao.keys.PENDENTE_ENVIO.key];

export default {
  props: {
    cotacao: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    canCancel() {
      return SITUACOES_ABERTAS.includes(this.cotacao.situacao);
    },
  },
  methods: {
    cancel() {
      this.loading = true;
      CotacaoGrupoService.cancelarCotacao(this.cotacao.id)
        .then(() => {
          this.$toast('Cotação cancelada', { color: 'success' });
          this.$emit('update');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickCancel() {
      this.$refs.confirm.open('Você deseja cancelar a cotação?',
        'A cotação será cancelada e não poderá mais receber respostas. Esta operação não pode ser desfeita',
        { width: 300 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.cancel();
        });
    },
  },
};
</script>
