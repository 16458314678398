<template>
  <v-data-table
    show-select
    v-model="localValue"
    :headers="headers"
    :items="fornecedores.content"
    class="elevation-1 mt-2"
    :loading="loading"
    :server-items-length="fornecedores.total"
    @update:options="updateOptions"
    @item-selected="onSelectItem"
    :footer-props="footerOptions"
    no-data-text="Nenhum fornecedor encontrado"
    loading-text="Carregando fornecedores..."
    :options="optionsList"
  >
    <template v-slot:footer.page-text>
      <span></span>
    </template>
    <template v-slot:header.data-table-select>
      <span></span>
    </template>
    <template v-slot:item.pessoa.nome="{ item }">
      {{ item.pessoa.nome }}
      <px-open-pagina-fornecedor :id="item.id"/>
    </template>
    <template v-slot:item.cnpj="{ item }">
      {{ item.pessoa.cnpj | cnpj }}
    </template>
    <template v-slot:item.local="{ item }">
      {{ item.endereco.municipio.nome }} ({{ item.endereco.estado.sigla }})
    </template>
    <template v-slot:item.score="{ item }">
      <px-fornecedor-score v-if="item.score" :score="item.score"/>
      <span v-if="!item.score">N/A</span>
    </template>
    <template v-slot:item.avaliacao="{ item }">
      <span v-if="pontuacao(item)">{{ pontuacao(item) | number('0.0') }}</span>
      <span v-else>Sem avaliação</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    value: Array,
    fornecedores: Object,
    loading: Boolean,
    optionsList: Object,
    onChangeOptions: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      maxSelect: 20,
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'pessoa.nome',
          sortable: false,
        },
        {
          text: 'CNPJ',
          value: 'cnpj',
          sortable: false,
        },
        {
          text: 'Local',
          value: 'local',
          sortable: false,
        },
        {
          text: 'Score',
          value: 'score',
          sortable: false,
        },
        {
          text: 'Avaliação',
          value: 'avaliacao',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
    pontuacao() {
      return (item) => {
        if (!item.score) {
          return null;
        }
        return item.score.pontuacaoMedia;
      };
    },
  },
  methods: {
    onSelectItem() {
    },
    updateOptions(options) {
      this.onChangeOptions(options);
    },
  },
  watch: {
    localValue(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.localValue.length > this.maxSelect) {
        this.localValue = [...oldValue];
      }
    },
  },
};
</script>
