export default {
  selectedStatus(state) {
    return state.selectedStatus;
  },
  searchText(state) {
    return state.searchText;
  },
  stringFilter(state) {
    let filter = 'origemCompra in ("COTACAO_CONJUNTA", "CATALOGO_COMPRA_CONJUNTA")';
    if (state.searchText) {
      filter += ` and (fornecedor.nome like '%${state.searchText.toLowerCase()}%' or produto like '%${state.searchText.toLowerCase()}%')`;
    }
    return filter;
  },
};
