<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <v-row justify="end" v-if="!loading && freteCep">
      <v-col cols="12" sm="6" md="8">
          <span class="caption">
            &#8226; {{ prazoPrefix }} {{ prazoEmbarque | pluralize(['dia útil', 'dias úteis']) }}
          </span>
        <div v-if="freteCep.ofertaFreteGratis">
            <span class="caption">
              &#8226; Oferta frete grátis a partir de
              {{ freteCep.taxaMinimaEntrega | currency }}
            </span>
        </div>
        <div>
            <span class="caption">
            &#8226; Ver mais prazos disponíves
            </span>
          <px-carrinho-prazos-disponiveis-gerais :catalogo-id="catalogo.catalogo.id"/>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="!loading && freteCep">
        <v-list>
          <v-list-item class="grey lighten-3">
            <v-row dense no-gutters>
              <v-col cols="6">
                Frete
              </v-col>
              <v-col cols="6" class="text-right">
                <px-carrinho-empresa-catalogo-frete :total-frete="totalFrete"/>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item class="grey lighten-3 mt-1">
            <v-row dense no-gutters>
              <v-col cols="6">
                Total sem desconto
              </v-col>
              <v-col cols="6" class="text-right">
                <span class="text-line-through">{{ totalProdutosOriginal | currency }}</span>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item class="grey lighten-3 mt-1">
            <v-row dense no-gutters class="blue--text">
              <v-col cols="6">
                Total a prazo
                <px-carrinho-prazos-disponiveis :catalogo-id="catalogo.catalogo.id"
                                                :valor-a-prazo="totalProdutosAPrazo"
                                                @prazos="onLoadPrazos"/>
              </v-col>
              <v-col cols="6" class="text-right">
                {{ totalProdutosAPrazo | currency }}
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item class="grey lighten-3 mt-1">
            <v-row dense no-gutters class="green--text">
              <v-col cols="6">
                Total à vista
              </v-col>
              <v-col cols="6" class="text-right">
                {{ totalProdutosAVista | currency }}
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <div class="text-right mt-3" v-if="!loading && freteCep && prazoLoaded">
      <px-carrinho-finalizar :carrinho="catalogo"
                             :frete="totalFrete"
                             :total-prazo="totalProdutosAPrazo"
                             :total-vista="totalProdutosAVista"
                             :has-prazo-pagamento="hasPrazo"/>
    </div>
  </div>
</template>

<script>
import { TipoFrete } from 'px-business-components';
import CarrinhoCalculoService from './carrinho-calculo-service';
import CarrinhoEmpresaCatalogoFrete from './CarrinhoEmpresaCatalogoFrete.vue';
import CarrinhoFinalizar from './CarrinhoFinalizar.vue';
import CatalogoService from '../catalogo/catalogo-service';
import CarrinhoPrazosDisponiveis from './CarrinhoPrazosDisponiveis.vue';
import CarrinhoPrazosDisponiveisGerais from './CarrinhoPrazosDisponiveisGerais.vue';

export default {
  props: {
    catalogo: Object,
    comprador: Object,
  },
  data() {
    return {
      loading: false,
      prazoLoaded: false,
      freteCep: null,
      hasPrazo: false,
    };
  },
  computed: {
    totalProdutosOriginal() {
      return CarrinhoCalculoService.calcularTotal(this.catalogo.ofertas, 'precoOriginal');
    },
    totalProdutosAVista() {
      return CarrinhoCalculoService.calcularTotal(this.catalogo.ofertas, 'precoAVista');
    },
    totalProdutosAPrazo() {
      return CarrinhoCalculoService.calcularTotal(this.catalogo.ofertas, 'precoAPrazo');
    },
    totalFrete() {
      return CarrinhoCalculoService.calculaFrete(this.freteCep, this.totalProdutosAVista);
    },
    isFob() {
      return this.totalFrete.tipoFrete === TipoFrete.keys.FOB.key;
    },
    prazoEmbarque() {
      return this.freteCep.prazoEmbarque;
    },
    prazoPrefix() {
      if (this.isFob) {
        return 'Embarque em';
      }
      return 'Entrega em';
    },
  },
  methods: {
    onLoadPrazos(hasPrazo) {
      this.prazoLoaded = true;
      this.hasPrazo = hasPrazo;
    },
    getFreteCep() {
      this.loading = true;
      CatalogoService.getFreteCep(this.catalogo.catalogo.id, this.comprador.endereco.cep)
        .then(({ data }) => {
          this.freteCep = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFreteCep();
  },
  components: {
    pxCarrinhoEmpresaCatalogoFrete: CarrinhoEmpresaCatalogoFrete,
    pxCarrinhoFinalizar: CarrinhoFinalizar,
    pxCarrinhoPrazosDisponiveis: CarrinhoPrazosDisponiveis,
    pxCarrinhoPrazosDisponiveisGerais: CarrinhoPrazosDisponiveisGerais,
  },
};
</script>
