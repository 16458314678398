<template>
  <div>
    <px-view-header title="Meu painel" :breadcrumbs="breadcrumbs"/>
    <v-row v-if="hasModuleGerenciaOrCompraConjunta">
      <v-col cols="12" sm="12" md="12" lg="6" v-if="hasModuleCompraConjunta">
        <px-meu-painel-cotacoes/>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="6">
        <px-meu-painel-compras/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MeuPainelCotacoes from './MeuPainelCotacoes.vue';
import MeuPainelCompras from './MeuPainelCompras.vue';

export default {
  data() {
    return {
      breadcrumbs: [
        { text: 'Indicadores' },
        { text: 'Meu painel' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['hasModuleGerenciaOrCompraConjunta', 'hasModuleGestorFrete', 'hasModuleCompraConjunta']),
  },
  components: {
    pxMeuPainelCotacoes: MeuPainelCotacoes,
    pxMeuPainelCompras: MeuPainelCompras,
  },
};
</script>
