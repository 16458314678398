import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  user: '',
  password: '',
  loading: false,
  error: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
