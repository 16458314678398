<template>
  <div>
    <v-row>
      <v-col cols="12" class="headline mt-3">
        <v-icon>
          mdi-calculator
        </v-icon>
        {{ fornecedor.score.score | number('0.0') }} (Score Proxpect)
      </v-col>
    </v-row>
    <div class="ml-3 mb-2">
      <v-row dense>
        <v-col cols="12">
          {{ fornecedor.score.score | number('0.0') }} de 1000
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          Com base nas últimas {{ fornecedor.score.cotacoes | number('0') }} cotações
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          Respondeu {{ fornecedor.score.scoreRespostas | number('0') }}% das solicitações
        </v-col>
      </v-row>
      <v-row dense v-if="fornecedor.score.scoreVendas">
        <v-col cols="12">
          Vendeu {{ fornecedor.score.scoreVendas | number('0') }}% das solicitações respondidas
        </v-col>
      </v-row>
      <v-row dense v-if="fornecedor.score.scoreNegociacoes">
        <v-col cols="12">
          Respondeu {{ fornecedor.score.scoreNegociacoes| number('0') }}% das negociações
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fornecedor: Object,
  },
};
</script>
