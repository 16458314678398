<template>
  <div>
    <div class="body-1 mt-5">
      <span class="text-line-through">{{ oferta.precoOriginal | currency }}</span>
      <px-oferta-tag-desconto :oferta="oferta" chip-class="body-1"/>
    </div>
    <div class="text--primary mt-1">
      <span class="display-1">{{ oferta.precoAVista | currency }}</span> à vista
    </div>
    <div class="green--text body-1 mt-1">
      {{ oferta.precoAPrazo | currency }} a prazo
    </div>
  </div>
</template>

<script>
import OfertaTagDesconto from './OfertaTagDesconto.vue';

export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  components: {
    pxOfertaTagDesconto: OfertaTagDesconto,
  },
};
</script>
