<template>
  <div>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && oferta">
      <px-oferta-title :oferta="oferta"/>
      <v-row>
        <v-col cols="12" sm="7">
          <px-oferta-foto :oferta="oferta"/>
        </v-col>
        <v-col cols="12" sm="5">
          <px-oferta-info-preco @comprador="changeComprador" :oferta="oferta"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-oferta-especificacao :oferta="oferta"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <px-oferta-duvidas :oferta="oferta"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CatalogoService from '@/modules/catalogo/catalogo-service';
import OfertaTitle from './OfertaTitle.vue';
import OfertaFoto from './OfertaFoto.vue';
import OfertaInfoPreco from './OfertaInfoPreco.vue';
import OfertaEspecificacao from './OfertaEspecificacao.vue';
import OfertaDuvidas from './OfertaDuvidas.vue';

export default {
  data() {
    return {
      loading: false,
      oferta: null,
      id: this.$route.params.id,
      grupoId: this.$route.params.idGrupo,
      compradorSelecionado: null,
    };
  },
  methods: {
    changeComprador(comprador) {
      this.compradorSelecionado = comprador;
    },
    getOferta() {
      this.loading = true;
      CatalogoService.getOferta(this.grupoId, this.id)
        .then(({ data }) => {
          this.oferta = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getOferta();
  },
  components: {
    pxOfertaTitle: OfertaTitle,
    pxOfertaFoto: OfertaFoto,
    pxOfertaInfoPreco: OfertaInfoPreco,
    pxOfertaEspecificacao: OfertaEspecificacao,
    pxOfertaDuvidas: OfertaDuvidas,
  },
};
</script>
