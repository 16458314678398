var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"show-select":"","headers":_vm.headers,"items":_vm.fornecedores.content,"loading":_vm.loading,"server-items-length":_vm.fornecedores.total,"footer-props":_vm.footerOptions,"no-data-text":"Nenhum fornecedor encontrado","loading-text":"Carregando fornecedores...","options":_vm.optionsList},on:{"update:options":_vm.updateOptions,"item-selected":_vm.onSelectItem},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(){return [_c('span')]},proxy:true},{key:"header.data-table-select",fn:function(){return [_c('span')]},proxy:true},{key:"item.pessoa.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pessoa.nome)+" "),_c('px-open-pagina-fornecedor',{attrs:{"id":item.id}})]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("cnpj")(item.pessoa.cnpj))+" ")]}},{key:"item.local",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.endereco.municipio.nome)+" ("+_vm._s(item.endereco.estado.sigla)+") ")]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [(item.score)?_c('px-fornecedor-score',{attrs:{"score":item.score}}):_vm._e(),(!item.score)?_c('span',[_vm._v("N/A")]):_vm._e()]}},{key:"item.avaliacao",fn:function(ref){
var item = ref.item;
return [(_vm.pontuacao(item))?_c('span',[_vm._v(_vm._s(_vm._f("number")(_vm.pontuacao(item),'0.0')))]):_c('span',[_vm._v("Sem avaliação")])]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }