<template>
  <div class="mt-5 ml-5">
    <v-divider/>
    <div class="mt-10 title-content">Descrição</div>
    <div class="mt-5 grey--text text--darken-3 description-content large-text">
      {{ oferta.especificacao }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
};
</script>
