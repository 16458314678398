<template>
  <div>
    <px-view-header title="Visualizar resposta" :breadcrumbs="breadcrumbs">
      <template v-slot:before>
        <v-btn class="mb-4 mr-1" icon x-large color="primary" link @click="onClickBack">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </template>
    </px-view-header>
    <div v-if="loading">
      <px-circular-loading/>
    </div>
    <div v-if="!loading && resposta">
      <px-grupo-compra-cotacao-resposta-detail-header :resposta="resposta" :is-admin="isAdmin" />
      <px-grupo-compra-cotacao-resposta-detail-produtos class="mt-3" :resposta="resposta" />
      <px-grupo-compra-cotacao-resposta-detail-minha-empresa class="mt-3" :resposta="resposta"/>
      <px-grupo-compra-negociacao class="mt-3" :resposta="resposta" :is-admin="isAdmin"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { comparePrazos } from '@/utils/prazo-utils';
import GrupoCompraCotacaoRespostaDetailHeader from './GrupoCompraCotacaoRespostaDetailHeader.vue';
import GrupoCompraCotacaoRespostaDetailProdutos from './GrupoCompraCotacaoRespostaDetailProdutos.vue';
import GrupoCompraCotacaoRespostaDetailMinhaEmpresa from './GrupoCompraCotacaoRespostaDetailMinhaEmpresa.vue';
import CotacaoConjuntaRespostaService from '../../cotacao-conjunta-resposta';
import CotacaoConjuntaService from '../../cotacoes-grupo-service';
import GrupoCompraNegociacao from './negociacao/GrupoCompraNegociacao.vue';

export default {
  data() {
    return {
      loading: false,
      resposta: false,
      admins: [],
      idCotacao: this.$route.params.idCotacao,
      idResposta: this.$route.params.idResposta,
      breadcrumbs: [
        { text: 'Negócios' },
        { text: 'Resposta' },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters(['isGrupoAdmin']),
    isAdmin() {
      return this.admins.map(a => a.id).includes(this.user.id) || this.isGrupoAdmin;
    },
  },
  methods: {
    onClickBack() {
      this.$router.go(-1);
    },
    getResposta() {
      this.loading = true;
      CotacaoConjuntaRespostaService.getResposta(this.idCotacao, this.idResposta)
        .then(({ data }) => {
          this.resposta = data;
          this.resposta.produtos = this.resposta.produtos.map(p => ({
            ...p,
            prazos: p.prazos.sort(comparePrazos),
          }));
          return this.getAdmins();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAdmins() {
      CotacaoConjuntaService.getAdmins(this.idCotacao)
        .then(({ data }) => {
          this.admins = data;
        });
    },
  },
  created() {
    this.getResposta();
  },
  components: {
    pxGrupoCompraCotacaoRespostaDetailHeader: GrupoCompraCotacaoRespostaDetailHeader,
    pxGrupoCompraCotacaoRespostaDetailProdutos: GrupoCompraCotacaoRespostaDetailProdutos,
    pxGrupoCompraCotacaoRespostaDetailMinhaEmpresa: GrupoCompraCotacaoRespostaDetailMinhaEmpresa,
    pxGrupoCompraNegociacao: GrupoCompraNegociacao,
  },
};
</script>
