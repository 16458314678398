var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.cotacoes.content,"loading":_vm.loading,"server-items-length":_vm.cotacoes.total,"footer-props":_vm.footerOptions,"no-data-text":"Nenhuma cotação encontrada","loading-text":"Carregando cotações...","options":_vm.optionsList},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.identificador",fn:function(ref){
var item = ref.item;
return [_c('px-lista-cotacao-identificador',{attrs:{"cotacao":item,"save-function":_vm.identifierFunction},on:{"update":_vm.emitUpdate}})]}},{key:"item.createdIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdIn,true))+" ")]}},{key:"item.datafechamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.datafechamento,true))+" ")]}},{key:"item.quantidadeProdutos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantidadeProdutos)+" produto(s) ")]}},{key:"item.quantidadeRespostas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantidadeRespostas)+" resposta(s) ")]}},{key:"item.prazoEntrega",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("naoInformado")(_vm._f("date")(item.prazoEntrega)))+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","link":"","to":("/" + _vm.grupoId + "/cotacao/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }