<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab>Cotação</v-tab>
      <v-tab>Participantes</v-tab>
      <v-tab>Fornecedores</v-tab>
      <v-tab>Acompanhamento</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text>
            <px-grupo-compra-cotacao-analise-tab-cotacao :cotacao="cotacao"
                                                         :is-user-admin="isUserAdmin"
                                                         @update="emitUpdate"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text>
            <px-grupo-compra-cotacao-visualizacao-participacoes read-only
                                                                :cotacao="cotacao"
                                                                :grupo-compra="grupoCompra"
                                                                :is-user-admin="isUserAdmin"
                                                                @update="emitUpdate" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text>
            <px-grupo-compra-cotacao-visualizacao-fornecedores :cotacao="cotacao"
                                                               :is-user-admin="isUserAdmin"
                                                               @update="emitUpdate" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text class="text--primary">
            <px-acompanhamentos allow-files
                                :item-type="messageType"
                                :item-id="cotacao.id"
                                :user="user"
                                @on-message="onMessage"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import CotacaoConjuntaResposta from '@/modules/cotacoes/cotacao-conjunta-resposta';
import GrupoCompraCotacaoVisualizacaoParticipacoes from '../GrupoCompraCotacaoVisualizacaoParticipacoes.vue';
import GrupoCompraCotacaoVisualizacaoFornecedores from '../GrupoCompraCotacaoVisualizacaoFornecedores.vue';
import GrupoCompraCotacaoAnaliseTabCotacao from './GrupoCompraCotacaoAnaliseTabCotacao.vue';

export default {
  props: {
    cotacao: Object,
    grupoCompra: Object,
    isUserAdmin: Boolean,
  },
  data() {
    return {
      messageType: this.$px.types.COTACAO_CONJUNTA,
    };
  },
  computed: {
    tab: {
      get() {
        return this.$store.getters['compraConjuntaCotacaoPendente/tab'];
      },
      set(searchText) {
        this.$store.dispatch('compraConjuntaCotacaoPendente/tab', searchText);
      },
    },
    ...mapGetters('user', ['user']),
  },
  methods: {
    emitUpdate() {
      this.$emit('update');
    },
    onMessage() {
      CotacaoConjuntaResposta.notificarAcompanhamento(this.cotacao.id);
    },
  },
  components: {
    pxGrupoCompraCotacaoVisualizacaoParticipacoes: GrupoCompraCotacaoVisualizacaoParticipacoes,
    pxGrupoCompraCotacaoVisualizacaoFornecedores: GrupoCompraCotacaoVisualizacaoFornecedores,
    pxGrupoCompraCotacaoAnaliseTabCotacao: GrupoCompraCotacaoAnaliseTabCotacao,
  },
};
</script>
