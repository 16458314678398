<template>
  <div class="d-inline-block">
    <px-grupo-compra-editar-participacao :participante="participante"
                                         :cotacao="cotacao"
                                         v-if="isCompradorAtual"
                                         @update="emitUpdate" />
    <v-tooltip top v-if="isCompradorAtual">
      <template v-slot:activator="{ on }">
        <v-btn class="ml-2" icon small
               :loading="loadingRemove"
               v-on="on"
               @click="remove">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>Sair deste produto</span>
    </v-tooltip>
  </div>
</template>

<script>
import CotacaoGrupoService from '../cotacoes-grupo-service';
import GrupoCompraEditarParticipacao from '../editar-participacao/GrupoCompraEditarParticipacao.vue';

export default {
  props: {
    cotacao: Object,
    participante: Object,
    isCompradorAtual: Boolean,
  },
  data() {
    return {
      loadingRemove: false,
    };
  },
  methods: {
    remove() {
      this.loadingRemove = true;
      CotacaoGrupoService.removeParticipacao(this.cotacao.id, this.participante.id)
        .then(() => {
          this.emitUpdate();
          this.$toast('Remoção efetuada com sucesso', { color: 'success' });
        })
        .finally(() => {
          this.loadingRemove = false;
        });
    },
    emitUpdate() {
      this.$emit('update');
    },
  },
  components: {
    pxGrupoCompraEditarParticipacao: GrupoCompraEditarParticipacao,
  },
};
</script>
