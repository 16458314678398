<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab>Prazos de pagamento</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text>
            <v-list>
              <template v-for="(prazo, index) in grupo.prazosPagamentos">
                <v-list-item :key="prazo.id">
                  <v-list-item-content>
                    <px-forma-pagamento :forma-pagamento="prazo.formaPagamento"
                                        :prazo-pagamento="prazo.prazoPagamento"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index !== (grupo.prazosPagamentos.length - 1)"
                           :key="`div-${prazo.id}`"/>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  props: {
    grupo: Object,
  },
  data() {
    return {
      tab: 0,
    };
  },
};
</script>
