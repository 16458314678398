<template>
  <v-radio-group row hide-details dense v-model="produto.selected"
                 class="mt-0 align-content-end">
    <v-spacer></v-spacer>
    <v-radio class="mr-8" v-for="prazo in produto.prazos"
             :key="prazo.id"
             :value="prazo"
             :disabled="isPrazoNaoRespondido(prazo)">
      <template v-slot:label>
        <div class="text--primary">
          <px-forma-pagamento :forma-pagamento="prazo.formaPagamento"
                              :prazo-pagamento="prazo.prazoPagamento"/>
          <div class="caption">
            <px-resposta-prazo-preco :value="prazo.total" :situacao="prazo.situacao"/>
          </div>
        </div>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script>
import { SituacaoRespostaPrazo } from 'px-business-components';

export default {
  props: {
    produto: Object,
  },
  computed: {
    isPrazoNaoRespondido() {
      return prazo => prazo.situacao !== SituacaoRespostaPrazo.keys.RESPONDIDA.key;
    },
  },
};
</script>
