<template>
  <div>
    <px-search-text label="Busque por um produto, especificação ou empresa"
                    autocomplete="off"
                    v-model="searchText"
                    :on-search="emitChange"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchParam: this.$route.query.search,
    };
  },
  computed: {
    searchText: {
      get() {
        return this.$store.getters['catalogoSearch/searchText'];
      },
      set(searchText) {
        this.$store.dispatch('catalogoSearch/searchText', searchText);
      },
    },
  },
  methods: {
    setRouteParam(newText) {
      this.$router.push({
        query: {
          search: newText,
        },
      });
    },
    emitChange(newText) {
      this.setRouteParam(newText);
      this.$emit('change', newText);
    },
  },
  created() {
    if (this.searchParam) {
      this.searchText = this.searchParam;
      return;
    }
    if (this.searchText) {
      this.setRouteParam(this.searchText);
    }
  },
};
</script>
