<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab>Empresas</v-tab>
      <v-tab>Avaliações</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text>
            <px-minha-empresa-tab-empresas/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
        >
          <v-card-text>
            <px-minha-empresa-tab-avaliacoes :entidade="entidade"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import MinhaEmpresaTabEmpresas from './MinhaEmpresaTabEmpresas.vue';
import MinhaEmpresaTabAvaliacoes from './MinhaEmpresaTabAvaliacoes.vue';

export default {
  props: {
    entidade: Object,
  },
  data() {
    return {
      tab: 0,
    };
  },
  components: {
    pxMinhaEmpresaTabEmpresas: MinhaEmpresaTabEmpresas,
    pxMinhaEmpresaTabAvaliacoes: MinhaEmpresaTabAvaliacoes,
  },
};
</script>
