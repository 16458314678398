<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="fornecedores.content"
      class="elevation-1 mt-5"
      :loading="loading"
      :server-items-length="fornecedores.total"
      @update:options="updateOptions"
      :footer-props="footerOptions"
      no-data-text="Nenhum fornecedor encontrado"
      loading-text="Carregando fornecedores..."
      :options="optionsList"
    >
      <template v-slot:item.fornecedor.nome="{ item }">
        {{ item.fornecedor.pessoa.nome }}
        <px-open-pagina-fornecedor :id="item.fornecedor.id" :is-icon="false"/>
      </template>
      <template v-slot:item.fornecedor.cnpj="{ item }">
        {{ item.fornecedor.pessoa.cnpj | cnpj }}
      </template>
      <template v-slot:item.situcacao="{ item }">
        <px-ativo :ativo="item.ativo"/>
      </template>
      <template v-slot:item.permiteCatalogo="{ item }">
        <px-sim-nao :ativo="item.catalogoHabilitado"/>
      </template>
      <template v-slot:item.options="{ item }">
        <px-cadastro-fornecedor :fornecedor="item"
                                :id-grupo="idGrupo"
                                @change="emitUpdate">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </px-cadastro-fornecedor>
      </template>
    </v-data-table>
    <px-confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CadastroFornecedor from './CadastroFornecedor.vue';

export default {
  props: {
    fornecedores: Object,
    default: [],
    loading: Boolean,
    onChangeOptions: {
      type: Function,
      required: true,
    },
    optionsList: Object,
  },
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Linhas por página',
      },
      headers: [
        {
          text: 'Nome',
          value: 'fornecedor.nome',
        },
        {
          text: 'CNPJ',
          value: 'fornecedor.cnpj',
        },
        {
          text: 'Situação',
          value: 'situcacao',
          sortable: false,
        },
        {
          text: 'Permite catálogo',
          value: 'permiteCatalogo',
          align: 'center',
          sortable: false,
        },
        {
          text: null,
          sortable: false,
          value: 'options',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['idGrupo']),
  },
  methods: {
    updateOptions(options) {
      this.onChangeOptions(options);
    },
    emitUpdate() {
      this.$emit('update');
    },
  },
  components: {
    pxCadastroFornecedor: CadastroFornecedor,
  },
};
</script>
