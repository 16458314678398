import moment from 'moment';
import { chain, range, sum } from 'lodash';

const MONTHS_TO_SHOW = 6;

function buildMonthChart(data, maxMonths = MONTHS_TO_SHOW) {
  return chain(range(0, maxMonths))
    .map((month) => {
      const current = moment().subtract(month, 'month');
      const dataKey = current.format('YYYYMM');
      const description = current.format('MMM');
      return { dataKey, description, value: data[dataKey] || 0 };
    })
    .sortBy('dataKey')
    .value();
}

function toEchartData(data, key, value) {
  return data.map(d => [d[key], d[value]]);
}

function sumData(data) {
  const values = Object.keys(data).map(key => data[key]);
  return sum(values);
}

export {
  buildMonthChart,
  toEchartData,
  sumData,
};
