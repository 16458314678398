import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar';

Vue.use(VuetifyToast, {
  x: null,
  y: 'bottom',
  timeout: 8000,
  color: null,
  showClose: true,
  closeText: 'FECHAR',
});
