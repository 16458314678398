<template>
  <v-list-item>
    <v-list-item-avatar class="square-avatar">
      <px-oferta-foto height="40"
                      width="40"
                      :foto="oferta.oferta.produto.foto"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <router-link target="_blank" :to="`/${grupoId}/catalogo/oferta/${oferta.oferta.id}`">
        {{ oferta.oferta.titulo }}
      </router-link>
    </v-list-item-content>
    <v-list-item-content>
      {{ oferta.quantidade }} {{ oferta.oferta.unidadeMedida.descricao }}
    </v-list-item-content>
    <v-list-item-content>
      {{ oferta.oferta.precoAVista | currency }}
    </v-list-item-content>
    <v-list-item-action>
      <px-carrinho-empresa-catalogo-item-editar :oferta="oferta"
                                                :grupo-id="grupoId"
                                                @change="onChangeOferta"/>
    </v-list-item-action>
    <v-list-item-action>
      <v-btn icon :loading="loading" @click="onRemove()">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapActions } from 'vuex';
import { GET_ITENS, REMOVE_ITEM } from '@/store/carrinho/actions.type';
import CarrinhoEmpresaCatalogoItemEditar from './CarrinhoEmpresaCatalogoItemEditar.vue';

export default {
  props: {
    oferta: Object,
  },
  data() {
    return {
      grupoId: this.$route.params.idGrupo,
      loading: false,
    };
  },
  methods: {
    emitChange() {
      this.$emit('change');
    },
    ...mapActions('carrinho', {
      getItens: GET_ITENS,
      removeItem: REMOVE_ITEM,
    }),
    onRemove() {
      this.loading = true;
      this.removeItem({ grupoId: this.grupoId, id: this.oferta.id })
        .then(() => {
          this.emitChange();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChangeOferta() {
      this.emitChange();
    },
  },
  components: {
    pxCarrinhoEmpresaCatalogoItemEditar: CarrinhoEmpresaCatalogoItemEditar,
  },
};
</script>
