var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"show-expand":"","hide-default-footer":"","disable-pagination":"","headers":_vm.headers,"items":_vm.compra.produtos,"item-key":"id"},scopedSlots:_vm._u([{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.descricaoProduto(item))+" "),(_vm.isFromCatalogo)?_c('px-open-oferta',{attrs:{"oferta":item.oferta,"grupo-id":_vm.grupoId}}):_vm._e(),_c('px-historico-preco-produto',{attrs:{"small":"","produto":item.produto,"unidade-medida":item.unidadeMedida}})]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.quantidade)+" "+_vm._s(item.unidadeMedida.descricao)+" ")])]}},{key:"item.precoUnitario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.precoUnitario)))])]}},{key:"item.icms",fn:function(ref){
var item = ref.item;
return [_c('px-imposto',{attrs:{"imposto":item.impostos,"tipo":"ICMS"}})]}},{key:"item.ipi",fn:function(ref){
var item = ref.item;
return [_c('px-imposto',{attrs:{"imposto":item.impostos,"tipo":"IPI"}})]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.total)))]),_c('px-situacao-cotacao-produto',{attrs:{"situacao":item.situacao}})]}},(_vm.isFromCotacao)?{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","link":"","target":"_blank","to":_vm.linkCotacao(item)}},on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Ir para a cotação")])])]}}:null,{key:"footer",fn:function(){return [_c('v-row',{staticClass:"text-right text--primary mt-3",attrs:{"dense":""}},[_c('v-col',{staticClass:"body-1",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" FRETE: "+_vm._s(_vm._f("currency")(_vm.compra.frete.totalTransporte))+" ")])]),_c('v-col',{staticClass:"body-1",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"orange--text text--darken-4 font-weight-bold"},[_vm._v(" TOTAL: "+_vm._s(_vm._f("currency")(_vm.compra.total))+" ")])])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"grey lighten-4 expanded-item",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v("Especificação do produto")]),_c('div',{staticClass:"large-text"},[_vm._v(" "+_vm._s(_vm._f("naoInformado")(item.especificacaoProduto))+" ")])]),(_vm.isFromCotacao)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v("Especificação cotada")]),_c('div',{staticClass:"large-text"},[_vm._v(" "+_vm._s(_vm._f("naoInformado")(item.especificacaoProdutoOrigem))+" ")])]):_vm._e()],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }