<template>
  <v-card>
    <v-card-text>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="headline">Detalhamento do preço total</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <px-grupo-compra-resposta-produtos :produtos="resposta.produtos"
                                         :show-especificacao-line="false"
                                         :show-especificacao="true" />
      <v-divider />
      <v-row class="text--primary ml-2">
        <v-col cols="12" sm="4" v-for="total in totais" :key="total.id">
          <span class="caption font-weight-bold">
             <px-forma-pagamento :forma-pagamento="total.formaPagamento"
                                 :prazo-pagamento="total.prazoPagamento"/>
          </span>
          <div>
            {{ total.total | currency }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
import { comparePrazos } from '@/utils/prazo-utils';
import GrupoCompraRespostaProdutos from './GrupoCompraRespostaProdutos.vue';

export default {
  props: {
    resposta: Object,
  },
  computed: {
    totais() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.resposta.totais.sort(comparePrazos);
    },
  },
  components: {
    pxGrupoCompraRespostaProdutos: GrupoCompraRespostaProdutos,
  },
};
</script>
